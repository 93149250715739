const response ={
          "categories":[
  {
    "categoryName": "IT & Computers",
    "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a",
    "subCategories": [
      {
        "id": "495d47d1-d732-4ad4-ba74-2cf1290fe2d3",
        "name": "AI & ML",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "524c0958-c46c-4afd-afb9-a090f54ced05",
        "name": "Cloud Computing",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "9d2afb47-3193-4f6b-93ac-c29e54f9608b",
        "name": "Computer Hardware",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "94bdc05e-afeb-4c44-9720-78d310890cd3",
        "name": "Data Science",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "f17cf4b9-bdcb-4d38-927d-77e1360da45a",
        "name": "Database",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "2c0643d0-1444-47aa-a482-94a555824842",
        "name": "DevOps",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "e520038d-d73d-4ada-9944-bb575190f822",
        "name": "Embedded Systems",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "c352d9e1-eec3-4217-9e1d-c1e352b817d6",
        "name": "Game Development",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "225e5170-1bd6-4d3f-bb90-8cd534eb350d",
        "name": "Mobile Apps",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "2e7a6b89-f4c6-4a15-a3ff-8e8b177d8842",
        "name": "Network & Security",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "97df0512-4997-4b43-a622-fcd3555f41f1",
        "name": "Operating System",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "05775080-344d-4d65-87a5-9a2244cd0873",
        "name": "Other Technologies",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "4552c07a-8f5f-4b90-8b91-4d4e064f4d2f",
        "name": "Productivity",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "d1b25b25-2e1a-4980-82dd-7087b3ac0c8f",
        "name": "Programming",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "2b26fb80-d3b9-476b-a5c9-5e466631296b",
        "name": "Project Management",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "c813db19-b01e-4230-ac65-b6480aa59cb1",
        "name": "Software Engineering",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "a7b2cffa-a7e2-46f5-b634-9ffade57bd80",
        "name": "Testing",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      },
      {
        "id": "3cb857b5-74dd-47b0-b750-e7c2e0280d53",
        "name": "Web Development",
        "categoryID": "02fb06a3-934e-4845-95ef-4faf6db4ce4a"
      }
    ]
  },
  {
    "categoryName": "Business & Management",
    "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6",
    "subCategories": [
      {
        "id": "142aa5ff-9cba-4848-99c7-ab026f05ef0f",
        "name": "Business Strategy",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "1ff90a48-e8cd-4a47-9023-f3c645c2add7",
        "name": "Communication",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "93268056-52fc-4dc9-9075-5bbbb7a828c7",
        "name": "Economics",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "baf03320-8fcf-49c7-b5a2-85df2094ca0c",
        "name": "Entrepreneurship",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "f6b22d3e-0ff9-4567-84ba-0f7c3d1453b3",
        "name": "Finance",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "acd982f3-8cc8-490c-9ecd-d50f33bf261c",
        "name": "Human Resources",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "e58e6878-8169-4db2-b544-92e2c42bdb1f",
        "name": "E-commerce",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "765fe36e-aca2-43f2-9034-28bfafb34434",
        "name": "Leadership",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "f05ef006-a4c0-4fe7-9eb9-76d74155fb4d",
        "name": "Marketing",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "cd5a5da9-845f-4b8b-9f4f-2eca648a6a55",
        "name": "Operations",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "d91b1dce-4bd7-42fc-b6de-d989be9178a4",
        "name": "Product Development",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "5d3ca043-905f-4e2a-b00c-8732273672be",
        "name": "Project Management",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      },
      {
        "id": "41236e92-428c-4f80-945a-d24470cb5766",
        "name": "Sales",
        "categoryID": "461befa5-24ca-4d67-acac-da7b6a856bc6"
      }
    ]
  },
  {
    "categoryName": "Personal Development",
    "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a",
    "subCategories": [
      {
        "id": "ed31f0e2-578f-4966-912d-65b0fb1901af",
        "name": "Career Development",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "7045f58c-996d-446e-8673-228d3b6199b5",
        "name": "Cooking & Culinary",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "273afdee-9f6e-43a2-97cb-2b9e00e18a1b",
        "name": "Esoteric Practices",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "3a9961e5-e574-4a5d-818b-0de3ea530859",
        "name": "Happiness",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "cd295772-13f4-4e70-a3b9-f9a01e2e8bf8",
        "name": "Leadership",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "0388e01d-0e15-40c6-9da4-c7fc8d1be61e",
        "name": "Life Essentials",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "eb01ea51-361f-4522-8a04-34ccf47f66fd",
        "name": "Other Personal Development",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "fec6a2e8-9c23-4669-9360-d473e4574ec5",
        "name": "Parenting & Relationships",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "b3b73503-0119-4c78-b0c2-ca9cd3e38234",
        "name": "Personal Productivity",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "db3b3e03-868a-49a9-874a-f71810606efc",
        "name": "Religion & Spirituality",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      },
      {
        "id": "fade809e-962f-41c3-8a33-540e552627d5",
        "name": "Stress Management",
        "categoryID": "d87b8d22-2161-4476-8d5b-688fa337c25a"
      }
    ]
  },
  {
    "categoryName": "Creative & Performing Arts",
    "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730",
    "subCategories": [
      {
        "id": "67cfae24-0215-4545-a17a-e7a430293fee",
        "name": "Crafts",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "c611253f-420b-466f-a8f0-fdfe85731d09",
        "name": "Dance",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "c709ca55-688e-43a4-a8f7-3554fcecb93d",
        "name": "Instrumental",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "c970cac9-05ef-4d02-90b2-a06395fab6f2",
        "name": "Music",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "beaf4077-6c1f-4179-b27d-75e8ff546adf",
        "name": "Other Art Forms",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "1abd33dd-1d04-4eec-a081-385e9277ff51",
        "name": "Painting",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "368da90d-71be-4f33-874f-fffd4f7cabc1",
        "name": "Sculpting",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      },
      {
        "id": "c6c42ce0-2833-4c05-b0b8-ebfad10f02de",
        "name": "Theatre",
        "categoryID": "6d06807f-73b1-4da7-88df-3c327f270730"
      }
    ]
  },
  {
    "categoryName": "Photography, Audio & Video",
    "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a",
    "subCategories": [
      {
        "id": "d969175c-cf01-439b-88e4-dfb463781c61",
        "name": "Aerial and landscape",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "12358267-9d77-4b68-a0ef-c2ce72c863f6",
        "name": "Camera maintenance",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "304ff547-c163-4b60-a5c2-514e34df8854",
        "name": "Film direction",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "54d98e3d-0fd4-43cb-8502-a93f52041dd3",
        "name": "Photo editing",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "b8b09dd2-004f-476c-9839-09deb9b6df16",
        "name": "Photography techniques",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "25c05389-a13c-4cd4-9395-6676cabd84e0",
        "name": "Portrait photography",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "df481ced-66df-45d1-b488-16a173992ac0",
        "name": "Sound mixing",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "6bfd7f60-fc3d-41f6-8b59-f06597ad56c1",
        "name": "Tools",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "dcd70582-3df8-49c1-9a98-bfc16be92b75",
        "name": "Video editing",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      },
      {
        "id": "a7c3c7b2-2a32-41e3-8659-056f8ad4d57f",
        "name": "Wildlife photography",
        "categoryID": "cddeff20-75f4-4860-9c8e-7d73b813773a"
      }
    ]
  },
  {
    "categoryName": "Design",
    "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d",
    "subCategories": [
      {
        "id": "681aaf9e-8041-4aaa-8737-999fc9850e9c",
        "name": "3D Modelling & Animations",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "d5c101e5-98b9-47c5-8fac-694e1e62a71b",
        "name": "Architectural Design",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "5239bf0d-e4cb-4902-8113-7868659492ff",
        "name": "Design Thinking",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "55022909-69aa-4e38-a30b-0c166a0d2ba9",
        "name": "Design Tools",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "3abf8c48-553d-4847-a368-4616de920b00",
        "name": "Fashion Design",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "67b281af-f20f-4ebb-8a15-fbd75ec36c89",
        "name": "Game Design",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "ed8eaf0a-0a2b-46f6-bd00-ef13fd153909",
        "name": "Graphic Design & Illustrations",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "a145add7-a889-4c8a-a34a-768709ba06f3",
        "name": "Interior Design",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "52e29c74-799a-4861-b11f-3babd82341ef",
        "name": "UX & UI",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      },
      {
        "id": "2f9ac17a-9905-46a3-b3a0-2d0e69069cb3",
        "name": "Web Design",
        "categoryID": "a42967a0-1def-4769-969f-c6dd5b0ac05d"
      }
    ]
  },
  {
    "categoryName": "Health and Wellness",
    "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f",
    "subCategories": [
      {
        "id": "18d60dae-7f8d-4792-b391-92e62e916f32",
        "name": "Alternate Healing",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "072a4a9d-b3c5-49fc-adb3-7ea697653df1",
        "name": "Ayurveda",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "61295b16-7210-448f-9aa2-1ad2201d9bf4",
        "name": "Dieting",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "74c76b99-da98-47a6-9279-f290304a446d",
        "name": "Fitness",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "576289a0-c6c0-4f10-b335-09458b7a425c",
        "name": "Food & Nutrition",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "11a7a926-cb10-4d05-8784-fa25a6b3dcf5",
        "name": "General Health",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "8363d47c-89b7-4c22-b12c-3b353eeeba17",
        "name": "Mental Health",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "639d9be2-fa94-4364-b26f-bacd80a9c2cb",
        "name": "Other Health & Fitness",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "3195d7b9-7f96-43fc-a613-552bcff94472",
        "name": "Safety & First Aid",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "bc909977-5496-4dd7-8c9c-ddf4e80615dc",
        "name": "Self Defence",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "1b47e941-9892-48b7-ab89-18d1e85e84bd",
        "name": "Sports",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      },
      {
        "id": "c23741a1-dec7-4ce0-b638-978eac54fc7f",
        "name": "Yoga & Meditation",
        "categoryID": "000601ad-6a54-4de3-8735-a7fb10bbe96f"
      }
    ]
  },
  {
    "categoryName": "Lifestyle",
    "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1",
    "subCategories": [
      {
        "id": "968d20e0-0126-48ad-b018-718356cebe42",
        "name": "Beauty & Makeup",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "d4eb7c0c-f930-4cc0-a237-547989c6abaf",
        "name": "Esoteric Practices",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "3799322f-9560-4692-8100-1def0f415e0b",
        "name": "Food & Beverages",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "43a7a4e4-577d-42bd-8267-1da7a3755969",
        "name": "Gaming",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "208a1c94-5deb-423c-9945-270c6b543ba7",
        "name": "Home Improvement",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "0a837944-b9c6-4236-9f74-9f09cfc95c62",
        "name": "Petcare & Training",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      },
      {
        "id": "9555bf6f-dd12-4142-8eaa-2f0de821c7f5",
        "name": "Travel",
        "categoryID": "e72e5305-d3ea-4c98-95ee-2e357daa68c1"
      }
    ]
  },
  {
    "categoryName": "Teaching & Academics",
    "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329",
    "subCategories": [
      {
        "id": "450cdca1-964f-4947-b5dd-02f9a1905ca8",
        "name": "Architecture",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "f46f8b48-9650-49e8-8dde-32b7e4bdb738",
        "name": "Biology",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "04f763fc-d159-4932-a436-19cee972a142",
        "name": "Chemistry",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "6474eefe-bdcb-4145-998e-4079fdec72e2",
        "name": "Economics",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "92c23908-a976-4881-97f9-e6e0372c531b",
        "name": "Engineering",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "bd0c2548-5479-42f0-a3e1-3ba5b428979b",
        "name": "Environmental Sciences",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "a04c51d5-96c0-4dfc-a855-28205c97fd04",
        "name": "Languages",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "5c208271-7a25-4baf-9606-25382c7f8181",
        "name": "Mathematics",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "440d7148-9ae4-4411-bf24-a7471e4c339f",
        "name": "Medicine",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "561bf73f-5d94-47b1-b5d0-0e7978a94b2c",
        "name": "Physics",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      },
      {
        "id": "f9eb4f04-783c-45e7-a18f-0d5ef0177f0b",
        "name": "Social Science",
        "categoryID": "6944baa7-e0f0-4415-8f13-6b1665b1c329"
      }
    ]
  },
  {
    "categoryName": "Skill Development",
    "categoryID": "989a64f6-5269-4146-9776-027fd65e774d",
    "subCategories": [
      {
        "id": "ef265df5-c3e3-464a-b5a3-6aa6aaa399a8",
        "name": "Automobile",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "5ad91541-54f4-4645-8c6e-27db5b68bf88",
        "name": "Carpentry",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "0039fa72-dd7c-4dad-a0d1-ddcb8f74296e",
        "name": "Electrical",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "25732c29-9103-4f6a-bf08-ce48fd1427b7",
        "name": "Field Technicians",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "41d25cff-60cd-4ee9-8fd2-a567488bbe42",
        "name": "Gardening",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "ef2da3ca-ceb2-430e-a402-7bbb9016ca16",
        "name": "Handicrafts",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "17b41647-ecab-4ec1-9b91-c413c5871fb0",
        "name": "Heavy Machinary",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "c3a5e415-9cc8-4bd8-9b58-65130bfe17ed",
        "name": "Masonry",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "13ab9281-9d3a-4bb2-a14a-90fd6e05cea5",
        "name": "Motor-works",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "48f79b6b-75b0-459f-9fae-9afaeab74f92",
        "name": "Plumbing",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "5e56cc27-3712-4a7b-990f-4654cf0a7378",
        "name": "Stitching and Knitting",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      },
      {
        "id": "789594b4-5560-41f5-a4c8-6bbbebd97808",
        "name": "Welding",
        "categoryID": "989a64f6-5269-4146-9776-027fd65e774d"
      }
    ]
  },
  {
    "categoryName": "Linguistic and Humanities",
    "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa",
    "subCategories": [
      {
        "id": "74ea1907-60fc-42bb-a38a-b6ac1085507d",
        "name": "Creative Writing",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "8236d15d-3ea6-4e74-94e1-a4a612fb9d18",
        "name": "Economics",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "6712dc8a-996d-408a-92da-450b20119939",
        "name": "Ethics",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "439943f4-14ec-49d2-928f-0c982b7dfef1",
        "name": "Geography",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "793f2ea0-8936-4e38-8632-6f68ab668b19",
        "name": "History",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "62209aaf-03ae-4717-a7da-bbda93b1ca91",
        "name": "Languages & Literature",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "42d3cdbc-b467-4586-8f51-19275dace277",
        "name": "Law",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "3ba465c0-4a4a-463f-bd76-8451647193a0",
        "name": "Media & Journalism",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "d4ba19b8-fe16-4a22-8a74-112b736483ba",
        "name": "Philosophy",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "e48da82c-2609-4d10-bacc-7c8adbd3bdf5",
        "name": "Political Science",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "234552bb-7db2-4c0f-9fa6-18f4e50f59b5",
        "name": "Psychology",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "5f0b8dcb-4062-42bc-a2ab-059e00947a2d",
        "name": "Sociology",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      },
      {
        "id": "b73d984c-a632-46bc-a301-b5139f685f55",
        "name": "Theology",
        "categoryID": "deb3cc9c-8921-4d02-bc65-9365ff480afa"
      }
    ]
  }
],

          "location":[{"key":"000","value":"Bengaluru","label":"Bengaluru","latitude":"12.972442","longitude":"77.580643"},
                      {"key":"1","value":"Hyderabad","label":"Hyderabad","latitude":"17.387140","longitude":"78.491684"},
                      {"key":"2","value":"Chennai","label":"Chennai","latitude":"13.067439","longitude":"80.237617"},
                      {"key":"3","value":"Delhi","label":"Delhi","latitude":"28.644800","longitude":"77.216721"},
                      {"key":"4","value":"Mumbai","label":"Mumbai","latitude":"19.076090","longitude":"72.877426"},
                      {"key":"5","value":"Trivandrum","label":"Trivandrum","latitude":"8.524139","longitude":"76.936638"}]
}

export default response;
