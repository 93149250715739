import { takeLatest, put, call, all } from "redux-saga/effects";
import ContentTypes from "../../common/contenttypes/content-types";
import InstructorOnBoardingTypes from "./instructoronboarding.types";
import UserActionTypes from "../user/user.types";
import { push } from "connected-react-router";
import Axios from "axios";

export function* submitNationalId(
  nationalIdObj,
  registrationValues
) {
  let uploadImgURL = process.env.REACT_APP_API_URL + "/file/s3";
  let data = new FormData();
  data.append("file", registrationValues["nationalId"]);
  return yield Axios.post(uploadImgURL, data);
}

export function* submitToApiForInstructorRegistration(
  registrationValues,
  uuid,
  action,
  currentStep
) {
  let url = process.env.REACT_APP_API_URL + "/instructor/";
  url += uuid + "/onboarding?action=" + action + "&currentStep=" + currentStep;

  if (registrationValues["nationalId"]) {
    const response = yield call(submitNationalId, registrationValues["nationalId"]);
    registrationValues["nationalId"] = response.data.s3File.id;
  }

//  let bodyFormData = new FormData();
//  for (let key in registrationValues) {
  //  var isKeyValue = !!registrationValues[key];

  //  if (isKeyValue) {
  //    bodyFormData.append(key, registrationValues[key]);
    //}
//  }
  console.log(registrationValues, "registrationValues")

  // Changing format of registration values before sending it to API

  let formatedValues ={
                        "currentStep": currentStep,
                        "personalDetails": {
                            "firstName": registrationValues.firstName,
                            "lastName": registrationValues.lastName,
                            "designation": registrationValues.designation,
                            "profileImgID": registrationValues.profileImgID,
                            "socialBio": registrationValues.socialBio,
                            "agreementAccepted": registrationValues.agreementAccepted,
                            "panNumber": registrationValues.panNumber,
                            "nationalID": registrationValues.nationalID,
                            "introVideoYoutubeURL": registrationValues.introVideoYoutubeURL
                        },
                        "address": {
                            "address1": registrationValues.address1,
                            "address2": registrationValues.address2,
                            "city": registrationValues.city,
                            "state": registrationValues.state,
                            "country": registrationValues.country,
                            "pincode": registrationValues.pincode
                        },
                        "socialMedia": registrationValues.socialMedia,
                        "socialMediaMoreLinks": registrationValues.socialMediaMoreLinks,
                        "bankDetails": {
                            "bankName": registrationValues.bankName,
                            "bankAcctName": registrationValues.bankAcctName,
                            "bankAcctNo":  registrationValues.bankAcctNo,
                            "bankIFSCCode": registrationValues.bankIFSCCode,
                            "gst": registrationValues.gst
                        }
                    }
                    console.log(formatedValues)
  return yield Axios.post(url, formatedValues)
}

export function* submitRegistrationForInstructor({
  payload: { registrationValues, uuid, action, currentStep },
}) {
  try {

    yield put({ type: "SHOW_LOADING" });
  let response =  yield call(
      submitToApiForInstructorRegistration,
      registrationValues,
      uuid,
      action,
      currentStep
    )
    let responseData = yield response;
    if(responseData.data.actionResult==="SUCCESS"){
  
      let onboardingStatus = responseData.data.onboardingDetails.instructorOnboardingStatus
      yield put({ type: "UPDATE_ONBOARDING_STATUS", payload: onboardingStatus })
    }

    yield put({ type: "HIDE_LOADING" });


    if(action==="SAVE"){
      yield put(push("/"));
    }
    else{
      yield put(push("/instructor-dashboard/courses"));
    }
  } catch (error) {
    if(action==="SAVE"){
      yield put(push("/"));
    }
    else{
      yield put(push("/instructor-dashboard/courses"));
    }
  }
}

export function* initiatePendingApproval() {
  yield put(push("/instructor/onboarding/info"));
}

export function* onSubmitRegistrationStart() {
  yield takeLatest(
    InstructorOnBoardingTypes.REGISTRATION_STARTED,
    submitRegistrationForInstructor
  );
}

export function* onSetPendingApproval() {
  yield takeLatest(
    InstructorOnBoardingTypes.SET_PENDING_APPROVAL,
    initiatePendingApproval
  );
}

export function* onboardingSagas() {
  yield all([call(onSubmitRegistrationStart), call(onSetPendingApproval)]);
}
