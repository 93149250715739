import { all, call, takeLatest } from "redux-saga/effects";
import MobileVerificationActionTypes from "./mobile-verification.types";
import { mobileOTPSendStart, mobileOTPVerifyStart, remobileOTPSendStart, OTPVerifyStart } from "./mobile-verification.sagas";

export function* onMobileOTPVerifyStart() {
    yield takeLatest(MobileVerificationActionTypes.MOBILE_VERIFY_OTP_START, mobileOTPVerifyStart);
}

export function* onOTPVerifyStart() {
    yield takeLatest(MobileVerificationActionTypes.VERIFY_OTP_START, OTPVerifyStart);
}

export function* onMobileOtpSend() {
    yield takeLatest(MobileVerificationActionTypes.MOBILE_OTP_SEND_START, mobileOTPSendStart);
}

export function* reonMobileOtpSend() {
    yield takeLatest(MobileVerificationActionTypes.MOBILE_OTP_SEND_RE_START, remobileOTPSendStart);
}

export function* mobileVerificationSagas() {
    yield all([call(onMobileOTPVerifyStart),
    call(onMobileOtpSend), call(reonMobileOtpSend), call(onOTPVerifyStart)]);
}
