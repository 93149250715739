import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import {
  selectIsInstructor,
  selectIsLearner,
  selectIsSignedIn,
  selectIsVenueProvider,
  selectOnboardingStatus,
  selectVenueProviderOnboardingStatus,
} from "../redux/user/user.selectors";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

const RouteWrapper = ({
  component: Component,
  publicRoute,
  learner,
  instructor,
  venueProvider,
  isVenueProvider,
  isLearner,
  isInstructor,
  isSignedIn,
  onboardingStatus,
  venueProviderOnboardingStatus,
  ...rest
}) => {
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  console.log(venueProviderOnboardingStatus, publicRoute, isSignedIn, instructor, isInstructor);
  if (!publicRoute && !isSignedIn) {
    console.log("Redirecting to signin page as the user is not signed in");
    return (
      <Redirect
        to={{
          pathname: "/",
          loginComponent: true,
        }}
      />
    );
  }

  // * Redirect user to home page if he tries to access instructor pages
  // * and he isnt instructor


  // If instructor hasnt submited onboarding, so he must be redirected to onboarding
  // when he tries to enter instructor route
  if (
    instructor &&
    isInstructor &&
    !window.location.pathname.includes("/onboarding")
  ) {

    console.log("onboardingStatus", onboardingStatus)
    switch (onboardingStatus) {
      case "INSTRUCTOR_PROFILE_INITIATED":
        return <Redirect to="/instructor/onboarding" />;
        break;

      case "ONBOARDING_FORM_IN_PROGRESS":
        return <Redirect to="/instructor/onboarding" />;
        break;

      case "SENT_BACK_FOR_CORRECTION":
        return <Redirect to="/instructor/onboarding" />;
        break;
    }
  }

  // If instructor submited onboarding, he must be redirected to instructor dashboard,
  // onboarding must be blocked.
  if (
    instructor &&
    isInstructor &&
    window.location.pathname.includes("/onboarding")
  ) {
    console.log("onboardingStatus", onboardingStatus)
    switch (onboardingStatus) {
      case "PENDING_APPROVAL":
        return <Redirect to="/instructor-dashboard/batches" />;
        break;

      case "APPROVED":
        return <Redirect to="/instructor-dashboard/batches" />;
        break;

      case "REJECTED":
        return <Redirect to="/instructor-dashboard/batches" />;
        break;
    }
  }




   // If instructor hasnt submited onboarding, so he must be redirected to onboarding
  // when he tries to enter instructor route
  if (
    venueProvider &&
    isVenueProvider &&
    !window.location.pathname.includes("/onboarding")
  ) {
    switch (venueProviderOnboardingStatus) {
      case "VENUE_PROVIDER_PROFILE_INITIATED":
        return <Redirect to="/venue-provider/onboarding" />;
        break;

      case "ONBOARDING_FORM_IN_PROGRESS":
        return <Redirect to="/venue-provider/onboarding" />;
        break;

      case "SENT_BACK_FOR_CORRECTION":
        return <Redirect to="/venue-provider/onboarding" />;
        break;
    }
  }

  // If instructor submited onboarding, he must be redirected to instructor dashboard,
  // onboarding must be blocked.
  if (
    venueProvider &&
    isVenueProvider &&
    window.location.pathname.includes("/onboarding")
  ) {
    switch (venueProviderOnboardingStatus) {
      case "PENDING_APPROVAL":
        return <Redirect to="/venue-provider-dashboard/batches" />;
        break;

      case "APPROVED":
        return <Redirect to="/venue-provider-dashboard/batches" />;
        break;

      case "REJECTED":
        return <Redirect to="/venue-provider-dashboard/batches" />;
        break;
    }
  }


  // if ((instructor && !isInstructor) || (venueProvider && !isVenueProvider)) {
  //   console.log("instructor && !isInstructor", instructor && !isInstructor)
  //   return <Redirect to="/" />;
  // }
  

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  /*
        if (isPublic && isSignedIn) {
            return <Redirect to="/learner"/>;
        }
    */

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={Component} />;
};

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

RouteWrapper.defaultProps = {
  isPublic: false,
  isLearner: false,
  isInstructor: false,
};

const mapStateToProps = createStructuredSelector({
  isSignedIn: selectIsSignedIn,
  isLearner: selectIsLearner,
  isInstructor: selectIsInstructor,
  isVenueProvider: selectIsVenueProvider,
  venueProviderOnboardingStatus: selectVenueProviderOnboardingStatus,
});

export default connect(mapStateToProps, null)(RouteWrapper);
