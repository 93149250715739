import React, { useState } from "react";
// import "./cartCourse.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import removeFromCart from "../../../cartPage/cart/removeFromCart.js";
import wishlistToCart from "../../../cartPage/cart/wishlistToCart.js";
import removeFromWishlist from "../removeCourseFromWishlist.js";
import {
  addToCart,
  addToWishlist,
  cartToWishlistAction,
  updateArrays
} from "../../../../redux/cart/cart-actions.js";
import { faCalendar, faRupeeSign } from "@fortawesome/free-solid-svg-icons";

const CartCourse = (props) => {
  console.log("propspropspropspropsprops====", props)
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cartArray);
  const date = moment(
    props.date.includes("[Etc/UTC]")
      ? props.date.replace("[Etc/UTC]", "")
      : props.date
  ).format("Do MMM YYYY");
  const user = useSelector((state) => state.user.currentUser);
  // console.log('propspropsprops45234343', props);
  const removeCourse = async () => {
    if (props.action === "cart") {
      let response = await removeFromCart(
        props.id,
        localStorage.getItem("anonymousUserID"),
        user === null ? "" : user.id
      );
      if (response.actionResult === "SUCCESS") {
        let cart = response.cart.cartItemList;
        let tax = response.cart.tax;
        let totalAmount = response.cart.totalAmount;
        let totalDiscount = response.cart.totalDiscount;
        let grandTotalAmount = response.cart.grandTotalAmount;

        let obj = {
          cartArray: cart,
          tax: tax,
          totalAmount: totalAmount,
          grandTotalAmount: grandTotalAmount,
          totalDiscount: totalDiscount,
          data: response,
        };

        console.log("obj", obj)
        dispatch(addToCart(obj));
      }
    } else {
      let response = await wishlistToCart(
        props.id,
        user === null ? "" : user.id
      );
      if (response.actionResult === "SUCCESS") {
        // Handle redux state
        if (response.actionResult === "SUCCESS") {
          let cart = response.cart.cartItemList;
          let tax = response.cart.tax;
          let totalAmount = response.cart.totalAmount;
          let totalDiscount = response.cart.totalDiscount;
          let grandTotalAmount = response.cart.grandTotalAmount;
          let wishlist = response.wishlist.wishListItemList;
          let saveLater = response.saveLater.saveLaterItemList

          let obj = {
            cartArray: cart,
            tax: tax,
            totalAmount: totalAmount,
            grandTotalAmount: grandTotalAmount,
            totalDiscount: totalDiscount,
            wishlistArray: wishlist,
            saveLaterArray: saveLater
          };

          dispatch(updateArrays(obj))
        }
      }
    }
  };

  return (
    <div
      className="cartCourse-dropdown"
      id="cartCourse-dropdown"
      onMouseEnter={() => setShowRemoveBtn(true)}
      onMouseLeave={() => setShowRemoveBtn(false)}
    >
      <div
        className="cartCourse-dropdown-image"
        style={{ background: `url(${props.img})` }}
      ></div>
      {/* <img src={props.img} alt="course"/> */}
      <div className="cartCourse-dropdown-content">
        <div className="cartCourse-dropdown-title">
          {props.title.length < 23
            ? props.title
            : props.title.slice(0, 23) + "..."}
        </div>
        <div className="cartCourse-dropdown-sub">By {props.author}</div>
        <div className="cartCourse-dropdown-date">
          <FontAwesomeIcon icon={faCalendar} style={{ marginRight: "5px" }} />
          {date}
        </div>
        <div className="cartCourse-dropdown-price" style={{ display: 'flex', alignItems: 'center', wordBreak: 'normal', whiteSpace: 'nowrap', gap: '6px', marginTop:2 }}> 
          {/* <FontAwesomeIcon icon={faRupeeSign} style={{ marginRight: "5px" }} /> */}
          {/* {props.price} */}

          {
                props.oldPrice > 0 ||  props.price > 0?
                props.oldPrice > props.price?
                    <>
                      <div style={{ textAlign: "left", color:"#000", fontSize:16 }}>
                        <FontAwesomeIcon icon={faRupeeSign} style={{color:"#000"}} />
                        {props.price} 
                      </div>
                    <div className="main-price" style={{ textAlign: "left", color:"#00000070", fontSize:12 ,  textDecoration: 'line-through'}}>
                      {""}
                      <FontAwesomeIcon icon={faRupeeSign} style={{color:"#00000070"}} />
                     {props.oldPrice}
                    </div>
                      {
                        props.discount > 0 ?
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: 12,
                              color: "#EB5757",
                              width: '100%',
                              textAlign: 'left'
                            }}
                          >
                            {(props.discount/props.oldPrice*100).toFixed(0)}% off
                          </span>
                        :null
                      }
                    </>
                  :
                  <div style={{ textAlign: "left" }}>
                    <FontAwesomeIcon icon={faRupeeSign} />
                    {props.price} 
                  </div>
                :<div style={{ textAlign: "left" }}>{'FREE'}</div>

              }

        </div>
      </div>

      {
        <div className="cartCourse-backdrop">
          <button
            id="cartCourse-remove"
            className="cartCourse-remove"
            onClick={removeCourse}
          >
            {props.action === "cart" ? "Remove" : "Add to Cart"}
          </button>
        </div>
      }
    </div>
  );
};

export default CartCourse;
