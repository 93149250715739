import { Empty } from 'antd';
import React,{useState} from 'react';
import VenueBooking from '../../../venue-provider-dashboard/bookings/venue-booking/VenueBooking';


const BookingsDetailsByVenueId = props => {
  console.log("props", props);
  return(
    <div className="instCourseDetails-content">
      {/* {
        props && props.bookings?
          props.bookings.map((booking)=>(
            <VenueBooking
              key={booking.id}
              
              // courseBatchId={courseBatch.courseBatchID}
              // title={courseBatch.title}
              // schedule={courseBatch?.schedule?.briefCourseSchedule}
              // price={courseBatch?.discountedPrice}
              // data = {courseBatch}
              // location={courseBatch.venueType=="ONLINE" ? courseBatch?.onlineMeeting?.meetingLink : courseBatch?.address}
              // studentsEnrolled={courseBatch.enrollmentCount}
              // venueType={courseBatch.venueType}
              // courseBatchStatus={getCourseBatchStatus(
              // courseBatch.courseBatchStatus
              // )}
              // visibilityStatus={getVisibilityStatus(courseBatch.visibilityStatus)}
              // img={courseBatch.thumbnailImageURL}
              // updateCourseBatchName={props.updateCourseBatchName}
              // updateCourseBatchID={props.updateCourseBatchID}
              // activeTab={props.activeTab}
              // setActiveTab={props.setActiveTab}
          />
          ))
        :null
      } */}
    {
      props && props.bookings && props.bookings.length>0?
        props.bookings.map((booking)=>(
        <VenueBooking
          key={booking.id}
          booking={booking}
        />
      ))
      :<div className='empty_data'>
        <Empty />
      </div>
    } 
    </div>
);}

export default BookingsDetailsByVenueId;
