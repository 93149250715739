const fetchVenueDetails = async (courseID) => {
  const response = await fetch(
    process.env.REACT_APP_API_URL + `/venues/${courseID}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const result = await response.json();
  console.log(result);
  return result;
};
export default fetchVenueDetails;
