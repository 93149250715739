import styled from 'styled-components';

export const AnnouncementStyleContainer = styled.div`

.instructorBatch-details{
  .tabTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom:15px;
    border-bottom: 1px solid #EDEDED;
  }
  .instructorBatch-details-head{
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  
    padding: 10px 0px;
    width: 100%;
    color: #041016;
  }
  .add_button{
    border: 0;
    color: #677eea;
    font-weight: 600;
    font-size: 12px;
  }
}
.instructorBatch-announcmentsList{
  .ant-collapse-header{
    display: flex;
    align-items: center;
    .ant-collapse-extra{
      .ant-btn.ant-btn-primary{
        background: rgb(103, 126, 234);
        color: rgb(255, 255, 255);
        border: 0px;
        padding: 6px 12px;
        border-radius: 20px;
        height: 25px;
        line-height: 12px;
        font-size: 12px;
      }
    }
  }
}

.announcements-list{
  .ant-list-header{
    padding: 10px 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #000000;
    background: #F0F5FF;
    border: 1px solid #E0E0E0;
    border-radius: 4px 4px 0px 0px;

  }
  .ant-list-items{
    max-height: 400px;
    overflow: auto;
    border-top:0px;
    
    .ant-list-item{
      padding: 12px;
      padding-left: 25px;
      position: relative;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      margin-bottom: 10px;
      color: #626262;
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: #667EEA;
        border-radius: 100px;
        left: 12px;
        top: 18px;
      }
      .ant-list-item-meta-title{
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;  
        color: #041016;
      }
      .ant-list-item-meta-description{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #041016;
      }
    }
  }
 
}
.instructorBatch-details-close{
  color: #EB5757;
  font-size: 17px;
}

// .instructorBatch-announcmentsList{
//   .ant-collapse{
//     background: transparent;
//     border: 0;
//     .ant-collapse-item{
//       background: #FFFFFF;
//       border: 1px solid #EDEDED;
//       box-shadow: 0px 4px 2px rgb(0 0 0 / 3%);
//       border-radius: 4px;
//       margin-bottom: 10px;
//       .ant-collapse-header{
//         // background: #667EEA;
//         border-radius: 3px 3px 0px 0px;
//         color: #333;
//         .ant-collapse-header-text{
//           font-weight: 700;
//           font-size: 14px;
//           line-height: 17px;
//           letter-spacing: 0.02em;
//           color: #333;
//         }
//       }
//       .instructorBatch-reviewContent{
//         .rating{
//           margin-bottom:20px;
//         }
//         .comment_box{
//           background: #FFFFFF;
//           border: 1px solid #EDEDED;
//           box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.03);
//           border-radius: 4px;
//           padding:20px;
//         }
//         .comment{
//           font-weight: 400;
//           font-size: 14px;
//           line-height: 17px;
//           letter-spacing: 0.02em;
//           margin-bottom:20px;
//           color: #041016;
//         }
//         .comment-date{
//           font-weight: 400;
//           font-size: 12px;
//           line-height: 14px;
//           letter-spacing: 0.02em;
//           color: #4F4F4F;
//           margin-bottom: 20px;
//         }
//         .action-row{
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           padding: 10px 10px 0px 10px;
//           div:first-child{

//           }
//           div:last-child{
//             .ant-btn{
//               color:#667EEA;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// .instructorBatch-announcmentsList > div:first-child{
//   background: #F0F5FF;
//   border-bottom: 1px solid #E0E0E0;
//   padding-left: 20px;
//   padding-right: 37px;
//   height: 45px;
//   display: flex;
//   align-items: center;
// }
// .instructorBatch-announcmentsList > div:last-child{
//   overflow-y: scroll;
//   max-height: 351px;
// }
// .instructorBatch-announcmentsItem{
//   max-width: 91%;
//   font-size: 14px;
//   line-height: 17px;
//   color: #041016;
//   border-bottom: 1px solid #E2E2E2;
//   margin-top: 20px;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   margin-left: 28px;
// }
// .instructorBatch-announcmentsItem > div:first-child{
//   font-weight: bold;
//   letter-spacing: 0.02em;
//   margin-bottom: 8px;
// }
// .instructorBatch-announcmentsItem::before{
//     content:"\A";
//     width:6px;
//     height:6px;
//     border-radius:50%;
//     background: #667EEA;
//     display: inline-block;
//     margin-right: 2px;
//     position: absolute;
//     top: 6px;
//     left: -14px;
// }
// .instructorBatch-announcmentsItem > div:nth-child(2){
//  margin-bottom: 8px;
// }
// .instructorBatch-announcmentsItem > div:last-child{
//   font-style: italic;
//   font-size: 12px;
//   line-height: 14px;
//   letter-spacing: 0.02em;
//   color: #626262;
//   margin-bottom: 15px;
// }
// .instructorBatch-newAnnouncement{
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 17px;
//   letter-spacing: 0.02em;
//   color: #000000;
//   margin-bottom: 20px !important;
//   display: flex;
//   flex-direction: column;
// }
// .instructorBatch-newItem{
//   margin-bottom: 15px;
// }
// .instructorBatch-newItem > label{
//   font-size: 14px;
//   line-height: 17px;
//   letter-spacing: 0.02em;
//   color: #000000;
//   margin-bottom: 8px;
// }
// .instructorBatch-newItem > textarea{
//   border: 1px solid #EDEDED;
//   box-sizing: border-box;
//   border-radius: 4px;
//   height: 77px;
//   resize: none;
//   width: 100%;
//   font-size: 14px;
//   line-height: 17px;
//   letter-spacing: 0.02em;
//   color: #041016;
//   padding: 10px;
// }
// .instructorBatch-newItem > textarea[isTitle=yes]{
//   height: 47px;
// }
// .instructorBatch-newItem > textarea:focus{
//   outline: 1px solid #81C4E9;
// }



`

