import styled from 'styled-components';
import { themeColors } from '../../../theme';

export const CoursesContainer = styled.div`
  width:100%;
  .no-content{
    width: 100%;
    padding:30px 10px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width:60px;
      height:auto;
    }
    h3{
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height */
      
      display: flex;
      align-items: center;
      
      color: #041016;
    }
    p{
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      
      color: #041016;
    }
    h5{
      font-weight: 400;
      font-size: 19px;
      line-height: 23px;
      /* identical to box height */
      
      display: flex;
      align-items: center;
      
      color: #4F4F4F;
    }
    .add_button{
      margin-top:30px;
      background: #667EEA;
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      padding: 10px 30px;
      align-items: center;
      letter-spacing: 0.02em;

      color: #FFFFFF;
    }
  }
  .page-sub-header{
    width: 100%;
    
    display: flex;
    flex-diraction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .search-box{
      width: 100%;
      min-width: 320px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      input{
        border: 1px solid #EDEDED;
        border-radius: 4px 0px 0px 4px;
        padding:0px 10px;
        height: 40px;
      }
      .myCourses-searchIcon{
        width: 40px !important;
        height: 40px !important;
        background: #667EEA;
        padding: 0;
        color:#fff;
        border: 0;
        border-radius: 0px 4px 4px 0px;
      }
    }
    .batches-timeline{
      height: 40px !important;
      color:#fff;
      border: 0;
      display:flex;
      gap:30px;
      .ant-btn{
        height: 40px !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #4F4F4F;
        &.active{
          color: #667EEA;
        }
      }
    }
  }
  
`


export const CourseBatchCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  background: #FFFFFF;
  border: 1px solid rgba(79, 79, 79, 0.15);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
  a.course-button{
    color: ${themeColors.primary};
  }
  .instDashboard-batch{
    width: 100%;
    .row{
      width: 100%;
    }
    .course-img{
      width:100%;
      height:auto;
      border-radius: 4px;
      overflow: hidden;
      img{
        width:100%;
        aspect-ratio: 16/9;
      }
    }
    .batch-info{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      .heading{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        margin-bottom:10px;
        color: ${themeColors.textDark};
      }
      .batch-item{
        margin-bottom:10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #4F4F4F;
        a{
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.02em;
          line-height: 17px;
          color: #4F4F4F;
        }
        svg{
          margin-right:5px;
          color:#667EEA;
        }
      }
      .rupee-sign{
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: 17px;
        display: flex;
        align-items: center;
        margin-bottom:10px;
        color: #EB5757;
        svg{
          margin-right:5px;
          color:#EB5757;
        }
      }
    }
    .action-part{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-left: 1px solid #E2E2E2;
      padding-left: 15px;
      height: 100%;
      .batch-right-item {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-bottom: 16px;
        .lable{
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #303030
        }
        .value{
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }
      .batch-right-status{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #4F4F4F;
        span{
          font-weight: 700;
          font-size: 13px;
          line-height: 17px;
          letter-spacing: 0.04em;
          color: #000000;
          .inprogress{
            color:#2DB417;
          }
          .upcoming{
            color:#EB5757;
          }
          .completed{
            color:#EC9F05;
          }
        }
      }
    }
  }
`