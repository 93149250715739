const fetchQustionAnswerByBatchesbyId = async (courseID, pageNum, pageSize) => {
  const response = await fetch(
    process.env.REACT_APP_API_URL + `/venue/question-answers/${courseID}/qna?pageNum=${pageNum}&pageSize=${pageSize}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  const result = await response.json();
  console.log(result);
  return result;
};
export default fetchQustionAnswerByBatchesbyId;