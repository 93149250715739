import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsSignedIn,
  selectIsInstructor,
  selectIsVenueProvider,
} from "../../redux/user/user.selectors";
import { Container, Button } from "react-bootstrap";
import {
  setCategory,
  setSearch,
  setCourseDate,
  setLocation,
  setSearchFieldInput,
  setTimelineLocation,
  setTrainingStartDate,
  setVenueType,
  resetFilters,
  setLanguage,
  setDuration,
  setAvgCustomerReview,
  setLevels,
  setPriceRange,
} from "../../redux/browse-courses/browse-courses-actions.js";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faSlidersH,
  faChevronDown,
  faCaretUp,
  faSearch,
  faTimes,
  faBars,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo.png";
import userdemo from "../../assets/userdemo.svg";
import CartIcon from "../../assets/cart.svg";
import HeartIcon from "../../assets/heart.svg";
// import "./header.css";
import {
  HeaderContainer,
  MobileContainer,
  LogoContainer,
  MenuContainer,
  CategoryContainer,
  SearchFilterContainer,
  CategorySearch,
  LinkButton,
  HeaderTop,
  TopRight,
  HeaderBottom,
} from "./header.styles";
import Category from "../browse-courses/navbar/category/category.jsx";
import MenuComponent from "./menu/menu";
import Location from "../browse-courses/navbar/location/location.jsx";
import ProfileIcons from "./profileIcons/profileIcons.jsx";
import HeaderDropdown from "./dropdown/headerDropdown.jsx";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import CartDropdown from "./cart-dropdown/cartDropdown.jsx";
import Select from "react-select";
import { useMatchBreakpoints } from "../../hooks";
import fetchCartCourses from "./cart-dropdown/fetchCartCourses.js";
import { addToCart } from "../../redux/cart/cart-actions.js";
import Axios from "axios";
import { store } from "react-notifications-component";
import { message, Popover } from "antd";


const Header = (props) => {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const locationUrl = useLocation();
  const { isLg, isXl } = useMatchBreakpoints();
  const isMobie = !isLg && !isXl;
  let searchFieldInput =  useSelector(
    (state) => state.browseCourses.searchFieldInput
  );
  const [showWishlist, setShowWishlist] = useState(false);
  const [searchkey, setSerarchKey] = useState('');
  const search = useSelector((state) => state.browseCourses.search);
  const trainingStartDate = useSelector(
    (state) => state.browseCourses.trainingStartDate
  );
  const cartArray = useSelector((state) => state.cart.cartArray);
  const wishlistArray = useSelector((state) => state.cart.wishlistArray);
  const isSignedIn = useSelector(selectIsSignedIn);
  console.log("isSignedIn", isSignedIn)
  const isInstructor = useSelector(selectIsInstructor);
  const isVenueProvider = useSelector(selectIsVenueProvider);
  const location = useSelector((state) => state.browseCourses.location);
  const user = useSelector((state) => state.user.currentUser);
  const venueType = useSelector((state) => state.browseCourses.venueType);

  console.log("user == = =  ", user);

  const timeFilterOptions = [
    { key: "001", value: "THIS_WEEK", label: "This Week" },
    { key: "002", value: "NEXT_WEEK", label: "Next Week" },
    { key: "003", value: "THIS_MONTH", label: "This Month" },
    { key: "004", value: "NEXT_THREE_MONTHS", label: "Next 3 Months" },
    { key: "005", value: "ALL_TIME", label: "All Time" },
  ];

  const [showDropdown, setShowDropdown] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  
  const [showAccount, setShowAccount] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [tempTimeFilter, setTempTimeFilter] = useState(timeFilterOptions[4]);
  const [tempLocation, setTempLocation] = useState({
    latitude: "",
    longitude: "",
    city: { key: "001", label: "All locations", value: "all" },
  });
  const [isMyLocation, setIsMyLocation] = useState(false);
  const [myLocationDetails, setMyLocationDetails] = useState({
    key: "0011",
    value: "getLocation",
    label: "Get My Location",
    latitude: "",
    longitude: "",
  });

  const handleMyLocationDetails = (arg) => setMyLocationDetails(arg);
  const handleIsMyLocation = (arg) => setIsMyLocation(arg);
  const handleTempLocation = (arg) => setTempLocation(arg);
  const handleMyAccBtns = (path) => {
    console.log("path", path);
    props.handleLogin(path);
    setShowAccount(false);
  };

  const customStyles = {
    dropdownIndicator: () => ({
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
  };
  console.log(location);
  console.log(tempLocation);

  const handleShowCart = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowCart(true);
    document.addEventListener("click", closeCart);
  };

  const handleShowSearch = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowSearch(true);
    document.addEventListener("click", closeCart);
  };

  

  const handleShowWishlist = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowWishlist(true);
    document.addEventListener("click", closeWishlist);
  };

  const closeWishlist = (e) => {
    let cartCourseRemove = document.getElementById("cartCourse-remove");
    let cartDropdown = document.getElementById("cartDropdown");
    if (e.target === cartDropdown || e.target === cartCourseRemove) {
    } else {
      setShowWishlist(false);
      document.removeEventListener("click", closeWishlist);
    }
  };

  const handleShowDropdown = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowDropdown(true);
    document.addEventListener("click", closeDropdown);
  };

  const handleShowAccount = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowAccount(true);
    document.addEventListener("click", closeAccount);
  };

  const filtersHandler = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowFilters(true);
    document.addEventListener("click", closeOutsideFilters);
  };

  const closeDropdown = (e) => {
    let profileDropDown = document.getElementById("profileDropDown");
    let profileMsgContainer = document.getElementById("profileMsgContainer");

    if (e.target !== (profileDropDown || profileMsgContainer)) {
      setShowDropdown(false);
      document.removeEventListener("click", closeDropdown);
    }
  };

  const closeAccount = (e) => {
    let myAccountDropdown = document.getElementById("myAccount-dropdown");
    let myAccountBox = document.getElementById("myAccount-box");
    if (e.target !== (myAccountDropdown || myAccountBox)) {
      setShowAccount(false);
      document.removeEventListener("click", closeAccount);
    }
  };

  const closeCart = (e) => {
    let cartCourseRemove = document.getElementById("cartCourse-remove");
    let cartDropdown = document.getElementById("cartDropdown");
    if (e.target === cartDropdown || e.target === cartCourseRemove) {
    } else {
      setShowCart(false);
      document.removeEventListener("click", closeCart);
    }
  };
  // This is called if user clicks outside of filters box, it will be closed...
  const closeOutsideFilters = (e) => {
    let headerFilterBox = document.getElementById("header-filter-box");
    let headerFilterOption1 = document.getElementById("responsiveLocation");
    let isResponsiveLocation = e.srcElement.closest("#responsiveLocation")?.id;
    let selectID = e.srcElement.id;
    if (
      e.target === headerFilterBox ||
      isResponsiveLocation === "responsiveLocation" ||
      selectID.includes("react-select")
    ) {
    } else {
      setShowFilters(false);
      document.removeEventListener("click", closeOutsideFilters);
    }
  };

  const handleClickSearch = () => {
    dispatch(setCategory(""));
    console.log("searchkeysearchkeysearchkeysearchkeysearchkey", searchkey);
    if(searchkey == ""){
      dispatch(setSearchFieldInput(""));
      dispatch(setSearch("")); 
      if (window.location.pathname !== "/course-search")
        history.push("/course-search");
    }else{
      dispatch(setSearch(searchFieldInput));
      if (window.location.pathname !== "/course-search")
        history.push("/course-search");
      setSerarchKey("");
    }
    dispatch(setLanguage('ALL'));
    dispatch(setCourseDate('',''));
    dispatch(setDuration([0,100000]));
    dispatch(setAvgCustomerReview("ZERO"));
    dispatch(setLevels("ALL"));
    dispatch(setPriceRange('[0,100000000000]'));
    dispatch(setTrainingStartDate("ALL_TIME"));
    dispatch(setCategory('001'));
  };

  let searchvalue = '';

  // useEffect(() => {
  //   if(searchkey==""){
  //     dispatch(setSearchFieldInput(""));
  //     dispatch(setSearch("")); 
  //   }
  // }, [searchkey]);

  useEffect(() => {
    console.log("searchkeysearchkeysearchkeysearchkeysearchkey1", searchkey);
    if(searchkey==""){

      dispatch(setSearchFieldInput(""));
      dispatch(setSearch("")); 
    }
  }, [1]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // searchFieldInput = '';
      // ref.current.value = '';
      // setSerarchKey('');
      handleClickSearch();
    }
  };

  const handleSearch = (e) =>{
     setSerarchKey(e.target.value);
     dispatch(setSearchFieldInput(e.target.value))
   };

  const handleClickFilter = (value) => {
    // This will trigger if we click on close btn in search bar timelineFilter
    if (value === "close") {
      dispatch(setTrainingStartDate("ALL_TIME"));
      setTempTimeFilter(timeFilterOptions[4]);
    }
    //This is case when we choose between options before clicking on filter btn
    else {
      setTempTimeFilter(value);
    }
  };
  // This is called when clicked on X btn in search box filter
  const handleCloseLocation = () => {
    let obj = {
      latitude: "",
      longitude: "",
      city: { key: "001", label: "All locations", value: "all" },
    };
    setTempLocation(obj);
    dispatch(setLocation(obj));
    dispatch(setVenueType("ALL"));
    if (window.location.pathname !== "/course-search")
      history.push("/course-search");
  };

  const handleFilterDropdown = () => {
    // This will set venueType to ONSITE if location is selected..
    let tempVenue;
    if (tempLocation.city.value !== "all") {
      tempVenue = "ONSITE";
    } else {
      tempVenue = "ALL";
    }
    let obj = {
      trainingStart: tempTimeFilter.value,
      location: tempLocation,
      venueType: tempVenue,
    };
    dispatch(setTimelineLocation(obj));
    setShowFilters(false);
    if (window.location.pathname !== "/course-search")
      history.push("/course-search");
  };

  const handleCancelFilterDropdown = () => {
    setShowFilters(false);
    // Handle option value in timeFilter select
    let index = timeFilterOptions.findIndex(
      (elem) => elem.value === trainingStartDate
    );
    setTempTimeFilter(timeFilterOptions[index]);

    //Handle option value in location filter
    setTempLocation(location);
  };

  let disableCategory;

  if ((isInstructor && locationUrl.pathname.includes("instructor")) || (isVenueProvider && locationUrl.pathname.includes("venue-provider"))) {
    if (!locationUrl.pathname.includes("instructor-profile") && !locationUrl.pathname.includes("instructors") && !locationUrl.pathname.includes("venue-providers")) {
      disableCategory = false;
    } else {
      disableCategory = true;
    }
  } else {
    disableCategory = true;
  }


  useEffect(() => {
    async function fetch() {
      console.log("isSignedIn", isSignedIn)
      if(isSignedIn){
          if(user && user.id){
            Axios.get(process.env.REACT_APP_API_URL + "/admin/"+user.id)
            .then((response) => {
              console.log(response, "dmodmeom");
              if(response.data && response.data.actionResult=="SUCCESS"){

              }else{
                message.error({ content:  `User Session not found please login again`, duration: 2 });
                
                localStorage.clear();
                history.push("/auth/login")
              }
              // setLocations(response.data);
            });
          }
      }
      // If this clause is true, then user got loged out, so we dont have either user or anonymousID then we dont call fetchCart API
      if (!user && localStorage.getItem("anonymousUserID") === "") {
      } else {
        let response = await fetchCartCourses(
          user === null ? "" : user.id,
          localStorage.getItem("anonymousUserID")
        );
        // Cart flow
        let cart = [];
        let tax = 0;
        let totalAmount = 0;
        let grandTotalAmount = 0;
        let totalDiscount = 0;
        if(response){
          cart = response.cartItemList;
          tax = response.tax;
          totalAmount = response.totalAmount;
          grandTotalAmount = response.grandTotalAmount;
          totalDiscount = response.totalDiscount;
        }

        let obj = {
          cartArray: cart,
          tax: tax,
          totalAmount: totalAmount,
          grandTotalAmount: grandTotalAmount,
          totalDiscount: totalDiscount,
          data: response,
        };

        if (obj.cartArray === undefined) {
          let defaultObj = {
            cartArray: [],
            tax: 0.0,
            totalAmount: 0.0,
            grandTotalAmount: 0.0,
            totalDiscount: 0.0,
          };
          dispatch(addToCart(defaultObj));
        } else {
          dispatch(addToCart(obj));
        }
      }
    }
    fetch();
  }, [1]);


  const loginAction = () => {
    // setIsOpen(false);
    history.push("/auth/login");
  };

  const signAction = () => {
    // setIsOpen(false);
    history.push("/auth/signup");
  };


  return (
    <HeaderContainer>
      {isMobie ? (
        <MobileContainer>
          <HeaderTop>
            <LogoContainer>
              <MenuComponent />
              {/* <FontAwesomeIcon icon={faBars} /> */}
              <LinkButton to="/">
                <img src={logo} className="logo" alt={"Logo"} />
              </LinkButton>
            </LogoContainer>
            <TopRight>
              {/* {isSignedIn ?
                <Button
                  onClick={!showCart ? handleShowWishlist : () => {}}
                  variant="link"
                >
                  <img className="shoppingCart mobile" src={HeartIcon} />
                  {wishlistArray.length > 0 ? <span>{wishlistArray.length}</span> : null}
                </Button>
                :null
              } */}
              {/* {showWishlist && <CartDropdown name="wishlist" />} */}
              {/* <Button
                onClick={!showCart ? handleShowCart : () => {}}
                variant="link"
              >
                {cartArray.length > 0 ? <span>{cartArray.length}</span> : null}
              </Button> */}
              {showCart && <CartDropdown name="cart" />}
              {
                showSearch?
                <Button
                  onClick={()=>{
                    setShowSearch(false)
                  }}
                  variant="link"
                >
                  <FontAwesomeIcon icon={faTimes} />
                  {/* <img className="shoppingCart mobile" src={faSearch} /> */}
                </Button>
                :<Button
                  onClick={handleShowSearch}
                  variant="link"
                >
                  <FontAwesomeIcon icon={faSearch} />
                  {/* <img className="shoppingCart mobile" src={faSearch} /> */}
                </Button>
              }
              
                {/* {isSignedIn ? (
                  <div className="header-dropdown-container">
                    <ProfileIcons
                      showDropdown={showDropdown}
                      handleShowDropdown={handleShowDropdown}
                    />
                    <HeaderDropdown
                      showDropdown={showDropdown}
                      handleShowDropdown={handleShowDropdown}
                    />
                  </div>
                ) :(
                  <>
                    <Button
                      onClick={!showCart ? handleShowCart : () => {}}
                      variant="link"
                    >
                      <img className="shoppingCart mobile" src={CartIcon} />
                      {cartArray.length > 0 ? <span>{cartArray.length}</span> : null}
                    </Button>
                    <Button
                      onClick={!showAccount ? handleShowAccount : () => {}}
                      variant="link"
                    >
                      <img
                        className="img-profile"
                        alt="profile"
                        src={"https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"}
                      />
                    </Button>

                      {showAccount && (
                        <div className="myAccount-dropdown" id="myAccount-dropdown">
                          <FontAwesomeIcon
                            className="icon-handPoint"
                            icon={faCaretUp}
                            size="2x"
                          />

                          <img src={userdemo} />

                          <div className="myAcc-dropdown-btns">
                            <Button
                              variant={"primary"}
                              onClick={() => history.push("/auth/login")}
                            >
                              Sign In
                            </Button>
                            <Button
                              variant={"outline-primary"}
                              onClick={() => history.push("/auth/signup")}
                            >
                              Register
                            </Button>
                          </div>

                          <LinkButton
                            className="bookings-button"
                            to="/view-bookings"
                            onClick={() => setShowAccount(false)}
                          >
                            My Bookings
                          </LinkButton>
                        </div>
                      )}
                  </>
                )} */}
            </TopRight>
          </HeaderTop>
          {
            showSearch?
              <HeaderBottom>
                <div className="mobile-content">
                  <input
                    type="text"
                    ref={ref}
                    placeholder="Search for Virtual/ Classroom Training"
                    // value={searchFieldInput}
                    value={searchkey}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="mobile-inputGroup">
                    
                    <div className="header-search-btn" onClick={handleClickSearch}>
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                  </div>
                </div>
                {/* <div
                  className="mobile-filterIcon"
                  style={{ color: showFilters ? "#4183c4" : "" }}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  <FontAwesomeIcon icon={faSlidersH} />
                </div> */}
              </HeaderBottom>
            :null
          }
          
        </MobileContainer>
      ) : (
        <>
          <LogoContainer>
            {/* <Button className="mobile-menu-button" variant="link"> <FontAwesomeIcon icon={faBars}/></Button> */}
            <LinkButton to="/">
              <img src={logo} className="logo" alt={"Logo"} />
            </LinkButton>
          </LogoContainer>

          {!disableCategory?
          <React.Fragment></React.Fragment>
            :<CategorySearch>
            <Category />
            <CategoryContainer>
              <div
                className={`header-search-content ${
                  showFilters ? "filteropen" : null
                } `}
              >
                <input
                  type="text"
                  ref={ref}
                  placeholder="Search for Virtual/ Classroom Training"
                  // value={searchFieldInput}
                  value={searchkey}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                />
              {/*<div className="header-search-inputGroup">
                  {location.city.value !== "all" && (
                    <div className="header-search-filterBox">
                      {location.city.value}
                      <span
                        onClick={handleCloseLocation}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                  )}

                  {trainingStartDate !== "ALL_TIME" && (
                    <div className="header-search-filterBox">
                      {trainingStartDate}
                      <span
                        onClick={() => handleClickFilter("close")}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>
                  )}

                  <div
                    className="header-search-filterIcon"
                    onClick={!showFilters ? filtersHandler : () => {}}
                  >
                    <FontAwesomeIcon icon={faSlidersH} />
                  </div>
                </div>*/}
                {/*showFilters && (
                  <SearchFilterContainer
                    className="dropdown"
                    id="header-filter-box"
                  >
                    <div className="search-filter-options">
                      <Location
                        tempLocation={tempLocation}
                        handleTempLocation={handleTempLocation}
                        isMyLocation={isMyLocation}
                        handleIsMyLocation={handleIsMyLocation}
                        myLocationDetails={myLocationDetails}
                        handleMyLocationDetails={handleMyLocationDetails}
                      />

                      <Select
                        id="responsiveLocation"
                        className="select-location"
                        styles={customStyles}
                        options={timeFilterOptions}
                        defaultValue={timeFilterOptions[4]}
                        value={tempTimeFilter}
                        onChange={handleClickFilter}
                      />
                    </div>

                    <div className="search-filter-actions">
                      <Button
                        variant="link"
                        className="header-filter-btn"
                        onClick={handleCancelFilterDropdown}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="primary"
                        className="header-filter-btn reverseBtn"
                        onClick={handleFilterDropdown}
                      >
                        Filter
                      </Button>
                    </div>
                  </SearchFilterContainer>
                )*/}
              </div>
              <div className="header-search-btn" onClick={handleClickSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </CategoryContainer>
          </CategorySearch>}

          <MenuContainer>
            {isSignedIn ? (
              <>
                {
                  locationUrl.pathname.includes("/venue") ?
                  null
                  :
                  <>
                  {  (user?.instructorId || (user?.instructor && user?.instructor?.onboardingStatus))?null:<LinkButton to="/become-an-instructor"      className="becomeInst">
                        Become an Instructor
                      </LinkButton>
                  }
                  
                  </>
                }
                  <div className="header-dropdown-container">
                    <ProfileIcons
                      showDropdown={showDropdown}
                      handleShowDropdown={handleShowDropdown}
                    />
                    <HeaderDropdown
                      showDropdown={showDropdown}
                      handleShowDropdown={handleShowDropdown}
                    />
                  </div>
              </>
            ) : (
              <>
              {
                locationUrl.pathname.includes("/venue") ?
                null
                :
                <>
                 {  (user?.instructorId || (user?.instructor && user?.instructor?.onboardingStatus))?null:<LinkButton to="/auth/signup/true" className="becomeInst">
                  Become an Instructor 
                </LinkButton>}
                </>}
                <div
                  className="icon-notification"
                  style={{ position: "relative" }}
                >
                  {/* <div
                    className="cartIcon"
                    onClick={!showCart ? handleShowCart : () => {}}
                  > */}
                    {/* <NotificationBadge
                      className="badge-notification"
                      count={cartArray.length}
                      effect={Effect.SCALE}
                    /> */}
                    {/* <img className="shoppingCart" src={CartIcon} /> */}
                    {/* <FontAwesomeIcon className="shoppingCart" icon={faShoppingCart} size="1x" /> */}
                  {/* </div> */}
                  {/* {showCart && <CartDropdown name="cart" />} */}
                </div>

                <div className="myAcc-relative">
                  <Button type="link" onClick={()=>loginAction()} className="login-button">Login</Button>
                  <Button type="primary" onClick={()=>signAction()} className="signup-button">Signup</Button>
                  {/* <div
                    className="myAccount-box"
                    id="myAccount-box"
                    onClick={!showAccount ? handleShowAccount : () => {}}
                  >
                    <div>My Account</div>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div> */}
                  {/* {showAccount && (
                    <div className="myAccount-dropdown" id="myAccount-dropdown">
                      <FontAwesomeIcon
                        className="icon-handPoint"
                        icon={faCaretUp}
                        size="2x"
                      />

                      <img src={userdemo} />

                      <div className="myAcc-dropdown-btns">
                        <Button
                          variant={"primary"}
                          onClick={() => history.push("/auth/login")}
                        >
                          Sign In
                        </Button>
                        <Button
                          variant={"outline-primary"}
                          onClick={() => history.push("/auth/signup")}
                        >
                          Register
                        </Button>
                      </div>

                      <LinkButton
                        className="bookings-button"
                        to="/view-bookings"
                        onClick={() => setShowAccount(false)}
                      >
                        My Bookings
                      </LinkButton>
                    </div>
                  )} */}
                </div>
              </>
            )}
          </MenuContainer>
        </>
      )}
    </HeaderContainer>
  );
};
export default Header;
