import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import "./profileIcons.css";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHeart } from "@fortawesome/free-regular-svg-icons";

import {
  faShoppingCart,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsLearner,
  selectIsInstructor,
  selectIsVenueProvider,
} from "../../../redux/user/user.selectors";
import CartDropdown from "../cart-dropdown/cartDropdown.jsx";
import fetchCartCourses from "../cart-dropdown/fetchCartCourses.js";
import fetchWishlistCourses from "../cart-dropdown/fetchWishlistCourses.js";
import { addToCart, addToWishlist } from "../../../redux/cart/cart-actions.js";
import { ProfileIconsContainer } from "./profileIcons.styles";
import CartIcon from "../../../assets/cart.svg";
import BellIcon from "../../../assets/bell.svg";
import HeartIcon from "../../../assets/heart.svg";
import { LinkButton } from "../header.styles";
import Axios from "axios";
import { Badge } from "antd";

const ProfileIcons = (props) => {
  const locationUrl = useLocation();
  const [counter, setCounter] = useState(2);
  const [unreadCount, setunreadCount] = useState(0);
  const [cartCounter, setCartCounter] = useState(0);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const handleShowCart = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowCart(true);
    document.addEventListener("click", closeCart);
  };

  const handleShowWishlist = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setShowWishlist(true);
    document.addEventListener("click", closeWishlist);
  };

  const closeCart = (e) => {
    let cartCourseRemove = document.getElementById("cartCourse-remove");
    let cartDropdown = document.getElementById("cartDropdown");
    if (e.target === cartDropdown || e.target === cartCourseRemove) {
    } else {
      setShowCart(false);
      document.removeEventListener("click", closeCart);
    }
  };
  const closeWishlist = (e) => {
    let cartCourseRemove = document.getElementById("cartCourse-remove");
    let cartDropdown = document.getElementById("cartDropdown");
    if (e.target === cartDropdown || e.target === cartCourseRemove) {
    } else {
      setShowWishlist(false);
      document.removeEventListener("click", closeWishlist);
    }
  };

  const cartArray = useSelector((state) => state.cart.cartArray);
  const wishlistArray = useSelector((state) => state.cart.wishlistArray);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const isLearner = useSelector(selectIsLearner);
  const isInstructor = useSelector(selectIsInstructor);
  const isVenueProvider = useSelector(selectIsVenueProvider);

  let disableIcon;

  if ((isInstructor && locationUrl.pathname.includes("instructor")) || (isVenueProvider && locationUrl.pathname.includes("venue-provider"))) {
    if (!locationUrl.pathname.includes("instructor-profile") && !locationUrl.pathname.includes("instructors") && !locationUrl.pathname.includes("venue-providers")) {
      disableIcon = false;
    } else {
      disableIcon = true;
    }
  } else {
    disableIcon = true;
  }

  useEffect(() => {
    if (location.pathname.includes("/messages") || location.pathname.includes("/instructor-dashboard/batches") || location.pathname.includes("/instructor-dashboard/messages")) {
      setunreadCount(0);
    }
  }, [location])


  useEffect(() => {
    async function fetch() {

      if (user && user.id) {
        let response = await Axios.get(process.env.REACT_APP_API_URL + "/room/unread/count/" + user.id);
        console.log("call api response", response)

        if (response.data.actionResult == "SUCCESS") {
          setunreadCount(response.data.count);
        }

      }

      console.log(localStorage.getItem("anonymousUserID"));
      // If this clause is true, then user got loged out, so we dont have either user or anonymousID then we dont call fetchCart API
      if (user === "" && localStorage.getItem("anonymousUserID") === "") {

      } else {



        //  if(response && response.data && response.data.length>0){
        //     const lo = response.data.find(el => el.lat && el.lng);
        //     console.log('lo', lo);
        //     if(lo){
        //       let object={latitude:lo.lat,longitude:lo.lng, city:{key:lo.name,label:lo.name,  value:lo.name, name:lo.name}}
        //       // console.log(object, "object")
        //       dispatch(setLocation(object))
        //       setDropdownChange(object);
        //     }

        //   }
        // let response = await fetchCartCourses(
        //   user === null ? "" : user.id,
        //   localStorage.getItem("anonymousUserID")
        // );

        // Wishlist flow
        // if (user !== null && user.id !== null) {
        //   // let wishlistResponse = await fetchWishlistCourses(user.id);
        //   // console.log(wishlistResponse);
        //   // let wishlist = wishlistResponse.wishListItemList;
        //   // let wishlistObj = { wishlistArray: wishlist };
        //   // dispatch(addToWishlist(wishlistObj));
        // }

        // if (!response) {
        //   let defaultObj = {
        //     cartArray: [],
        //     tax: 0.0,
        //     totalAmount: 0.0,
        //     grandTotalAmount: 0.0,
        //     totalDiscount: 0.0,
        //   };
        //   dispatch(addToCart(defaultObj));
        // } else {
        //   // Cart flow
        //   let cart = response.cartItemList;
        //   let tax = response.tax;
        //   let totalAmount = response.totalAmount;
        //   let totalDiscount = response.totalDiscount;
        //   let grandTotalAmount = response.grandTotalAmount;
        //   let obj = {
        //     cartArray: cart,
        //     tax: tax,
        //     totalAmount: totalAmount,
        //     grandTotalAmount: grandTotalAmount,
        //     totalDiscount: totalDiscount,
        //     data: response,
        //   };
        //   if (obj.cartArray === undefined) {
        //     let defaultObj = {
        //       cartArray: [],
        //       tax: 0.0,
        //       totalAmount: 0.0,
        //       grandTotalAmount: 0.0,
        //       totalDiscount: 0.0,
        //     };
        //     dispatch(addToCart(defaultObj));
        //   } else {
        //     dispatch(addToCart(obj));
        //   }
        // }
      }
    }
    fetch();
  }, [user]);




  return (
    <ProfileIconsContainer>
      {/* {isLearner && disableIcon && (
        <div className="icon-notification" style={{ position: "relative" }}>
          <div
            className="heartIconDiv"
            onClick={!showWishlist ? handleShowWishlist : () => {}}
          >
            <NotificationBadge
              className="badge-notification"
              count={!wishlistArray ? 0 : wishlistArray.length}
              effect={Effect.SCALE}
            />
            <img src={HeartIcon} style={{ width: 20 }} />
          </div>
          {showWishlist && <CartDropdown name="wishlist" />}
        </div>
      )} */}

      {/* {isLearner && disableIcon && (
        <div className="icon-notification" style={{ position: "relative" }}>
          <div
            className="cartIcon"
            onClick={!showCart ? handleShowCart : () => {}}
          >
            <NotificationBadge
              className="badge-notification"
              count={!cartArray ? 0 : cartArray.length}
              effect={Effect.SCALE}
            />
            <img className="shoppingCart" style={{ width: 20 }} src={CartIcon} />
           /div>
          {showCart && <CartDropdown name="cart" />}
        </div>
      )} */}

      {/* <div className="icon-notification">
            <div className="bellIconDiv">
            <NotificationBadge className="badge-notification" count={counter} effect={Effect.SCALE}/>
            <img style={{ width:20 }} src={BellIcon} />
            </div>
           </div> */}
      <div style={{ position: 'relative' }}>
        <LinkButton to="/messages" className="messagechaticon">
          <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 25, height: 25 }} viewBox="0 0 512 512">
            <path d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z" />
          </svg>
          {unreadCount ? <Badge style={{ backgroundColor: '#ff4d50', display: 'flex', position: 'absolute', top: '-20px', right: '-5px' }} count={unreadCount} overflowCount={100}></Badge> : null}
        </LinkButton>
      </div>

      <div
        className="profile-img-accountBox"
        onClick={!props.showDropdown ? props.handleShowDropdown : () => { }}
      >
        <img
          className="img-profile"
          alt="profile"
          src={
            user
              ? user.profileImg
                ? user.profileImg
                : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
              : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
          }
        />
        {/* <div className="myAccount-box">
                  <div>{user?user.firstName:""} {user?user.lastName.slice(0,1) + ".":""}</div>
                  <FontAwesomeIcon icon={faChevronDown}/>
             </div> */}
      </div>
    </ProfileIconsContainer>
  );
};

export default ProfileIcons;
