import styled from 'styled-components';
import { mediaQueries, themeColors } from '../../theme';




export const PageContainer = styled.div`
`
export const HeaderSection = styled.div`
  height: 482px;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://mgtblog.com/wp-content/uploads/2021/07/Custom-dimensions-1200x500-px-60-1.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.about-mainImg{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://www.theladders.com/wp-content/uploads/teamwork_190415.jpg');
  }
  h2{
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: #E4E4E4;
  }
  h5{
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    margin:0px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #E4E4E4;
  }
  button{
    margin-top: 40px;
    background: #677eea;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #FFFFFF;
    border: 0;
    padding: 10px 30px;
    height: 48px;
  }
`;

export const PageSection = styled.div`
  padding: 60px 20px;
  text-align: center;
  display: flex;
  &.theme_bg{
    background: #f2f2f2;
  }
  .title{
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    width: 100%;
    margin-bottom: 60px;
    color: #030B0F;
  }
  .split-content{
    display: flex;
    flex-direction: column;
    padding: 20px 100px;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    text-align: left;
  }
  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */

    display: flex;
    align-items: center;
    letter-spacing: 0.02em;

    color: #343434;

  }
  .card_link{
    // max-width: 1200px;
    width: 100%;
    margin: auto;
  }
  .learnersLink-joinUsItem{
    background: #fff;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    height: 100%;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    text-align: left;
    h3{
      font-size: 24px;
      color: #000;
      margin:0;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    p{
      font-weight: 400;
      color: #000;
      font-size: 16px;
    }
  }
  .learnersLink-howItem{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin: 0;
    padding: 20px;
    h4{
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #4F4F4F; 
    }
  }

  button{
    margin-top: 18px;
    background: #677eea;
    border-radius: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #FFFFFF;
    border: 0;
    padding: 10px 30px;
    height: 48px;
  }
  .Collapsible{
    background: #fff;
    padding: 10px 20px;
    border-radius: 6px;
    margin-bottom: 16px;
    border: 1px solid #e0e0e0;
    border-left: 8px solid #667EEA;
    .Collapsible__trigger{
      text-align: left;
      font-weight: 600;
      p{
        font-weight: 600;
        strong{
          margin-right: 10px;
        }
      }
    }
    .Collapsible__contentOuter{
      text-align: left;
      p{
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        strong{
          margin-right: 10px;
        }
      }
    }
  }
  .leadership-card{
    border-radius: 4px;
    min-height: 272px;
    padding: 20px;
    margin: 10px;
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    .leadership-cardInfo{
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      margin-right: 20px;
      align-items: center;
      img{
        width: 110px;
        height: 110px;
        border-radius: 100%;
      }
      div:nth-child(2){
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        margin-top: 12px;
        margin-bottom: 6px;
        width: max-content;
      }
      div:last-child{
        font-style: italic;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #C1C1C1
      }
      
      
    }
    .leadership-cardText{
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      h2{
        text-align: left;
        margin: 0;
        color: #677eea;
      }
      h5{
        text-align: left;
        margin: 0;
        margin-bottom: 20px;
        color: #00000095; 
      }
      p{
        color: #000000;
        text-align: left;
        font-size: 16px;
      }
    }
  }
`