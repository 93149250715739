const ApiReviewList= async function(batchId, pageNum=0, pageSize=1000){
    let url = process.env.REACT_APP_API_URL + `/venue-reviews/getReviewByvenueId/${batchId}?pageNum=${pageNum}&pageSize=${pageSize}`

   
    const response = await fetch(url,{
      method:"GET",
      headers: { 'Content-Type': 'application/json'},
    })
    const data = await response.json()
    console.log(data)
    return data
}
export default ApiReviewList;
