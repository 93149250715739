import React from "react";
import socketio from "socket.io-client";
// import { SOCKET_URL } from "config";

// export const socket = socketio.connect("http://127.0.0.1:8085", { transports: ["websocket", "polling"], forceNew: true });
export const socket = socketio.connect("https://dev.simplitrain.in", { transports: ["websocket", "polling"], forceNew: true, secure: true });

// console.log("socketsocketsocketsocket", socket, socket.connected)
socket.on('disconnect', function () {
    console.log("disconnectdisconnectdisconnect", socket)
    socket.socket.reconnect();
})

export const SocketContext = React.createContext();