const CartTypes = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART:"REMOVE_FROM_CART",
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  CART_TO_WISHLIST: "CART_TO_WISHLIST",
  CART_TO_SAVE_LATER: "CART_TO_SAVE_LATER",
  GET_SAVE_LATER: "GET_SAVE_LATER",
  UPDATE_ARRAYS: "UPDATE_ARRAYS",
};

export default CartTypes;
