
export const baseColors = {
  failure: "#D23737",
  primary: "#667EEA",
  primaryBright: "#53DEE9",
  primaryDark: "#0098A1",
  secondary: "#F78B0C",
  tertiary: "#F78B0C",
  third: "#A771C5",
  success: "#2DB417",
  grad: "#A771C5",
  danger: "#EB5757",
  warning: "#FFB237",
  white: "#FFFFFF",
  black: "#000000",
};

export const additionalColors = {
  binance: "#F0B90B",
  overlay: "#000000",
  gold: "#FFC700",
  silver: "#B2B2B2",
  bronze: "#E7974D",
};

export const themeColors = {
  ...baseColors,
  ...additionalColors,
  headerBackground:"#FFFFFF",
  background: "#E9E7DF",
  overline: "#858380",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#FFFFFF",
  cardBorder: "#E7E3EB",
  contrast: "#191326",
  dropdown: "#F6F6F6",
  dropdownDeep: "#EEEEEE",
  menuBackgrpound: "#f4f3ef",
  menuActiveLink: "#eae7e0",
  invertedContrast: "#FFFFFF",
  input: "#3333330d",
  headerSearch: "#F6F6F6",
  tableRow:"#FFFFFF0d",
  inputSecondary: "#272727",
  text: "#333333",
  textDark: "#041016",
  textDisabled: "#b6b6b6",
  textSubtle: "#4F4F4F",
  disabled: "#E9EAEB",
  gradients: {
    themegradient: "linear-gradient(270.99deg, #F2960D -10.7%, #F2C00D 109.15%)",
    bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
    cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
    blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
    violet: "linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)",
    violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
    gold: "linear-gradient(270.99deg, #F2960D -10.7%, #F2C00D 109.15%)",
  },
};
