import React,{useState,useLayoutEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import fetchBookingbyId from './fetchBookingbyId'
import { Container } from 'react-bootstrap';
import QustionAnswer from './qustionanswer/qustionAnswer';
import { DetailPageHeader, LeftMenuContaier } from '../../../styles.jsx';
import { Button, Col, Menu, Row } from 'antd';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { VenueDashboardBookingCard, BatchContainer } from './venueBookingDetails.style.jsx';
import BookingDetails from './details/details.jsx';
import VenueProviderReviews from './reviews/reviews.jsx';
import VenueProviderFinance from './finance/finance.jsx';
import moment from 'moment';


const VenueBookingDetails = props => {
    console.log("props", props)
  const [activeTab, setActiveTab] = useState(0)
  const handleActiveTab = value => setActiveTab(value)
  const [batchesDatails, setbatchesDetails] = useState({});
  const [loading, setLoading] = useState(true)

  useLayoutEffect(()=> {
    async function fetchData() {
     const response= await fetchBookingbyId(props.match.params.id);
     console.log("response", response)
    //  props.setActiveCourceTitle(response?.courseBatchCompleteDetails?.title);
    if(response && response.venueBookingDetails)
        await setbatchesDetails(response.venueBookingDetails)
     setLoading(false);
     setActiveTab(1);
    }
    fetchData();
  },[props.match.params.id])




  return(
        <VenueDashboardBookingCard>
            <DetailPageHeader className='page-section'>
                <Container>
                    <div className='header-pre'>
                    <Button className='back-button' type='link' icon={<FontAwesomeIcon icon={faChevronLeft} />}>
                        <Link to={`/venue-provider-dashboard/bookings`}>
                            All Bookings
                        </Link>
                    </Button>
                    </div>
                    <div className='header-top'>
                    <h3 className='title'>
                        <strong>{batchesDatails?.venueDetails?.venueName}</strong>
                        {/* <span style={{ background: '#e0e0e0', padding: '1px 8px', fontSize: 13, borderRadius: 5 }}>Batch</span> */}
                    </h3>
                    {/* <Button type='primary' className='edit_button'>
                        <Link
                        to={`/instructor/edit-batches/${batchesDatails?.courseBatchID}`}
                        >
                        Edit Details
                        </Link>
                    </Button> */}
                    </div>
                    {/* <p className='description' dangerouslySetInnerHTML={{ __html: batchesDatails?.description }}></p> */}
                </Container>
            </DetailPageHeader>
            <div className="instDashboard-batchCard page-section">
                <Container>

                
                <Row>
                    <Col md={5}>
                        <LeftMenuContaier>
                            <div className="left-container">
                                <Menu selectedKeys={[activeTab]} className='menu-group'>
                                    <Menu.Item onClick={()=>handleActiveTab(1)} className={activeTab == 1?'active':null} key={1}>Details</Menu.Item>
                                    <Menu.Item onClick={()=>handleActiveTab(2)} className={activeTab == 2?'active':null} key={2}>Receipt/Billing</Menu.Item>
                                    <Menu.Item onClick={()=>handleActiveTab(3)} className={activeTab == 3?'active':null} key={3}>Reviews</Menu.Item>
                                    <Menu.Item onClick={()=>handleActiveTab(4)} className={activeTab == 4?'active':null} key={4}>Q&A</Menu.Item>
                                    {/* <Menu.Item onClick={()=>handleActiveTab(5)} className={activeTab == 5?'active':null} key={5}>Cancellation Policy</Menu.Item> */}

                                </Menu>
                            </div>
                        </LeftMenuContaier>
                    </Col>
                    <Col md={19}>
                        <Container>
                            <BatchContainer>
                                {activeTab===1 && <BookingDetails handleActiveTab={handleActiveTab} batchDetails={{...batchesDatails}}/>}
                                {activeTab===2 && <VenueProviderFinance handleActiveTab={handleActiveTab} batchDetails={{...batchesDatails}}/>}
                                {activeTab===3 && <VenueProviderReviews handleActiveTab={handleActiveTab} batchDetails={{...batchesDatails}}/>}
                                {activeTab===4 && <QustionAnswer handleActiveTab={handleActiveTab} batchDetails={{...batchesDatails}}/>} 
                                {activeTab===5 &&

                                    <div className='header-top'>
                                    <h3 className='title'>
                                        <strong>{batchesDatails?.venueDetails?.cancellationPolicy}</strong>
                                        {/* <span style={{ background: '#e0e0e0', padding: '1px 8px', fontSize: 13, borderRadius: 5 }}>Batch</span> */}
                                    </h3>
                                    </div>
                                //  handleActiveTab={handleActiveTab} batchDetails={{...batchesDatails}}/>
                                 
                                 }
                            </BatchContainer>
                        </Container>
                
                    </Col>
                </Row>
                </Container>
            </div>
        </VenueDashboardBookingCard>
);}

export default VenueBookingDetails;
