const CourseSchedulerTypes = {
    NEXT_STEP: "NEXT_STEP",
    PREVIOUS_STEP: "PREVIOUS_STEP",
    REGISTRATION_STARTED: "REGISTRATION_STARTED",
    REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
    REGISTRATION_FAILED: "REGISTRATION_FAILED",
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    SET_INITIAL_STEP: "SET_INITIAL_STEP",
    SET_PENDING_APPROVAL: "SET_PENDING_APPROVAL"
}

export default CourseSchedulerTypes;