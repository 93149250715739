import styled from 'styled-components';

export const InstructorBatchDetails = styled.div`
.instructorBatch-details{
  padding: 0px;
  .tabTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom:15px;
  }
}
.detail_section{
  // .row{
  //   width: 100%;
  //   margin:0;
  // }
  padding:0px 15px;
  margin-bottom:15px;
  .basic-detials{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding:20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    .course-image{
      width: 140px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 20px;
      img{
        aspect-ratio: 16/9;
        width: 100%;
      }
    }
    .course-detail{
      width: 100%;
      display: flex;
      flex-direction: column;
      .enrollments{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin: 0;
        display: flex;
        align-items: center;
        color: #303030;
        b{
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }
      .price{
        font-weight: 700;
        font-size: 14px;
        margin-top: 20px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #EB5757;
      }
    }
    .status_action{
      width: 170px;
      .status{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin: 0px;
        display: flex;
        align-items: center;
        color: #303030;
        b{
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.04em;
        }
      }
    }
  }
  .coupon-detials{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding:10px;
    .ant-table-thead{
      .ant-table-cell{
        padding: 10px;
        background: transparent;
        white-space: nowrap;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #000000;
      }
    }
    .ant-table-tbody{
      .ant-table-cell{
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #303030;
      }
    }
  }
}

.schedule_section{
  padding:0px 15px;
  margin-bottom:15px;
  .title{
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #041016;
  }
  .batch-item{
    display: flex;
    align-items: center;
    svg{
      margin-right: 8px;
    }
  }
  .schedule_details{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding:20px;
    height:320px;
    overflow:auto;
    
  }
  .schedule_dates{
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding:10px;
    .ant-table-thead{
      .ant-table-cell{
        padding: 10px;
        background: transparent;
        white-space: nowrap;
        text-align: left;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #000000;
      }
    }
    .ant-table-tbody{
      .ant-table-cell{
        padding: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #303030;
        b{
          font-weight: 700;
        }
        span{
          background: #F5F5F5;
          border-radius: 4px;
          padding:5px;
          font-size: 12px;
        }
      }
    }
  }
}

// .instructorBatch-details > div:first-child{
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;
// }
// .instructorBatch-details > div:nth-child(2){
//   margin-bottom: 30px;
// }
// .instructorBatch-details > div:nth-child(3){
//   margin-bottom: 15px;
// }
// .instructorBatch-details-venue{
//   width: 100%;
//   min-height: 175px;
//   background: white;
//   padding: 12px 40px 12px 12px;
//   display: flex;
//   @media (max-width: 480px) {
//     flex-flow: column;
//     padding: 10px;
//   }
// }
// .instructorBatch-details-venue > img{
//   width: 293px;
//   height: 151px;
//   margin-right: 20px;
//   @media (max-width: 480px) {
//     width: 100%;
//     margin-right: 0;
//     margin-bottom: 10px;
//   }

// }
// .instructorBatch-details-venueInfo{
//   display: flex;
//   flex-direction: column;
//   font-size: 14px;
//   line-height: 17px;
// }
// .instructorBatch-details-venueInfo > div:first-child{
//   letter-spacing: 0.02em;
//   color: #303030;
//   margin-bottom: 18px;
// }
// .instructorBatch-details-venueInfo > div:nth-child(2){
//   color: #041016;
//   margin-bottom: 31px;
//   @media (max-width: 480px) {
//     margin-bottom: 15px;
//   }
// }
// .instructorBatch-details-venueInfo span{
//   margin-left: 10px;
//   font-weight: bold;
// }
// .instructorBatch-details-venueInfo > div:last-child{
//   color: #667EEA;
//   display: flex;
//   font-weight: 500;
// }
// .instructorBatch-details-venueInfo > div:last-child > div{
//   display: flex;
//   margin-right: 40px;
// }
// .instructorBatch-details-venueInfo svg{
//   margin-right: 12px;
//   color: #667EEA;
//   width: 18px;
//   height: 18px;
// }
// .instructorBatch-details-venueInfo svg:hover{
//   cursor: default;
//   color: #667EEA;
// }
// .instructorBatch-details-head{
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 19px;
//   letter-spacing: 0.02em;
//   color: #041016;
// }
// .instructorBatch-details-close{
//   color: #EB5757;
//   font-size: 17px;
// }
// .instructorBatch-details-close:hover{
//   cursor: pointer;
//   color: red;
// }
// .instructorBatch-details-close > svg{
//   font-size: 20px;
// }


`

