const VenueProviderOnBoardingTypes = {
    NEXT_STEP: "NEXT_STEP",
    PREVIOUS_STEP: "PREVIOUS_STEP",
    REGISTRATION_VENUE_PROVIDER_STARTED: "REGISTRATION_VENUE_PROVIDER_STARTED",
    REGISTRATION_VENUE_PROVIDER_SUCCESS: "REGISTRATION_VENUE_PROVIDER_SUCCESS",
    REGISTRATION_VENUE_PROVIDER_FAILED: "REGISTRATION_VENUE_PROVIDER_FAILED",
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    SET_INITIAL_STEP: "SET_INITIAL_STEP",
    SET_VENUE_PREOVIDER_PENDING_APPROVAL: "SET_VENUE_PREOVIDER_PENDING_APPROVAL"
}

export default VenueProviderOnBoardingTypes;