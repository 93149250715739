const MobileVerificationActionTypes = {
    MOBILE_OTP_SEND_START: 'MOBILE_OTP_SEND_START',
    MOBILE_OTP_SEND_RE_START: 'MOBILE_OTP_SEND_RE_START',
    MOBILE_OTP_SEND_SUCCESS: 'MOBILE_OTP_SEND_SUCCESS',
    MOBILE_OTP_SEND_FAILURE: 'MOBILE_OTP_SEND_FAILURE',
    MOBILE_VERIFY_OTP_START: 'MOBILE_VERIFY_OTP_START',
    MOBILE_VERIFY_OTP_SUCCESS: 'MOBILE_VERIFY_OTP_SUCCESS',
    MOBILE_VERIFY_OTP_FAILURE: 'MOBILE_VERIFY_OTP_FAILURE',
    MOBILE_NUMBER_RESET: 'MOBILE_NUMBER_RESET',
    VERIFY_OTP_START: 'VERIFY_OTP_START',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',


}

export default MobileVerificationActionTypes;
