const UserActionTypes = {
    FACEBOOK_SIGN_IN_START: 'FACEBOOK_SIGN_IN_START',
    FACEBOOK_SIGN_IN_SUCCESS: 'FACEBOOK_SIGN_IN_SUCCESS',
    FACEBOOK_SIGN_IN_FAILURE: 'FACEBOOK_SIGN_IN_FAILURE',
    GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
    GOOGLE_SIGN_IN_SUCCESS: 'GOOGLE_SIGN_IN_SUCCESS',
    GOOGLE_SIGN_IN_FAILURE: 'GOOGLE_SIGN_IN_FAILURE',
    LINKEDIN_SIGN_IN_START: 'LINKEDIN_SIGN_IN_START',
    LINKEDIN_SIGN_IN_SUCCESS: 'LINKEDIN_SIGN_IN_SUCCESS',
    LINKEDIN_SIGN_IN_FAILURE: 'LINKEDIN_SIGN_IN_FAILURE',
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    EMAIL_SIGN_IN_SUCCESS: 'EMAIL_SIGN_IN_SUCCESS',
    EMAIL_SIGN_IN_FAILURE: 'EMAIL_SIGN_IN_FAILURE',
    BECOME_INSTRUCTOR_START: 'BECOME_INSTRUCTOR_START',
    BECOME_INSTRUCTOR_SUCCESS: 'BECOME_INSTRUCTOR_SUCCESS',
    BECOME_INSTRUCTOR_FAILURE: 'BECOME_INSTRUCTOR_FAILURE',
    UPDATE_ONBOARDING_STATUS: 'UPDATE_ONBOARDING_STATUS',
    UPDATE_VENUE_PROVIDER_ONBOARDING_STATUS: 'UPDATE_VENUE_PROVIDER_ONBOARDING_STATUS',
    CHANGE_VIEW: 'CHANGE_VIEW',
    LOGOUT: 'LOGOUT',
    LOGGEDIN_COUNT: 'LOGGEDIN_COUNT',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    REMOVE_ERROR: 'REMOVE_ERROR',
    BECOME_VENUE_PROVIDER_START:'BECOME_VENUE_PROVIDER_START',
    BECOME_VENUE_PROVIDER_SUCCESS:'BECOME_VENUE_PROVIDER_SUCCESS',
    BECOME_VENUE_PROVIDER_FAILURE:'BECOME_VENUE_PROVIDER_FAILURE'
};

export default UserActionTypes;
