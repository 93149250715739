import React, { useState, useLayoutEffect } from 'react';
//import "./announcments.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { Button, Checkbox, Col, Collapse, Empty, Form, Input, message, Radio, Row } from 'antd';
import { AnnouncementStyleContainer } from '../announcments/announcments.style';
import fetchQustionAnswerByBatchesbyId from './fetchQustionAnswerByBatchesbyId';
import { store } from "react-notifications-component";
import { faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
const { TextArea } = Input;
const { Panel } = Collapse;


const QustionAnswer = props => {
  console.log("propspropsprops", props);
  const [form] = Form.useForm();
  const [open, setOpen] = useState([]);
  const [fields, setFields] = useState(
    // [
    //    {name:'scheduleDates', value:[]} , {name:'corporate_discounts', value:[]} 
    // ]
  );

  const [announcement, setAnnouncement] = useState([]);
  const [edit, setEdit] = useState({});
  const [showReply, setShowReply] = useState(true);

  const onChange = (value, e, index) => {
    // setMessage(e.target.value);
    announcement[index].answer = e.target.value;
    setAnnouncement([...announcement]);

    console.log('Change:', e.target.value);
  };

  const checked = (value, e, index) => {
    announcement[index].isPublic = e.target.checked;
    setAnnouncement([...announcement]);
  }


  async function fetchData() {
    const response = await fetchQustionAnswerByBatchesbyId(props.batchDetails.venueDetails.venueID, 0, 10);
    await setAnnouncement(response?.qna?.content);
  }
  useLayoutEffect(() => {
    fetchData();
  }, [props?.batchDetails?.venueDetails?.venueID]);


  const onFinish = async (object) => {
    console.log(object, "object", props)
    // let data = {
    //   id:edit && edit.id?edit.id:'',
    //   userId:props?.batchDetails?.instructor?.instructorPersonalDetails?.userID,
    //   courseBatchId:props?.batchDetails?.courseBatchID,
    //   title:object.title,
    //   description:object.description
    // }
    const response = await fetch(process.env.REACT_APP_API_URL + `/question-answers/${object.questionID}/answer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    });
    const res = await response.json();

    console.log("res", res)
    if (res.status == 400) {
      message.error({ content: `${res.message}.`, duration: 2 });


    } else {
      setEdit({});
      form.resetFields();
      fetchData();
      setShowReply(false);
      setOpen([]);
      message.success({ content: `${res.message}.`, duration: 2 });

    }
  }

  const editAnnouncemnet = async (object) => {
    setEdit(object);
    setFields(
      [
        { name: 'title', value: object?.title },
        { name: 'description', value: object?.description }
      ]
    )
  }

  const deleteAnnouncemnet = async (object) => {
    const response = await fetch(process.env.REACT_APP_API_URL + `/batch_announcements/${object.id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    });
    const result = await response.json();

    console.log("response", response, result)
  }


  const onChangePanle = async (key) => {
    console.log(key);
    if (key && announcement[key].isNew) {
      let data = announcement[key];
      const response = await fetch(process.env.REACT_APP_API_URL + `/venue/question-answers/${data.questionID}/answer`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      console.log(announcement[key]);
      announcement[key].isNew = false;
      await setAnnouncement([...announcement]);
    }
    setOpen(key);
  };

  return (
    <AnnouncementStyleContainer>
      <div className="instructorBatch-details">
        <div className="tabTitle"><div className="instructorBatch-details-head">Q&A</div></div>
        <div className="instructorBatch-announcmentsList">
          {announcement && announcement.length > 0 ? <Collapse expandIconPosition={'end'} activeKey={open} onChange={onChangePanle} accordion>
            {
              announcement.map((item, index) => (
                <Panel header={<>
                  <h3 style={{ wordBreak: 'break-all', margin: 0, fontSize: 16, fontWeight: '500' }}>{item?.question}</h3>
                </>}
                  // extra={
                  //   <Button type="primary" style={{background: '#677eea', color: '#fff', border:0 }} onClick={()=>setShowReply(true)}>New</Button>
                  // }
                  extra={
                    item.isNew ? <Button type="primary" style={{ background: '#d94177', color: '#fff', border: 0 }}>New</Button> : item.answer ? <Button type="primary" style={{ background: '#677eea', color: '#fff', border: 0 }}>Responded</Button> : null
                  }
                  key={index}>
                  <div key={index} className="instructorBatch-announcmentsItem">
                    {
                      // showReply?
                      <>
                        <h6><small>{item?.askedByUserName ? item?.askedByUserName : "Name not Found"}</small></h6>

                        <h6 style={{ display: 'flex', alignItems: 'center', gap: 5 }}><small style={{ display: 'flex', alignItems: 'center', gap: 5 }}>  Question Date: {item?.questionAskedAt ? moment(new Date(item?.questionAskedAt)).format("ddd, MMM Do YYYY") : null} </small> -  <small style={{ display: 'flex', alignItems: 'center', gap: 5 }}>Answer Date: {item?.answeredAt ? moment(new Date(item?.answeredAt)).format("ddd, MMM Do YYYY") : null}</small></h6>

                        <b>Answer :</b>
                        <TextArea
                          // showCount
                          // maxLength={100}
                          value={item?.answer}
                          style={{ height: 120 }}
                          onChange={(e) => onChange(item, e, index)}
                          placeholder="Answer..."
                        />
                        <Row style={{ padding: 10 }}>
                          <Col md={12}>
                            <span style={{ paddingRight: 15 }}>
                              {item?.helpfulVotes ? item?.helpfulVotes : 0} <FontAwesomeIcon color={item.helpfulVotes > 0 ? '#667EEA' : '#929292'} icon={faThumbsUp} />
                            </span>
                            <Checkbox checked={item?.isPublic} onChange={(e) => checked(item, e, index)}>Public</Checkbox>
                          </Col>
                          <Col md={12} style={{ textAlign: 'right' }}>
                            <Button type="primary" style={{ background: '#677eea', color: '#fff', border: 0 }} onClick={() => onFinish(item)}>Submit</Button>
                          </Col>
                        </Row>
                      </>
                      // :<Row style={{padding:10}}> 
                      //   <Col md={24} style={{textAlign: 'right'}}>
                      //       <Button type="primary" style={{background: '#677eea', color: '#fff', border:0 }} onClick={()=>setShowReply(true)}>Reply</Button>
                      //   </Col>
                      // </Row>
                    }
                  </div>
                </Panel>
              ))
            }
          </Collapse>
            : <div className="empty_data">  <Empty /></div>
          }


        </div>
      </div>
    </AnnouncementStyleContainer>
  )
}

export default QustionAnswer;
