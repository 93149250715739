import React, {useState, useEffect} from "react";
//import "./details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRupeeSign, faTimes, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
// import CalendarComponent from "../../detailedCourses/calendar/calendar.jsx";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { Descriptions, Table } from "antd";
import defaultImage from '../../../../assets/business.jpg';
import { EmbedMap } from "../../../eBooking/schedule/embedmap/embedmap.jsx";
import { InstructorBatchDetails } from "./details.style.jsx";
import { themeColors } from "../../../../theme";
import { Link } from "react-router-dom";

const BookingDetails = (props) => {
  // We must convert Date to format our calendar component accepts, which is like this (year, month, day) Where month goes (0-January to 11- December)
console.log("props.batchDetails", props.batchDetails);
const { batchDetails } = props;
const [couponsCodesColumn, setCouponsCodesColumn] = useState([]);
const [everydayColumns, setEverydayColumns] = useState([]);

  // const firstDay = moment(
  //   props.batchDetails.startDate.includes("[Etc/UTC]")
  //     ? props.batchDetails.startDate.replace("[Etc/UTC]", "")
  //     : props.batchDetails.startDate
  // ).format("YYYY,M,D");

  // const lastDay = moment(
  //   props.batchDetails.endDate.includes("[Etc/UTC]")
  //     ? props.batchDetails.endDate.replace("[Etc/UTC]", "")
  //     : props.batchDetails.endDate
  // ).format("YYYY,M,D");
    
  const getCourseBatchStatus = (courseBatchStatus) => {
    switch (courseBatchStatus) {
      case "PENDING_APPROVAL":
        return <span style={{color:'#c79603'}}>Pending Approval</span>;

      case "APPROVED":
        return <span style={{color:'#28a745'}}>Approved</span>;

      case "DRAFT":
        return <span style={{color:'#17a2b8'}}>Draft</span>;

      case "SENT_BACK_FOR_CORRECTION":
        return <span style={{color:'#007bff'}}>Sent back for correction</span>;

      case "REJECTED":
        return <span style={{color:'#dc3545'}}>Rejected</span>;

      default:
        return "Unknown status";
    }
  };

  const getVisibilityStatus = (visibilityStatus) => {
    switch (visibilityStatus) {
      case "LIVE":
        return <span style={{color:'#28a745'}}>Live</span>;

      case "NOT_LIVE":
        return <span style={{color:'#17a2b8'}}>Not Live</span>;

      default:
        return <span style={{color:'#17a2b8'}}>Not Live</span>;
    }
  };

    useEffect(()=>{
      setCouponsCodesColumn([{
        title: 'Coupon Code',
        dataIndex: 'couponCode',
        key: 'couponCode',
      },
      {
        title: 'Discount',
        dataIndex: 'discountPrice',
        key: 'discountPrice',
      },
      {
        title: 'Validity',
        dataIndex: 'validTo',
        key: 'validTo',
        render: (text) => `Till ${moment(text).format('D MMM, YYYY')}`
      }]);
      setEverydayColumns([
        {
          title: 'Day',
          dataIndex: 'day',
          key: 'day',
          render: (text, record, index) => <b>Day {index+1}</b>
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          render: (text) => <span>{`${moment(text).format('Do MMM, dddd')}`}</span>
        },
        {
          title: 'From',
          dataIndex: 'startTime',
          key: 'startTime',
          render: (text) => <span>{`${moment(text, "HH:mm").format('hh:mm A')}`}</span>
        },
        {
          title: 'To',
          dataIndex: 'endTime',
          key: 'endTime',
          render: (text) => <span>{`${moment(text, "HH:mm").format('hh:mm A')}`}</span>
        }
      ]);
    }, []);
  

    const getStatus = (status) => {
      if(status == 'PENDING_APPROVAL'){
        return <span style={{color:themeColors.success}}>Pending Approval</span>;
      }else if(status == 'APPROVED'){
        return <span style={{color:themeColors.primary}}>Approved</span>;
      }else if(status == 'cancel'){
        return <span style={{color:themeColors.danger}}>Cancel</span>;
      }else{
        return <span style={{color:themeColors.success}}>Pending Approval</span>;
      }
    }
  

  return (
    <InstructorBatchDetails>
      <div className="instructorBatch-details">
        {/* <div className="detail_section">
          <Row>
            <Col md={7}>
              <div className="basic-detials">
                <div className="course-image">
                    <img src={batchDetails?.venueDetails?.photoGallery && batchDetails?.venueDetails?.photoGallery.length>0? batchDetails?.venueDetails?.photoGallery[0]?.venuePhotoId?.fileURL: defaultImage} />
                </div>
                <div className="course-detail">
                    <p className="enrollments">Enrollments:</p>
                    <p className="enrollments"><b>{batchDetails?.enrollmentCount} Learners</b></p>
                    <br />
                    
                    <p className="enrollments">Schedule:</p>
                    <p className="enrollments">
                    <b><svg style={{marginRight:6}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6667 2.67188H3.33333C2.59695 2.67188 2 3.26883 2 4.00521V13.3385C2 14.0749 2.59695 14.6719 3.33333 14.6719H12.6667C13.403 14.6719 14 14.0749 14 13.3385V4.00521C14 3.26883 13.403 2.67188 12.6667 2.67188Z" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10.668 1.32812V3.99479" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5.33203 1.32812V3.99479" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M2 6.67188H14" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      {batchDetails?.schedule?.briefCourseSchedule}</b>
                   </p>
                   <br />
                    {
                      batchDetails?.discountedPrice == 0 || batchDetails?.actualPrice == 0?
                      <span className="price">
                        {'FREE'}
                      </span>
                      :batchDetails?.actualPrice == batchDetails?.discountedPrice?
                        <span className="price">
                          <FontAwesomeIcon icon={faRupeeSign} />
                          {batchDetails?.actualPrice}
                        </span>
                      :<p className="price">
                        <span style={{ fontSize: 20}}> 
                          <FontAwesomeIcon icon={faRupeeSign} />
                          {batchDetails?.discountedPrice}
                        </span>
                        <span
                          style={{
                            textDecoration: "line-through",
                            fontSize: "14px",
                            color: "#909090",
                            marginLeft: 10,
                            fontWeight: "400",
                          }}
                        >
                         <FontAwesomeIcon icon={faRupeeSign} style={{ width: 8 }} />
                          {batchDetails?.venuePrice}
                        </span>

                        <p className="enrollments">Advance Amount:</p>
                        <span
                          style={{
                            textDecoration: "line-through",
                            fontSize: "14px",
                            color: "#909090",
                            marginLeft: 10,
                            fontWeight: "400",
                          }}
                        >
                         <FontAwesomeIcon icon={faRupeeSign} style={{ width: 8 }} />
                          {batchDetails?.venueAdvancePrice}
                        </span>
                      </p>
                    }
                </div>
                <div className="status_action">
                  <p className="status">Status:</p>
                  <p className="status"><b>{getCourseBatchStatus(batchDetails?.courseBatchStatus)}</b></p>
                  <br />
                  <p className="status">Visibility:</p>
                  <p className="status"><b>{getVisibilityStatus(batchDetails?.visibilityStatus)}</b></p>
                  <br />
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="coupon-detials">
                <Table dataSource={batchDetails?.coupons} pagination={false} rowKey={(record)=> record.id} columns={couponsCodesColumn} />
              </div>
            </Col>
          </Row>
        </div> */}
        <div className="schedule_section">
         
          {/* <div className="batch-item">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6667 2.67188H3.33333C2.59695 2.67188 2 3.26883 2 4.00521V13.3385C2 14.0749 2.59695 14.6719 3.33333 14.6719H12.6667C13.403 14.6719 14 14.0749 14 13.3385V4.00521C14 3.26883 13.403 2.67188 12.6667 2.67188Z" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10.668 1.32812V3.99479" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.33203 1.32812V3.99479" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2 6.67188H14" stroke="#667EEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {batchDetails?.schedule?.briefCourseSchedule}
          </div>
          <br /> */}

<h4 className="title" style={{margin:0}}> Details  </h4>
      <Row style={{padding: '16px 0px'}}>
        
        <Col xs={12} sm={12} md={12} lg={8}>
       
        <div className="batch-info">
            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Instructor Name:</div>
                <div className="value"><Link to={`/instructor-profile/${batchDetails?.instructorProfile?.instructorID}`}>{batchDetails?.instructorProfile?.firstName} {batchDetails?.instructorProfile?.lastName}</Link> </div>
            </div>

            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Course Name:</div>
                <div className="value"><Link to={`/course-details/${batchDetails?.courseBatchCompleteDetails?.courseBatchID}`}>{batchDetails?.courseBatchCompleteDetails?.courseInfo?.title}</Link> </div>
            </div>


           
           
            <div className="rupee-sign" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                Advance Payment: <FontAwesomeIcon icon="rupee-sign" /> {batchDetails?.venueAdvancePrice}
            </div>
            <div className="rupee-sign" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                Total Payment: <FontAwesomeIcon icon="rupee-sign" /> {batchDetails?.venuePrice}
            </div>
            </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} style={{position:"relative"}}>
        <div className="action-part">
            <div style={{width:"100%"}}>
            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Booking Id:</div>
                <div className="value">{batchDetails?.refNumber}</div>
            </div>
            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Booked On:</div>
                <div className="value"> <p>{ batchDetails?.createdAt?moment(new Date(batchDetails?.createdAt)).format('Do MMM YYYY, hh:mm A'):null }</p></div>
            </div>

            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Course Date:</div>
                <div className="value">{ batchDetails?.bookingFrom?moment(new Date(batchDetails?.bookingFrom)).format('Do MMM YYYY'):null } - { batchDetails?.bookingTo?moment(new Date(batchDetails?.bookingTo)).format('Do MMM YYYY'):null } </div>
            </div>

      
            <div className="batch-right-item" style={{display: 'flex',gap: '20px', marginBottom:5}}>
                <div className="lable">Status:</div>
                <div className="value">{getStatus(batchDetails?.venueBookingStatus)}</div>
            </div>
        </div>
        </div>
        </Col>
    </Row>


          <Row>
            <Col md={4}>
            <h4 className="title"> Venue Details  </h4>
            <div className="schedule_details">
              {
                <div className="instructorBatch-details-venue">
                  {/* <img src={batchDetails.venueImg} /> */}
                  <div className="instructorBatch-details-venueInfo">
                    <div>{batchDetails.venueLocation}</div>
                    <div>
                      Landmark: <span>{batchDetails?.venueDetails?.venueLocation?.addressLine} {batchDetails?.venueDetails?.venueLocation?.city} {batchDetails?.venueDetails?.venueLocation?.state} {batchDetails?.venueDetails?.venueLocation?.country}</span>
                    </div>
                    <br />
                    <div>
                    <EmbedMap
                      mapURL={
                        "https://www.google.com/maps/embed/v1/place?q=" +
                        batchDetails?.venueDetails?.venueLocation?.latitude +
                        "," +
                        batchDetails?.venueDetails?.venueLocation?.longitude +
                        "&key=" +
                        process.env.REACT_APP_GOOGLE_EMBED_MAP_KEY +
                        "&zoom=17&maptype=roadmap"
                      }
                      
                    />
                    
                      <div>
                        <FontAwesomeIcon
                          className="instructorBatch-details-close"
                          icon="map-marker-alt"
                        />
                        <div>
                              <a
                                href={
                                  "https://www.google.com/maps/search/?api=1&query=" +
                                  batchDetails?.venueDetails?.venueLocation?.latitude +
                                  "%2c" +
                                  batchDetails?.venueDetails?.venueLocation?.longitude
                                }
                                target={'_blank'}
                              >
                                <FontAwesomeIcon icon={faMapMarkedAlt} /> Open in Google Maps
                              </a>
                        </div>
                      </div>
                      <br />
                      <div>
                          <FontAwesomeIcon className="instructorBatch-details-close" icon={['fab','whatsapp']}/>
                          <div> <a href={"whatsapp://send?text=https://maps.google.com/?q="+ batchDetails?.venueDetails?.venueLocation?.latitude +","+ batchDetails?.venueDetails?.venueLocation?.longitude+"%0a%0a Address : "+ batchDetails?.venueDetails?.venueLocation?.addressLine+", "+batchDetails?.venueDetails?.venueLocation?.city+", "+batchDetails?.venueDetails?.venueLocation?.state+", "+batchDetails?.venueDetails?.venueLocation?.country} data-action="share/whatsapp/share" target={'_blank'}>WhatsApp me the location</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            </Col>
            <Col md={8}>
              <h4 className="title">Detailed Schedule</h4>
              <div className="schedule_dates">
                <Table dataSource={batchDetails?.venueBookingDates} pagination={false} scroll={{ y: 260 }}  rowKey={(record, index)=> index} columns={everydayColumns} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </InstructorBatchDetails>
  );
};

export default BookingDetails;
