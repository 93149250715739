import React, {useState, useLayoutEffect} from 'react';
//import "./finance.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FinanceStyle } from '../finance/finance.style'
import fetchLearnerBatchesbyId from '../fetchLearnerBatchesbyId';
import { Button, Col, Divider, Row, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Vdetail } from '../../../../styles';
const VenueProviderFinance = props => {

  const [learners, setLearners] = useState([]);
  const [booking, setBooking] = useState({});
  const [learnersColumns, setLearnersColumns] = useState([]);
  useLayoutEffect(()=> {
    async function fetchData() {
     const response= await fetchLearnerBatchesbyId(props.batchDetails.id);
     console.log(response, "response")
     setLearnersColumns([
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Coupon',
        dataIndex: 'coupon',
        key: 'coupon',
        render: (text, record) => <span style={{color: '#EB5757'}}>{text}</span>
      },
      {
        title: 'Discount',
        dataIndex: 'discountAmount',
        key: 'discountAmount',
        render: (text, record) => <span style={{color: '#2DB417'}}>{text}</span>
      },
      {
        title: 'Amount Paid',
        dataIndex: 'amountPaid',
        key: 'price',
        render: (text, record) => <span style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', background: 'transparent' }}>{Number(record.qty)*Number(text)}</span>
      }
    ]);
    await setBooking(response?.venueBookingFinances);
     await setLearners(response?.venueBookingFinances?.venueBookingCustomAmenityList);
    }
    fetchData();
  },[props?.batchDetails]);

  const getSubTotal = (data) =>{
    let amount = 0;
    if(data && data.length>0){
      data.map(el=>{
        if(el && el.amountPaid){
          amount = amount+ Number(el.amountPaid);
        }
      })
    }
   
    return Number(amount).toFixed(2);
  }

  const getProtalSubTotal = (value) =>{
   return Number((value/100)*10).toFixed(2);
  }



  return(
    <Vdetail>
      <Row>
        <Col span={24} className='payment-detail'>
                        
          <div>
              <div>
                  <Title level={4}>Receipt/Billing</Title>
              </div>
              <div style={{padding:'25px 0px'}}>
                  <div className='Payment'>
                      <span>1 {booking?.priceType?booking?.priceType: ''}</span>
                      <span>{booking?.currencyType === "INR"?'₹':'$'} {Number(booking?.priceDay).toFixed(2)}</span>
                  </div>
                  <div className='Payment'>
                      <span>{booking && booking.days} Days</span>
                      <span>₹ {booking?.totalDays}</span>
                  </div>
                  {learners.map((item, index)=>(
                      <div key={index} className='Payment'>
                          <span>{item?.qty?Number(item?.qty):0} X {item?.name}</span>
                          <span>₹ {Number(item?.qty)*Number(item?.price).toFixed(2)}</span>
                      </div>
                  ))}

                  {booking && booking.couponCode && 
                      <div className='Payment' style={{color:'#EB5757'}}>
                          <span>Coupon Code : {booking?.couponCode}</span>
                          <span>₹ {Number(booking?.couponAmount).toFixed(2)}</span>
                      </div>
                  }

                  <div className='Payment'>
                      <span>GST - 18%</span>
                      <span>₹ {Number(booking?.taxs).toFixed(2)}</span>
                  </div>
                  
                  <Divider style={{margin:'8px 0px'}} />
                  <div className='Payment' style={{color:'#2DB417'}}>
                      <span>Total Amount</span>
                      <span>₹ {Number(booking?.venuePrice).toFixed(2)}</span>
                  </div>
                  <Divider style={{margin:'8px 0px'}} />
                  <div className='Payment' style={{color:'#EB5757'}}>
                      <span>Advance Amount</span>
                      <span>₹ {Number(booking?.venueAdvancePrice).toFixed(2)}</span>
                  </div>

                  <div className='Payment' style={{color:'#EB5757'}}>
                  <span>SimpliTrain Platform Payout</span>
                    <span><FontAwesomeIcon icon="rupee-sign"/> {getProtalSubTotal(booking?.venuePrice)}</span>
                </div>

                {/* <div className='Payment' style={{color:'#2DB417'}}>
                  <span>Total Payment Due</span>
                  <span><FontAwesomeIcon icon="rupee-sign"/> {booking?.venuePrice-getProtalSubTotal(booking?.venuePrice)}</span>
                </div> */}
              </div>
          </div>
        </Col>  
      </Row>
    </Vdetail>
  )
};

export default VenueProviderFinance;
