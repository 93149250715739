import styled from 'styled-components';
import { themeColors, mediaQueries, fonts } from '../../../theme';

export const HeaderDropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 225px;
  position: absolute;
  right: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  background-color: #fff;
  border: 0;
  height: 1px;
  opacity: 0;
  transition: all .4s linear;
  margin-top: 5px;
  padding: 20px;
  z-index: 1000;
  ${mediaQueries.md}{
    right: 40px;
  }
  >svg{
    position: absolute;
    top: -18px;
    color: #ffffff;
    right: 18px;
  }
  &.show-anim {
    opacity: 1;
    height: auto;
  }
  &.hide-anim{
    display: none;
  }
  .profileDropDown-user{
    display: flex;
    justify-content: center;
    align-items: center;
    .img-profile{
      width: 45px;
      height: 45px;
      border-radius:100%;
    }
    .profileDropDown-userInfo{
      margin-left: 10px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: #303030;
      h4{
        font-weight: 600;
        margin: 0;
        font-size: 16px;
      }
      p{
        font-weight: 300;
      }
    } 
  }
  hr{
    width: 100%;
    margin: 0;
    margin-top: 16px;
  }
  .link-grey{
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #000000;
    display: inline-block;
    padding: 0;
    margin-top: 8px;
    padding: 8px 0px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .link-red{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #EB5757;
    margin-top: 8px;
  }
  .link-box{
    padding: 10px;
    border: 1px solid #667EEA;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    color: #667EEA;
    text-align: center;
    font-weight: 600;
    margin: 20px auto 0 auto;
    font-size: 14px;
  }
`;
