import React from "react";
const EmbedMap = (props) => {
  return (
    <div className="google-map-code">
      <iframe
        title="Google map"
        src={props.mapURL}
        width="100%"
        height="150"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};
export { EmbedMap };
