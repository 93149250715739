import styled from 'styled-components';

export const VenueProviderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom:80px;
  .venueProvider-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
`
export const CoursesContainer = styled.div`
width: 100%;
.instCourseDetails-content{
  background: #f5f5f5;
  width: 100%;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
}
.table-view{
  padding-bottom:30px;
  width:100%;
}
.summaryTitle{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-bottom:14px;
  line-height: 19px;
  letter-spacing: 0.02em;

  color: #041016;
}
.instCourseDetails-contentWraper{
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  padding: 20px;
  width: 100%;
  margin: 10px 0px;
 
  .ant-collapse-content-box{
    .ant-collapse-header{

    }
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li{
      padding-left: 1em; 
      text-indent: -.7em;
    }
    li::before {
      content: "•";
      margin-right: 6px;
      color: #EDEDED;

    }
  }
}
.header{
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #041016;
  margin: 0 10px;
  height: 25px;
  display: flex;
  position:relative;
  border-bottom: 1px solid #EDEDED;
  align-items: center;
  ::before{
    content:"";
    background:#667EEA;
    position: absolute;
    left: -15px;
    width:2px;
    height:100%;
  }
}
.descriptions{
  border: none!important;
  margin: 4px 0 !important;
}
.descriptions > div:last-child{
  flex-direction:column;
}
.instCourseDetails-img img{
  height: 138px;
  width: 210px;
  padding-left:10px;
}
.instCourseDetails-img iframe{
  height: 138px;
  width: 210px;
  padding-left:10px;
}
.instCourseDetails-creds{
  display: flex;
  padding-top: 20px;
}
.instCourseDetails-credsPart{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: black;
}
.devider{
  border-left: 1px solid #EDEDED;
}
.instCourseDetails-credsItem{
  display: flex;
  padding: 10px;
}

.instCourseDetails-credsItem > div:first-child{
  width: 50% !important;
  color: #979797;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
}
.instCourseDetails-credsItem > div:last-child{
  width: 50%;
  padding-left: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #041016;
}
.instCourseDetails-credsItem span{
  color: #979797;
  margin-right: 0px;
  margin-left: auto;
}
.instCourseDetails-credsItem ol, .instCourseDetails-credsItem ul{
 margin:0;
 padding:0;
 padding-left:15px;
 font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #041016;
}
.instCourseDetails-imageVideo{
  display: flex;
}
.instCourseDetails-imageVideo > div:first-child{
  margin-right: 10px;
}
.instCourseDetails-imageVideo > div:last-child{
  margin-left: 10px;
}
.instCourseDetails-list{
  margin-bottom: 10px;
}
.instCourseDetails-list > ul{
  padding-left: 0;
  color: #041016;
  list-style: none;
}
.instCourseDetails-list > ul > li{
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}
.instCourseDetails-list > ul > li:nth-child(2){
  margin-top: 12px !important;
  display: flex;
}
/* This is only for target audience */
.instCourseDetails-list > div:last-child{
  margin-top: 12px;
  display: flex;
}
.instCourseDetails-list > div:last-child > p{
  margin-right: 5px;
}
.instCourseDetails-list span{
  font-weight: bold;
  font-size: 14px;
}
/* Margin left 0 !important, because only here it has grey rectangle box that goes litle more on left side*/
.instCourseDetails-curriculumWrap{
  padding-top: 20px;
  margin-left: 0 !important;
}
.instCourseDetails-curriculumWrap > div:first-child > .instCourseDetails-curriculumItem:last-child{
  margin-bottom: 0;
}
.instCourseDetails-curriculumItem{
  border: 1px solid #EDEDED;
  border-radius: 2px;
  padding: 10px 0 10px 10px;
  margin-bottom: 15px;
}
.instCourseDetails-curriculumPart{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
}
.instCourseDetails-curriculumPart > div:first-child{
  font-weight: bold;
  color: #041016;
  margin-bottom: 12px;
}
.instCourseDetails-curriculumTopic{
  display: flex;
  flex-wrap: wrap;
}
.instCourseDetails-curriculumTopic > p{
  margin-right: 5px;
  margin-bottom: 5px;
}
.instCourseDetails-msgs{
  height: auto;
}
.instCourseDetails-gif{
  position: absolute;
  top: 30%;
  left: 48%;
}
.instCourseDetails-listWrap{
  padding:10px 0px;
  &.curriculum-collapsible{
    .ant-collapse{
      background: transparent;
      .ant-collapse-item{
        margin-bottom:10px;
      }
    }
  }
  .ant-collapse{
    border:0;
    .ant-collapse-item{
      border: 0;
      .ant-collapse-header{
        background: #fff;
        border-bottom: 1px solid #F7F7F7;
      }
      .ant-collapse-content{
        border:0px;
      }
    }
  }
}
`