import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link, NavLink } from "react-router-dom";

import { CourseBatchCard } from "../bookingContainer.style";
import { faBorderNone } from "@fortawesome/free-solid-svg-icons";
import { BorderInnerOutlined, BorderLeft } from "@material-ui/icons";
import { Col, Row } from "react-bootstrap";
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popover } from "antd";
import { themeColors } from "../../../../theme";
import moment from "moment";
import Axios from "axios";
import { useSelector } from "react-redux";


const VenueBooking = (props) => {

  console.log("props", props, props.booking)
  const history = useHistory();

  const user = useSelector((state) => state.user.currentUser);
  // const createdTime =  ()=>{
  //   if(props && props.created){
  //     if(props.created.toString().includes("[Etc/UTC]")){
  //       return moment(props.created.replace("[Etc/UTC]", "")).format("Do MMM YYYY, h:mm a");
  //     }else{
  //       return moment(props?.created).format("Do MMM YYYY, h:mm a");
  //     }
  //   }
  // }
  // const modifiedTime =  ()=>{
  //   if(props && props.lastModified){
  //     if(props.lastModified.toString().includes("[Etc/UTC]")){
  //       return moment(props.lastModified.replace("[Etc/UTC]", "")).format("Do MMM YYYY, h:mm a");
  //     }else{
  //       return moment(props?.lastModified).format("Do MMM YYYY, h:mm a");
  //     }
  //   }
  // }

  const workFlowVenueBooking = (status, id) =>{
    let url = process.env.REACT_APP_API_URL+`/venue-booking/${id}/workflow?userID=${user?.id}&venueBookingStatus=${status}`;
    Axios.get(url).then((response) => {
      const data = response.data;
      // if (data.actionResult === "SUCCESS") {
      //   fetchAllAmenitiesbyVenueId(form.getFieldValue('venueID'));
      //   amentiesForm.resetFields();
      // }
    });
    // let response = await DataService.post(`course-batches/${id}/workflow?userID=${userId}&workflowAction=${action}`, data);
    // console.log("response", response)
    // if(response.data && response.data.actionResult && response.data.actionResult =="SUCCESS"){
    //     message.success({ content: response.data.message, duration: 2 });
    //   return dispatch(addWorkflowActionBatchesSuccess(response.data.actionResult));
    // }else{
    //   {response.data.message?
    //     message.error({ content:  response.data.message, duration: 2 })
    //   :message.error({ content:  'Something went wrong!', duration: 2 })}
    //   return dispatch(addWorkflowActionBatchesErr(response.data.message));
    // }

  }
  const getStatus = (status) => {
    if(status == 'PENDING_APPROVAL'){
      return <span style={{color:themeColors.success}}>Pending Approval</span>;
    }else if(status == 'APPROVED'){
      return <span style={{color:themeColors.primary}}>Approved</span>;
    }else if(status == 'cancel'){
      return <span style={{color:themeColors.danger}}>Cancel</span>;
    }else{
      return <span style={{color:themeColors.success}}>Pending Approval</span>;
    }
  }


  return (
    <CourseBatchCard>
      <div className="instDashboard-batch">
        <Row>
            <Col xs={12} sm={12} md={12} lg={8}>
              <div className="batch-info">
                  <Link
                    to={`/venue-provider-dashboard/bookings/${props?.booking?.id}`}
                    value={props?.booking?.venueDetails?.venueName}
                  >
                  <div  className="heading">{props?.booking?.venueDetails?.venueName}</div>
                  </Link>
                  <div className="batch-item">
                    <p style={{margin: 0, marginRight: 10 }}>
                    <Link to={`/instructor-profile/${props?.booking?.instructorProfile?.instructorID}`}>{props?.booking?.instructorProfile?.firstName} {props?.booking?.instructorProfile?.lastName}</Link>, <Link to={`/course-details/${props?.booking?.courseBatchCompleteDetails?.courseBatchID}`}>{props?.booking?.courseBatchCompleteDetails?.courseInfo?.title}</Link> 
                    </p>
                  </div>
                  {/* <div  className="batch-item">
                    <div>Created: {" " + createdTime()}</div>
                    <div>Last Modified: {" " + modifiedTime()}</div>
                  </div> */}
                  <div  className="batch-item">
                    <p style={{margin: 0, marginRight: 10 }}><FontAwesomeIcon icon="fa-regular fa-calendar" color="#EB5757" /> { props?.booking?.bookingFrom?moment(new Date(props?.booking?.bookingFrom)).format('Do MMM YYYY'):null } - { props?.booking?.bookingTo?moment(new Date(props?.booking?.bookingTo)).format('Do MMM YYYY'):null }</p>
                    <div className="simulate-link">
                    <Popover placement="bottomRight" content={
                      <div className="calendar-details" style={{ width: '500px', maxHeight: '250px', overflow: 'auto' }}>
                        <div className="days" style={{padding: '10px 0px', borderBottom: '1px solid #e0e0e0', fontSize: '14px', fontWeight: 600 }}>Bookings Dates & Timings</div>
                        <div style={{display: 'flex', flexDirection: 'column' }}>
                          {
                            props?.booking?.venueBookingDates.length>0?
                              props?.booking?.venueBookingDates.map(date=>(
                                <div className="day" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px 0px' }} key={date.id}>
                                    <div style={{width:'50%', padding:'0px 5px', boxSizing: 'border-box'}}><span><FontAwesomeIcon icon="fa-regular fa-calendar" color="#EB5757" /> &nbsp; &nbsp;<b>{date.date?moment(new Date(date.date)).format("ddd, MMM Do YYYY"):null}</b></span></div>
                                    <div style={{width:'50%', padding:'0px 5px', boxSizing: 'border-box'}}><b><FontAwesomeIcon icon="fa-regular fa-clock" color="#EB5757"/> </b> &nbsp; &nbsp;<span>{date.startTime?moment(date.startTime, "HH:mm").format("hh:mm A"):null} - {date.endTime?moment(date.endTime, "HH:mm").format("hh:mm A"):null}</span></div>
                                    {/* <div style={{width:'30%', padding:'0px 5px', boxSizing: 'border-box'}}>From <span>{day.from}</span></div>
                                    <div style={{width:'30%', padding:'0px 5px', boxSizing: 'border-box'}}>To <span>{day.to}</span></div> */}
                                </div>
                              ))
                            :null
                          }
                        </div>
                      </div>
                    } trigger="click">
                      <Button type="text" style={{color:themeColors.primary, padding: 0, height: '16px', lineHeight: '16px' }}>More</Button>
                    </Popover>
                  </div>
                  </div>
                  <div className="rupee-sign">
                    Advance Payment: <FontAwesomeIcon icon="rupee-sign" /> {props?.booking?.venueAdvancePrice}
                  </div>
                  <div className="rupee-sign">
                    Total Payment: <FontAwesomeIcon icon="rupee-sign" /> {props?.booking?.venuePrice}
                  </div>
                </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} style={{position:"relative"}}>
              <div className="action-part">
                <div style={{width:"100%"}}>
                  <div className="batch-right-item">
                    <div className="lable">Booking Id:</div>
                    <div className="value">{props?.booking?.refNumber}</div>
                  </div>
                  <div className="batch-right-item">
                    <div className="lable">Booked On:</div>
                    <div className="value"> <p>{ props?.booking?.createdAt?moment(new Date(props?.booking?.createdAt)).format('Do MMM YYYY, hh:mm A'):null }</p></div>
                  </div>
                  {/* <div className="batch-right-item">
                    <div className="lable">Course Start:</div>
                    <div className="value">{ props?.booking?.bookingFrom?moment(new Date(props?.booking?.bookingFrom)).format('Do MMM YYYY, hh:mm A'):null }</div>
                  </div>
                  <div className="batch-right-item">
                    <div className="lable">Course End:</div>
                    <div className="value">{ props?.booking?.bookingTo?moment(new Date(props?.booking?.bookingTo)).format('Do MMM YYYY, hh:mm A'):null }</div>
                  </div> */}
                  <div className="batch-right-item">
                    <div className="lable">Status:</div>
                    <div className="value">{getStatus(props?.booking?.venueBookingStatus)}</div>
                  </div>
                </div>
                  <Dropdown overlay={<Menu>
                    <Menu.Item onClick={()=>workFlowVenueBooking('REJECTED', props?.booking?.id)}>Cancel</Menu.Item>
                    <Menu.Item onClick={()=>workFlowVenueBooking('APPROVED', props?.booking?.id)}>Approve</Menu.Item>
                  </Menu>} trigger={['click']}>
                    <Button type="link" style={{ top: '-10px', position: 'absolute', padding: '0px', right: '-25px' }} icon={<MoreOutlined />}></Button>
                  </Dropdown>
              </div>
            </Col>
          </Row>
      </div>
    </CourseBatchCard>
  );
};

export default VenueBooking;
