import React,{useEffect} from 'react';
import {useLocation, Link, NavLink} from "react-router-dom";
import {useHistory} from 'react-router-dom';
import "./instructorsLink.css";
import LearnersItem from "./instructorsItem.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faChalkboardTeacher, faShareAlt, faTrophy, faCogs, faSearch, faBookReader, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import LearnersStory from "./instructorsStory.jsx";
import Collapsible from 'react-collapsible';
import details from "./JSON.js";
import { Button, Col, Collapse, Row, Typography } from 'antd';
import { Container } from 'react-bootstrap';
import { HeaderSection, PageContainer, PageSection } from '../footerLink.style.jsx';
import image from '../../../assets/instrcutors_banner.jpg';
const { Title } = Typography;
const { Panel } = Collapse;

const InstructorLink = (props) => {
  const history= useHistory()
  const { pathname } = useLocation()
  useEffect(()=>(
    window.scroll({top:0, behavior:'smooth'})
  ),[pathname])

  return( 
    <PageContainer>
      {/* <HeaderSection>
        <h2>Teach Your Passion on Simplitrain</h2>
        <h5>Create and Sell your Courses and Reach a Global Audience</h5>
        <Button onClick={()=> history.push('/become-an-instructor')}>Become an Instructor</Button>
      </HeaderSection> */}
      <PageSection>
          <Container>
            <Row style={{maxWidth:1090, margin:'auto', alignItems: 'center'}}>
              <Col md={12}>
                <div style={{ textAlign: 'left' }}>
                  <h2 className='AvenirLTStd-Black' style={{fontSize: 40, fontWeight: '900'} }>Teach Your Passion on Simplitrain</h2>
                  <h5 className='AvenirLTStd-Book'  style={{ fontSize: 22, fontWeight: '400', margin: 0 }}>Create and Sell your Courses and Reach a Global Audience</h5>  
                  <Button onClick={()=> history.push('/become-an-instructor')}>Become an Instructor</Button>
                </div>
              </Col>
              <Col md={12}>
                <img src={image} style={{ width: 438, height: 394, objectFit: 'cover' }} />
              </Col>
            </Row>
          </Container>
        </PageSection>
      <PageSection className='theme_bg'>
        <Container >
          <Title className="title AvenirLTStd-Black">Why teach on Simplitrain?</Title>
          <div className='card_link' style={ {maxWidth: 1090, margin:'auto'}}>
            <Row style={{marginTop:20, marginBottom:20}}>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faChalkboardTeacher}/> */}
                    <img src={require('../../../assets/Flexible - Instrcutors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Flexible teaching options'}</h3>
                    <p>{'Our platform offers both in-person and online teaching options, giving instructors the flexibility to choose the teaching format that best meets their needs.'}</p>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faTrophy}/> */}
                    <img src={require('../../../assets/wide audiance - instructors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Wide audience'}</h3>
                    <p>{'Our platform has a wide audience of learners from around the world, providing instructors with the opportunity to reach a large and diverse group of students.'}</p>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/User Friendly-  Instructors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'User-friendly course builder'}</h3>
                    <p>{'We provide a user-friendly course builder tool that makes it easy for instructors to create and launch their courses on our platform.'}</p>
                </div>
              </Col>
             
            </Row>
            <Row style={{marginTop:20, marginBottom:20}}>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/Marketing Support - Instructors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Marketing support'}</h3>
                    <p>{'We offer marketing support to help instructors promote their courses and reach a wider audience.'}</p>
                </div>
              </Col>
              {/* <Col md={6}>
                <div className="learnersLink-joinUsItem" >
                    <h3>{'Personalized support'}</h3>
                    <p>{'Our team provides personalized support to instructors, including best practices for course design and delivery, and guidance on how to effectively market and sell their courses.'}</p>
                </div>
              </Col> */}
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    <img src={require('../../../assets/Payment Protection- Instructors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Payment protection'}</h3>
                    <p>{'We offer payment protection for instructors, ensuring that they are paid on time and in full for their courses.'}</p>
                </div>
              </Col>
              {/* <Col md={6}>
                <div className="learnersLink-joinUsItem" >
                    <h3>{'Wide range of subjects'}</h3>
                    <p>{'Our platform offers a wide range of subjects, so instructors can teach in their area of expertise and reach a relevant audience.'}</p>
                </div>
              </Col> */}
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/Rewarding- Instructors.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Rewarding experience'}</h3>
                    <p>{'Teaching on our platform can be a rewarding and fulfilling experience for instructors, as they have the opportunity to make a positive impact on the lives and careers of their students.'}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </PageSection>
      {/* <PageSection>
        <Container>
          <Title className="title">How to register?</Title>
          <div className='howItem_link'>
            <Row style={{marginBottom:10}}>
              <Col md={8}>
                <div className="learnersLink-howItem">
                  <FontAwesomeIcon icon={faSearch}/>
                  <h4>1. Search/ Browse Courses</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-howItem">
                    <FontAwesomeIcon icon={faBookReader}/>
                    <h4>2. Read through the descriptions</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-howItem">
                    <FontAwesomeIcon icon={faShoppingCart}/>
                    <h4>3. Add to Cart & Register</h4>
                </div>
              </Col>
            
            </Row>
          </div>
        </Container>
      </PageSection> */}
      <PageSection>
        <Container>
          <Title className="title AvenirLTStd-Black" >Q&A</Title>
          <div style={{marginTop:30, maxWidth: 1090, margin:'auto'}}>
            <Row>
              <Col md={12} style={{padding:'0px 10px'}}>
                <Collapsible tabIndex={0} key={'q1'} trigger={<p><strong>Q:</strong>Can I speak with the instructor before I register?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>{'We have an option for you to message the instructor with in the portal.'}</p>
                  </div>
                </Collapsible>
                <Collapsible tabIndex={0} key={'q3'} trigger={<p><strong>Q:</strong>What are your refund options?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>For Online programs; If you cancel up to 48 hours before the course begins, you are eligible to receive the full amount back minus the transaction charges from the payment gateway provider</p>
                    <p style={{ marginLeft: 26 }}>For Classroom  programs; if there are no venue cancellation charges then a similar refund policy applies. If the Venue provider has cancellation charges then the same will apply to you.</p>
                  </div>
                </Collapsible>
                <Collapsible tabIndex={0} key={'q5'} trigger={<p><strong>Q:</strong>Why should I register?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>{'When you register, you can keep a track of all your learning history and login and check your course details.'}</p>
                  </div>
                </Collapsible>
              </Col>
              <Col md={12} style={{padding:'0px 10px'}}>
                <Collapsible tabIndex={0} key={'q2'} trigger={<p><strong>Q:</strong>What are your payment options?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>{'Our payment service provider is RazorPay and all options from RazorPay are available to you.'}</p>
                  </div>
                </Collapsible>
                <Collapsible tabIndex={0} key={'q4'} trigger={<p><strong>Q:</strong>How secure is my data?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>We only collect your email address and phone number to enable us to mail you receipt, reminders and venue directions. All other financial data entered at the time of payment is handled by Razor Pay. For more details, please read our privacy policy <NavLink to='/policies/privacy-and-cookie-policy'>here.</NavLink></p>
                  </div>
                </Collapsible>
                <Collapsible tabIndex={0} key={'q6'} trigger={<p><strong>Q:</strong>Can I reschedule the course I have registered for?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>{'You can cancel and re-register the next course offered by the instructor.'}</p>
                  </div>
                </Collapsible>
              </Col>
            </Row>  
          </div>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <h5 className='title AvenirLTStd-Black' style={{marginBottom: 0}}>Start teaching today!</h5>
          <Button onClick={()=> history.push('/become-an-instructor')}>Become an Instructor</Button>
        </Container>
      </PageSection>
    </PageContainer>
)};

export default InstructorLink;
