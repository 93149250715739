const CreateCourseTypes = {
  RESET_STEP: "RESET_STEP",
  NEXT_STEP: "NEXT_STEP",
  PREVIOUS_STEP: "PREVIOUS_STEP",
  CREATE_COURSE_STARTED: "CREATE_COURSE_STARTED",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  CREATE_COURSE_FAILED: "CREATE_COURSE_FAILED",
  SHOW_LOADING: "SHOW_LOADING",
  HIDE_LOADING: "HIDE_LOADING",
  SET_INITIAL_STEP: "SET_INITIAL_STEP",
};

export default CreateCourseTypes;
