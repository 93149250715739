import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";
import {
  selectIsInstructor,
  selectIsLearner,
  selectIsSignedIn,
  selectIsOnboarded,
  selectOnboardingStatus,
  selectIsVenueProvider,
  selectVenueProviderIsOnboarded,
  selectVenueProviderOnboardingStatus,
} from "../../../redux/user/user.selectors";
import { connect, useSelector } from "react-redux";
import { auth } from "../../../firebase/firebase.util";
import { logout } from "../../../redux/user/user.actions";
import { BroadcastChannel } from 'broadcast-channel';
import {
  setCategory,
  setSearch,
  setSubCategory,
} from "../../../redux/browse-courses/browse-courses-actions.js";
import response from "../../browse-courses/navbar/JSONdetails.js";
import { themeColors, mediaQueries, fonts } from "../../../theme";
import useMatchBreakpoints from "../../../hooks/useMatchBreakpoints.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowRight,
  faBars,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Button, ListGroup } from "react-bootstrap";
import { LinkButton } from "../header.styles.jsx";
import axios, * as others from 'axios';

export const MenuButton = styled.div`
  .menu-icon {
    background: transparent !important;
    border: 0;
    svg {
      width: 21px;
      height: 21px;
    }
  }
`;

export const MenuDropDown = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: calc(100% - 43px);
  box-sizing: border-box;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000080;
    z-index: -1;
  }
  .menu-bar {
    width: 280px;
    height: 100%;
    background: #fff;
    overflow: auto;
    padding: 10px 0;
    box-sizing: border-box;
  }
  &.active {
    display: block;
  }
  & .profileDropDown-user {
    height: auto !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    img {
      width: 50px;
      border-radius: 100px;
      margin-right: 10px;
    }
    .profileDropDown-userInfo {
      h4 {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #303030;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #4f4f4f;
      }
    }
  }
`;
export const MenuList = styled.div`
  .divider {
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #dedede;
    margin: 8px 16px;
    width: calc(100% - 32px);
  }
  .menus {
    border: 0;
    .list-group-item {
      display: flex;
      border: 0;
      justify-content: space-between;
      align-items: center;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #303030;
      }
      button {
        background: transparent;
        border: 0;
        svg {
          color: #4f4f4f;
        }
      }
      &.title {
        a {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #041016;
        }
      }
      &.head {
        background: #efefef;
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #303030;
          svg {
            margin-right: 10px;
          }
        }
      }
      &.logout {
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.02em;
          color: #eb5757;
        }
      }
    }
  }
`;
export const MenuFooter = styled.div`
  padding: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  a {
    background: ${themeColors.primary};
    border-radius: 4px;
    color: #fff;
    border: 0;
    padding: 12px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    border-radius:60px;
    margin:10px 0px;
    color: #ffffff;
  }
`;

const MenuComponent = (
  {
    logout,
    isLearner,
    isInstructor,
    isVenueProvider,
    isVenueProviderOnboarded,
    onVenueProviderboardingStatus,
    isOnboarded,
    setCategory,
    setSubCategory,
    setSearch,
    onboardingStatus,
    ...props
  }) => {
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [mainMenu, setMainMenu] = useState(true);
  const [subManu, setSubManu] = useState(true);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const logoutChannel = new BroadcastChannel('logout');

  const isSignedIn = useSelector(selectIsSignedIn);
  const user = useSelector((state) => state.user.currentUser);
  const { isLg, isXl } = useMatchBreakpoints();
  const isMobie = !isLg && !isXl;
  const subCatClickHandler = (e, cubCat, cat) => {
    // dispatch(setSearch(""));
    setSearch("");
    // let subCategory = {
    //   id: e.target.getAttribute("subCatID"),
    //   name: e.target.getAttribute("subName"),
    // };
    // dispatch(setSubCategory(subCategory));
    setCategory(cat.id);
    setSubCategory(cubCat.id);
    setIsOpen(false);
    setMainMenu(true);
    setSubManu(true);
    if (window.location.pathname !== "/course-search")
      history.push("/course-search");
  };

  const catClickHandler = (e, catID, catName) => {
    let subName = e.target.getAttribute("subName");
    console.log(subName);
    if (!subName) {
      setSearch("");
      // let category = { id: catID, name: catName };
      // console.log(category);
      // dispatch(setCategory(category));
      setCategory(catID);
      setIsOpen(false);
      if (window.location.pathname !== "/course-search")
        history.push("/course-search");
    }
    setMainMenu(true);
    setSubManu(true);
  };

  const handleBrowseAll = () => {
    history.push("/course-search/browse-all");
    setIsOpen(false);
  };

  const handleManuallyDropdown = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    setIsOpen(true);
    document.addEventListener("click", closeCatDropdown);
  };

  const loginAction = () => {
    setIsOpen(false);
    history.push("/auth/login");
  };

  const signAction = () => {
    setIsOpen(false);
    history.push("/auth/signup");
  };
  const openCategory = () => {
    setMainMenu(false);
    setSubManu(false);
  };

  const gotoLink = (path) => {
    setIsOpen(false);
    history.push(path);
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = () => {
    axios.get(process.env.REACT_APP_API_URL + "/courses/meta/categories/search/list")
      .then((response) => {
        console.log('response', response);
        setCategoryList([...categoryList, ...response.data]);
      });
  }

  const closeCatDropdown = (e) => {
    setIsOpen(false);
    document.removeEventListener("click", closeCatDropdown);
  };

  const logoutAllTabs = () => {
    alert("call logout")
    logoutChannel.onmessage = () => {
      logout();
      logoutChannel.close();
    }
  }

  return (
    <MenuButton>
      <Button onClick={() => setIsOpen(!isOpen)} className="menu-icon">
        <FontAwesomeIcon color={themeColors.black} icon={faBars} />
      </Button>
      <MenuDropDown className={isOpen ? "active" : null}>
        <div onClick={() => setIsOpen(!isOpen)} className="overlay"></div>
        <div className="menu-bar">
          {isSignedIn ? (
            <div className="profileDropDown-user">
              <img
                className="img-profile"
                alt="profile"
                src={
                  user
                    ? user.profileImg
                      ? user.profileImg
                      : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
                    : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
                }
              />
              <div className="profileDropDown-userInfo">
                <h4>
                  {user ? user.firstName : ""} {user ? user.lastName : ""}
                </h4>
                <p>{user ? user.email : ""}</p>
              </div>
            </div>
          ) : (
            <div className="mobile-buttons">
              <Button className="login-button" variant={"primary"} onClick={() => loginAction()}>
                Sign In
              </Button>
              <Button
                className="signup-button"
                variant={"outline-primary"}
                onClick={() => signAction()}
              >
                Register
              </Button>
              {/* <LinkButton
                className="bookings-button"
                onClick={() => closeCatDropdown()}
                to="/view-bookings"
              >
                My Bookings
              </LinkButton> */}
            </div>
          )}
          <MenuList>
            {mainMenu ? (
              isSignedIn ? (
                <ListGroup className="menus">
                  <ListGroup.Item onClick={() => gotoLink("/my-courses")}>
                    <a>My Courses</a>
                  </ListGroup.Item>
                  {/* <ListGroup.Item onClick={()=> gotoLink('/my-courses')}><a>Notifications</a></ListGroup.Item> */}

                  <ListGroup.Item onClick={() => gotoLink("/cart")}>
                    <a>My Cart</a>
                  </ListGroup.Item>
                  <ListGroup.Item onClick={() => gotoLink("/profile")}>
                    <a>Profile</a>
                  </ListGroup.Item>
                  <ListGroup.Item onClick={() => gotoLink("/wishlist")}>
                    <a>Wishlist</a>
                  </ListGroup.Item>
                  <ListGroup.Item onClick={() => gotoLink("/purchase-history")}>
                    <a>Puchase History</a>
                  </ListGroup.Item>
                  <div className="divider" />
                  <ListGroup.Item onClick={() => openCategory()}>
                    <a>
                      <b>Course Categories</b>
                    </a>
                    <button>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                  </ListGroup.Item>
                  {/* <ListGroup.Item>
                    <a>Help & Support</a>
                  </ListGroup.Item> */}
                  <div className="divider" />
                  <ListGroup.Item onClick={() => gotoLink("/settings")}>
                    <a>Settings</a>
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      auth.signOut();
                      localStorage.setItem("anonymousUserID", "");
                      logout();
                      // logoutAllTabs();
                      closeCatDropdown();
                      // window.location.reload();
                    }}
                    className="logout"
                  >
                    <a>Logout</a>
                  </ListGroup.Item>
                  {/* {subCategories.map(category=>(
                    <ListGroup.Item><a>{ category.name }</a><button><FontAwesomeIcon icon={faChevronRight} /></button></ListGroup.Item>
                  ))}
                  <ListGroup.Item><a>Help & Support</a></ListGroup.Item> */}
                </ListGroup>
              ) : (
                <ListGroup className="menus">
                  <ListGroup.Item className="title">
                    <a>Course Categories</a>
                  </ListGroup.Item>
                  {categoryList.map((category) => (
                    <ListGroup.Item key={category.id}>
                      <a
                        onClick={(e) =>
                          catClickHandler(
                            e,
                            category.id,
                            category.name
                          )
                        }
                      >
                        {category.name}
                      </a>
                      <button
                        onClick={() => {
                          setMainMenu(false);
                          setSubManu(true);
                          setCategories(category);
                          setSubCategories(category.subCourseCategoryList);
                        }}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                    </ListGroup.Item>
                  ))}
                  {/* <ListGroup.Item>
                    <a>Help & Support</a>
                  </ListGroup.Item> */}
                </ListGroup>
              )
            ) : (
              <ListGroup className="menus">
                {subManu ? (
                  <>
                    <ListGroup.Item
                      className="head"
                      onClick={() => {
                        if (isSignedIn) {
                          setSubManu(false);
                        } else {
                          setMainMenu(true);
                        }
                      }}
                    >
                      <a>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        {categories.name}
                      </a>
                    </ListGroup.Item>
                    {subCategories.map((category) => (
                      <ListGroup.Item
                        key={category.id}
                        onClick={(e) => subCatClickHandler(e, category, categories)}
                      >
                        <a>{category.name}</a>
                      </ListGroup.Item>
                    ))}
                  </>
                ) : (
                  <>
                    <ListGroup.Item
                      onClick={() => {
                        setMainMenu(true);
                      }}
                      className="head"
                    >
                      <a>
                        <FontAwesomeIcon icon={faChevronLeft} /> Categories
                      </a>
                    </ListGroup.Item>
                    {categoryList.map((category) => (
                      <ListGroup.Item key={category.id}>
                        <a
                          onClick={(e) =>
                            catClickHandler(
                              e,
                              category.id,
                              category.name
                            )
                          }
                        >
                          {category.name}
                        </a>
                        <button
                          onClick={() => {
                            setSubManu(true);
                            setCategories(category);
                            setSubCategories(category.subCourseCategoryList);
                          }}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </ListGroup.Item>
                    ))}
                  </>
                )}

                {/* <ListGroup.Item>
                  <a>Help & Support</a>
                </ListGroup.Item> */}
              </ListGroup>
            )}
          </MenuList>
          <MenuFooter>
            {
              isSignedIn ?
                <>
                  {
                    isLearner && isInstructor && isOnboarded ? (
                      <>
                        <LinkButton
                          className="link-box"
                          onClick={() => closeCatDropdown()}
                          to={
                            (location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
                              ? "/"
                              : "/instructor-dashboard/courses"
                          }
                        >
                          {(location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
                            ? "Switch to Learner mode"
                            : "Switch to Instructor mode"}
                        </LinkButton>

                        {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
                      </>
                    ) : isLearner && !isInstructor ? (
                      <>
                        <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/become-an-instructor">
                          Become an instructor
                        </LinkButton>
                      </>
                    )
                      : isInstructor &&
                        (onboardingStatus === "APPROVED" || onboardingStatus === "PENDING_APPROVAL") ? (
                        <>
                          <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/instructor-dashboard/courses">
                            To instructor{" "}
                          </LinkButton>
                          {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
                        </>
                      )
                        : <>
                          <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/instructor/onboarding">
                            Continue Instructor Onboarding{" "}
                          </LinkButton>
                          {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
                        </>
                  }

                  {/* {
                    isLearner && isVenueProvider && isVenueProviderOnboarded ?
                      <>
                        <LinkButton
                          className="link-box"
                          onClick={() => closeCatDropdown()}
                          to={
                            (location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                              ? "/"
                              : "/venue-provider-dashboard/venues"
                          }
                        >
                          {(location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                            ? "Switch to Learner mode"
                            : "Switch to Venue Provider mode"}
                        </LinkButton>

                      </>
                    : isLearner && !isVenueProvider ?
                      <>
                        <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/become-an-venue-provider">
                          Become an Venue Provider
                        </LinkButton>
                      </>
                    : isVenueProvider && (onVenueProviderboardingStatus === "APPROVED" || onVenueProviderboardingStatus === "PENDING_APPROVAL") ? (
                        <>
                          <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/venue-provider-dashboard/venues">
                            To venue provider{" "}
                          </LinkButton>
                        </>
                      )
                    : <>
                        <LinkButton onClick={() => closeCatDropdown()} className="link-box" to="/venue-provider/onboarding">
                          Continue Venue Provider Onboarding{" "}
                        </LinkButton>
                      </>
                  } */}
                </> :
                <>
                  <LinkButton

                    onClick={() => closeCatDropdown()}
                    to="/become-an-instructor"
                  >
                    Become an Instructor
                  </LinkButton>
                  {/* <LinkButton
                    onClick={() => closeCatDropdown()}
                    to="/become-an-venue-provider"
                  >
                    Become an Venue Provider
                  </LinkButton> */}
                </>
            }
          </MenuFooter>

        </div>
      </MenuDropDown>
      {/* <Dropdown id="iconStyle"
        text={isMobie?<FontAwesomeIcon icon={faBars} />:'Categories'}  
        style={{fontSize:'16px',color:themeColors.white, padding: '0 10px'}}
        className='link item parentDrop'
        onClick={!isOpen ? handleManuallyDropdown : () => {}}
        open={isOpen}
        closeOnBlur
      >
        
        <Dropdown.Menu className="dropdown-menu">
          
          
          {response.categories.map(category=>(
            <Dropdown.Item
              key={category.categoryID}
              onClick={(e)=>{ catClickHandler(e,category.categoryID,category.categoryName)}}
            >
              <Dropdown
                text={category.categoryName}
                className="simple dropdown-category"
                open={false}
                closeOnBlur
                closeOnChange
                style={{padding:'0'}}
                onClick={(e)=>{ catClickHandler(e,category.categoryID,category.categoryName)}}
                >

                <Dropdown.Menu  className="dropodown-subMenu">
                  {category.subCategories.map(subCategory=>(
                    //
                  <Dropdown.Item onClick={subCatClickHandler}
                                  key={subCategory.id}
                                  subName={subCategory.name}
                                  subcatid={subCategory.id} >{subCategory.name}</Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
          ))}
          <Dropdown.Item style={{textAlign:'center'}} onClick={handleBrowseAll}>
            <span style={{color:'#008cb6'}}> Browse All </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </MenuButton>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setSearch: (msg) => dispatch(setSearch(msg)),
  setSubCategory: (subcat) => dispatch(setSubCategory(subcat)),
  setCategory: (cat) => dispatch(setCategory(cat)),
  isSignedIn: selectIsSignedIn,
  isLearner: selectIsLearner,
  isInstructor: selectIsInstructor,
  isOnboarded: selectIsOnboarded,
  onboardingStatus: selectOnboardingStatus,
  isVenueProvider: selectIsVenueProvider,
  isVenueProviderOnboarded: selectVenueProviderIsOnboarded,
  onVenueProviderboardingStatus: selectVenueProviderOnboardingStatus,
});

export default connect(null, mapDispatchToProps)(MenuComponent);
