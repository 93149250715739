import React,{useState,useLayoutEffect} from 'react';
import { CoursesContainer, VenueProviderDetailContainer } from "./venueProviderVenuesDetails.style";
import {Link} from 'react-router-dom';
import VenueDetails from "./venueProviderVenuesDetails";
import fetchBookingsbyVenueId from "./fetchBookingsbyVenueId";
// import VenueBookingDetails from "../venueProviderBookingDetails/venueBookingDetails";
// import batchDetails from "../venueProviderBookingDetails/responseJSON.js";
import LoaderGif from '../../../styles/images/loading_spinner.gif';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VenueDashCredsItem from "./VenueDashCredsItem";
import fetchVenueDetails from '../../edit-venue/fetchVenueDetails';
import { DetailPageHeader, LeftMenuContaier } from '../../../styles';
import { Button, Col, Menu, Row } from 'antd';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import BookingsDetailsByVenueId from './BookingsDetailsByVenuId';
import fetchVenueBookingDetails from './fetchVenueBookingDetails';

const VenueProviderDetail = props => {

  const [activeTab, setActiveTab] = useState(1)
  const [bookings, setBookings] = useState([])
  const [venueDetails, setVourseDetails] = useState()
  const [loading, setLoading] = useState(true)
  const disabledDates = [
    new Date(2023, 1, 13),
    new Date(2023, 1, 16),
  ];
  useLayoutEffect(()=> {
    async function fetchData() {
     const response= await fetchVenueDetails(props.match.params.id);
     console.log(response, "response")
     if(response?.venueDetails){
      const responsebooking= await fetchVenueBookingDetails(props.match.params.id, 0, 10);
      console.log("responsebooking", responsebooking)
      if(responsebooking && responsebooking.venueBookingDetails && responsebooking.venueBookingDetails.content){
        setBookings(responsebooking.venueBookingDetails.content)
      }
     }
    //  props.setActiveCourceTitle(response?.venueDetails?.venueName);
     await setVourseDetails(response.venueDetails)
    //  await callBookingbyVenueId(response.venueDetails.venueID);
     setLoading(false)
    }
    fetchData();
  },[props.match.params.id])

  // const callBookingbyVenueId = async(venueID) =>{
  //   setLoading(true)
  //   const response= await fetchBookingsbyVenueId(venueID);
  //    console.log("response", response)
  //   //  await setBookings(response.courseBatchCompleteDetailsList.content)
  //    setLoading(false)
  // }

  return(
    <VenueProviderDetailContainer>
      {loading && <img className="instCourseDetails-gif"  src={LoaderGif} style={{width:50}} alt='' />}
      {!loading && <React.Fragment>
        <DetailPageHeader className='page-section'>
          <Container>
            <div className='header-pre'>
              <Button className='back-button' type='link' icon={<FontAwesomeIcon icon={faChevronLeft} />}>
                <Link
                  to={`/venue-provider-dashboard/venues`}
                >
                  All Venues
                </Link>
              </Button>
            </div>
            <div className='header-top'>
              <div>
                <h3 className='title'>
                  <strong>{venueDetails?.venueName}</strong>
                  <span style={{ background: '#e0e0e0', padding: '1px 8px', fontSize: 13, borderRadius: 5 }}>Venue</span>
                </h3>
                {/* <p className='description' dangerouslySetInnerHTML={{__html: venueDetails?.venueSummary}}></p> */}
              </div>
              
              <Button type='primary' className='edit_button'>
                <Link
                  to={`/venue-provider/edit-venue/${venueDetails.venueID}`}
                >
                  Edit Details
                </Link>
              </Button>
            </div>
            
          </Container>
        </DetailPageHeader>
        <div className="venueProvider-container page-section">
          <Container>
            <Row>
              <Col md={5}>
                <LeftMenuContaier>
                  <div className="left-container">
                    <Menu selectedKeys={[activeTab]} className='menu-group'>
                      <Menu.Item onClick={()=>setActiveTab(1)} className={activeTab == 1?'active':null} key={1}>Details</Menu.Item>
                      <Menu.Item onClick={()=>setActiveTab(2)} className={activeTab == 2?'active':null} key={2}>Amenities</Menu.Item>
                      <Menu.Item onClick={()=>setActiveTab(3)} className={activeTab == 3?'active':null} key={3}>Images</Menu.Item>
                      <Menu.Item onClick={()=>setActiveTab(4)} className={activeTab == 4?'active':null} key={4}>Guidelines & Policies</Menu.Item>
                      <Menu.Item onClick={()=>setActiveTab(5)} className={activeTab == 5?'active':null} key={5}>Bookings</Menu.Item>
                      <Menu.Item onClick={()=>setActiveTab(6)} className={activeTab == 6?'active':null} key={6}>Availability</Menu.Item>
                    </Menu>
                  </div>
                </LeftMenuContaier>
              </Col>
              <Col md={19}>
                  <Container>
                    <CoursesContainer>
                      {activeTab===1 && <VenueDetails venueDetails={venueDetails} viewType={'detail'} />}
                      {activeTab===2 && <VenueDetails venueDetails={venueDetails} viewType={'amenities'} />}
                      {activeTab===3 && <VenueDetails venueDetails={venueDetails} viewType={'images'} />}
                      {activeTab===4 && <VenueDetails venueDetails={venueDetails} viewType={'guidelines'} />}

                      { activeTab===5 &&  <BookingsDetailsByVenueId bookings={bookings} />
                      }
                      {activeTab===6 && <div className="instCourseDetails-content">
                          <div className="instCourseDetails-listWrap curriculum-collapsible">
                                <Row>
                                  <Col md={12}>
                                    <div className="instCourseDetails-contentWraper">
                                      <div className='header'>Scheduling</div>
                                      <div className="instCourseDetails-creds">
                                        <div className="instCourseDetails-credsPart">
                                          <VenueDashCredsItem name={'Days'} text={venueDetails?.venueSchedule?.mondaySchedule ? 'Monday - Friday' : venueDetails?.venueSchedule?.saturdaySchedule ? ', Saturday' : venueDetails?.venueSchedule?.sundaySchedule ? ', Sunday' : ''} />
                                          <VenueDashCredsItem name={'Timings'} text={venueDetails?.venueSchedule?.mondaySchedule ? venueDetails?.venueSchedule?.mondaySchedule?.startTime + ' - ' + venueDetails?.venueSchedule?.mondaySchedule?.endTime : venueDetails?.venueSchedule?.saturdaySchedule ? venueDetails?.venueSchedule?.saturdaySchedule?.startTime + ' - ' + venueDetails?.venueSchedule?.saturdaySchedule?.endTime : venueDetails?.venueSchedule?.sundaySchedule ? venueDetails?.venueSchedule?.sundaySchedule?.startTime + ' - ' + venueDetails?.venueSchedule?.sundaySchedule?.endTime : ''} />
                                        </div>
                                      </div>
                                    </div>  
                                  </Col>
                                </Row>
                          </div>
                        </div>
                      }
                    </CoursesContainer>
                  </Container>
            
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>}
    </VenueProviderDetailContainer>
  )}

export default VenueProviderDetail;
