import React from 'react';

const VenueDashCredsItem = props => (
  <div className="instCourseDetails-credsItem">
    <div>{props.name}</div>
    <span>: </span>
    {/*If renderHTML is true then string contains html tags that should be rendered*/}
    { !props.renderHTML &&
      <div style={{fontWeight:props.isBold?"700":"500"}}>
        {props.text}
    </div>
    }

    {props.renderHTML &&
      <div style={{fontWeight:props.isBold?"700":"500"}}
           dangerouslySetInnerHTML={{ __html: props.text }}>
      </div>
    }
  </div>
);

export default VenueDashCredsItem;
