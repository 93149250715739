import styled from 'styled-components';

export const HeaderContainer = styled.div`
.header-container{
  width: 100%;
  height: 100px;
  padding: 0px 40px;
  background: #667EEA;;
  display: flex;
}
.header-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #d7f5f8;
}
.header-title{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  color:#fff;
}
.toggle{
  display:none;
  margin-right: 0;
  margin-left: auto;
  width: 0;
  height: 100%;
  @media (min-width:320px) and (max-width: 767px) {
    display: flex;
    padding-bottom: 25px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }  
}
.header-link-color{
  color: #fff;
}
.header-link-color:hover{
  color: white;
}
`
export const LandingVenueSection = styled.div`
  .landing-venue{
    max-width: 450px;
    width: 100%;
    margin: auto;
    .title{
      font-family: 'Lato';
      font-style: normal;
      margin-bottom: 40px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EDEDED;
      color: #041016;
      padding-bottom: 5px;
    }
    .sub-title{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      
      display: flex;
      align-items: center;
      
      color: #979797;
    }
    .venue-option-button{
      opacity: 0.5;
      border: 1px solid #979797;
      border-radius: 4px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      -webkit-letter-spacing: 0.02em;
      -moz-letter-spacing: 0.02em;
      -ms-letter-spacing: 0.02em;
      letter-spacing: 0.02em;
      color: #041016;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .ant-radio-group{
    .ant-radio-button-wrapper{
      border-radius: 4px;
      padding: 6px 16px;
      margin-right: 10px;
      height: 36px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border-width: 1px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #041016;
      &.ant-radio-button-wrapper-checked{
        background: #2d9cdc;
        border-color: #2d9cdc;
        color:#ffffff;
      }
    }
  }
  .ant-radio-button-wrapper:not(:first-child)::before{
    display:none;
  }
  .other-option-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #303030;
  }
  .button-group{
    display: flex;
    width: 100%;
    justify-content: flext-start;
    padding: 30px 0px;
    .cancel-button{
      background: #FFFFFF;
      border: 1px solid #667EEA;
      border-radius: 4px;
      color:#667EEA;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      margin-right:10px;
      justify-content: center;
      line-height: 19px;
      display: flex;
      width:40%;
      align-items: center;
      letter-spacing: 0.02em;
    }
    .next-button{
      background: #667EEA;
      border-radius: 4px;
      justify-content: center;
      font-family: 'Lato';
      font-style: normal;
      margin-right:10px;
      width:40%;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }
  }
`


export const LocationSection = styled.div`
  .map {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-color: #ddd;
    margin-bottom: 10px;
  }
  .title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #303030;
  }
  .pin-text{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #4F4F4F;
  }
  .form-control{
    margin-bottom:30px;
  }
  .no-map{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #303030;

  }
`

export const VenueForm = styled.div`

  .ant-form-item.multiple-filed{
     .ant-row.ant-form-item-row {
       .ant-col.ant-form-item-control{
          .ant-form-item-control-input-content{
            display:flex;
          }
      }
    }
  }

  .table-view{
    padding-bottom:30px;
    width: 100%;
  }

  .title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #303030;
  }
`

export const AmentiesForm = styled.div`

    padding: 20px;
    box-shadow: 0px 0px 4px #a9a9a9;
    border-radius: 4px;

  .add-amenties{
    background: #FFFFFF;
    border: 1px solid #667EEA;
    border-radius: 4px;
    color:#667EEA;
  }
  .cancel-button{
    color:#667EEA;
  }
  .save-button{
    background: #667EEA;
    border: 1px solid #667EEA;
    border-radius: 4px;
    color:#ffffff;
  }
`
export const AmentiesItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  .amentie-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #041016;
  }
  .amenties-items-list{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    .amenties-item{
      background: #FFFFFF;
      border: 1px solid #BDBDBD;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      position: relative;
      padding: 10px;
      align-items: center;
      .free{
        position: absolute;
        right: 10px;
        border-radius: 4px;
        top: 10px;
      }
      .title{
        font-weight: 700;
        font-size: 18px;
        margin: 16px 0px;
        line-height: 22px;
        color: #041016;
      }
      .text{
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        /* or 22px */

        text-align: center;

        color: #4F4F4F;
      }
    }
  }


`