import { sendMobileOTPApi, verifyMobileOTPApi, resendMobileOTPApi, verifyOTPApi } from "./mobile-verification.api";
import { call, put } from "redux-saga/effects";
import {
    mobileOtpSendFailure,
    mobileOtpSendSuccess,
    verifyMobileOTPFailure,
    verifyMobileOTPSuccess,

    verifyOTPFailure,
    verifyOTPSuccess
} from "./mobile-verification.actions";
import { push } from 'connected-react-router';
import { message } from "antd";

export function* mobileOTPVerifyStart({
    payload: {
        mobileOTP, userId, loginType
    }
}) {
    try {
        console.log(`Inside mobileOTPVerifyStart ${mobileOTP}, ${userId}, ${loginType}`);
        const response = yield call(verifyMobileOTPApi, mobileOTP, userId, loginType);
        if (response.data.actionResult === "FAILURE") {
            console.log("The error is " + JSON.stringify(response.data));
            yield put(verifyMobileOTPFailure(response.data));
        } else if (response.data.actionResult === "SUCCESS") {
            yield put(verifyMobileOTPSuccess(response.data));
            const userDetails = response.data;
            if (userDetails.userRoles.includes("INSTRUCTOR")) {
                yield put(push('/instructor-dashboard/courses'));
            } else if (userDetails.userRoles.includes("LEARNER")) {
                yield put(push('/'));
            }
        }
    } catch (error) {
        console.log("The error is " + JSON.stringify(error));
        yield put(verifyMobileOTPFailure(error.response));
    }
}

export function* OTPVerifyStart({
    payload: {
        mobileOTP, emailOTP, userId, loginType
    }
}) {
    try {
        console.log(`Inside mobileOTPVerifyStart ${mobileOTP}, ${emailOTP}, ${userId}, ${loginType}`);
        const response = yield call(verifyOTPApi, mobileOTP, emailOTP, userId, loginType);
        if (response.data.actionResult === "FAILURE") {
            console.log("The error is " + JSON.stringify(response.data));
            message.error({ content: response.data.message, duration: 2 });
            yield put(verifyOTPFailure(response.data));
        } else if (response.data.actionResult === "SUCCESS") {
            yield put(verifyOTPSuccess(response.data));
            const userDetails = response.data;
            if (userDetails.userRoles.includes("INSTRUCTOR")) {
                yield put(push('/instructor-dashboard/courses'));
            } else if (userDetails.userRoles.includes("LEARNER")) {
                yield put(push('/'));
            }
        }
    } catch (error) {
        console.log("The error is " + JSON.stringify(error));
        yield put(verifyMobileOTPFailure(error.response));
    }
}



export function* mobileOTPSendStart({
    payload: {
        userID, mobile, countryCode
    }
}) {
    try {
        console.log(`Inside mobileOTPSendStart ${userID}, ${countryCode} ${mobile}`);
        const response = yield call(sendMobileOTPApi, userID, mobile, countryCode);
        console.log("response is " + JSON.stringify(response));
        if (response.data.error || response.data.actionResult === "FAILURE") {
            console.log("The error is " + JSON.stringify(response.data));
            message.error({ content: response.data.message, duration: 2 });
            yield put(mobileOtpSendFailure(response.data));
        } else if (response.data.actionResult === "SUCCESS") {
            let userDetails = response.data;
            userDetails.mobile = mobile;
            console.log(`mobile: ${mobile}`);
            console.log("PRINTING IN sagas " + JSON.stringify(userDetails, null, 2));
            yield put(mobileOtpSendSuccess(userDetails));
            if (localStorage.getItem("change") && localStorage.getItem("change") == "true") {
                yield put(push("/signup/verify"))
            } else {
                yield put(push("/signup/verify-mobile-otp"))
            }

        }
    } catch (error) {
        console.log("The error is " + JSON.stringify(error));
        yield put(mobileOtpSendFailure(error.response));
    }
}

export function* remobileOTPSendStart({
    payload: {
        userID, mobile, countryCode
    }
}) {
    try {
        console.log(`Inside remobileOTPSendStart ${userID}, ${countryCode} ${mobile}`);
        const response = yield call(resendMobileOTPApi, userID, mobile, countryCode);
        console.log("response is " + JSON.stringify(response));
        if (response.data.error || response.data.actionResult === "FAILURE") {
            console.log("The error is " + JSON.stringify(response.data));
            yield put(mobileOtpSendFailure(response.data));
        } else if (response.data.actionResult === "SUCCESS") {
            let userDetails = response.data;
            userDetails.mobile = mobile;
            console.log(`mobile: ${mobile}`);
            console.log("PRINTING IN sagas " + JSON.stringify(userDetails, null, 2));
            yield put(mobileOtpSendSuccess(userDetails));
            if (localStorage.getItem("change") && localStorage.getItem("change") == "true") {
                yield put(push("/signup/verify"))
            } else {
                yield put(push("/signup/verify-mobile-otp"))
            }
        }
    } catch (error) {
        console.log("The error is " + JSON.stringify(error));
        yield put(mobileOtpSendFailure(error.response));
    }
}
