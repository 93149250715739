const EmailVerificationActionTypes = {
    EMAIL_LINK_SEND_START: 'EMAIL_LINK_SEND_START',
    EMAIL_LINK_SEND_SUCCESS: 'EMAIL_LINK_SEND_SUCCESS',
    EMAIL_LINK_SEND_FAILURE: 'EMAIL_LINK_SEND_FAILURE',
    EMAIL_VERIFY_START: 'EMAIL_VERIFY_START',
    EMAIL_VERIFY_SUCCESS: 'EMAIL_VERIFY_SUCCESS',
    EMAIL_VERIFY_FAILURE: 'EMAIL_VERIFY_FAILURE',
    EMAIL_LINK_DISABLE_ERR: `EMAIL_LINK_DISABLE_ERR`
};

export default EmailVerificationActionTypes;
