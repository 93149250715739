const BrowseCoursesTypes = {
  TRAINING_START_DATE: "TRAINING_START_DATE",
  RANGE_PRICE: "RANGE_PRICE",
  LEVELS: "LEVELS",
  AVERAGE_CUSTOMER_REVIEWS:"AVERAGE_CUSTOMER_REVIEWS",
  DURATION:"DURATION",
  CATEGORY:"CATEGORY",
  SUBCATEGORY:"SUBCATEGORY",
  SEARCH_FIELD_INPUT:"SEARCH_FIELD_INPUT",
  SEARCH:"SEARCH",
  SORT:"SORT",
  COURSE_DATE:"COURSE_DATE",
  DISTANCE:"DISTANCE",
  LOCATION: "LOCATION",
  LANGUAGE: "LANGUAGE",
  VENUE_TYPE: "VENUE_TYPE",
  PAGINATION:"PAGINATION",
  PAGE_SIZE:"PAGE_SIZE",
  RESET_FILTERS:"RESET_FILTERS",
  SET_TIMELINE_LOCATION:"SET_TIMELINE_LOCATION"
};

export default BrowseCoursesTypes;
