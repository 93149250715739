import React,{useState, useEffect} from 'react';
//import "./reviews.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import ReviewItem from "./reviewItem.jsx";
import { ReviewStyleContainer } from '../reviews/reviews.style';
import { Button, Collapse, Input, message } from 'antd';
import ApiReviewList from './apiReviewList.js';
import moment from 'moment';
import { store } from 'react-notifications-component';
import { useSelector } from 'react-redux';
const {Panel}  =Collapse;

const VenueProviderReviews = props => {

  console.log("props", props)
  const [reviewList, setReviewList] = useState([]);
  const [answer,setAnswer] = useState("")
  const currentUser= useSelector(state =>state.user.currentUser)
  const anonymousUserID = useSelector(state =>state.myCourses.anonymousUserID)
  const getAllReview = async() =>{

    const response = await ApiReviewList(props.batchDetails.venueDetails.venueID, 0, 1000);
    console.log("response", response)
    if(response.actionResult=="SUCCESS"){
      setReviewList(response?.courseReview?.content)
    }
  }
  useEffect(() => {
    if(props?.batchDetails?.venueDetails?.venueID){
      getAllReview()
    }
  }, [props?.batchDetails?.venueDetails?.venueID])

  const onFinish = async(object) =>{
    console.log(object, "object", props)
    object.replied = new Date().toISOString();
    const response = await fetch(process.env.REACT_APP_API_URL + `/course-reviews`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    });
    const res = await response.json();

    console.log("res", res)
    if(res.status==400){
      message.error({ content:  `${res.message}.`, duration: 2 });
    }else{
      getAllReview();
      message.success({ content:  `${res.message}.`, duration: 2 });
    }
  }

  const likeanddislike = async(object) =>{
    let url  = "";
    if(object.likes==null && object.dislikes==null){
      url = process.env.REACT_APP_API_URL + `/course-reviews/${object.id}/like?userID=${currentUser.id?currentUser.id:anonymousUserID}`;
    }
    if(object.likes==1){
      url = process.env.REACT_APP_API_URL + `/course-reviews/${object.id}/dislike?userID=${currentUser.id?currentUser.id:anonymousUserID}`;
    }else{
      url = process.env.REACT_APP_API_URL + `/course-reviews/${object.id}/like?userID=${currentUser.id?currentUser.id:anonymousUserID}`;
    }
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    });
    const res = await response.json();

    console.log("res", res)
    if(res.status==400){
      message.error({ content:  `${res.message}.`, duration: 2 });
     
    }else{
      getAllReview();
      message.success({ content:  `${res.message}.`, duration: 2 });
    }
  }

  const onChange = (value, e, index) => {
    // setMessage(e.target.value);
    reviewList[index].replyByInstructor = e.target.value;
    setReviewList([...reviewList]);

    console.log('Change:', e.target.value);
  };

  const getRatting =(rating) => {
    let aa = '';
    for (let index = 0; index < 5; index++) {
      if(index < rating){
        aa = aa + '<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.64004 8.91558L2.78074 13.9257L7.28008 11.5602L11.7794 13.9257L10.9201 8.91558L14.5602 5.36742L9.52975 4.63645L7.28008 0.078125L5.03041 4.63645L0 5.36742L3.64004 8.91558ZM9.57829 8.47939L10.1208 11.6427L7.28004 10.1492L4.43924 11.6427L4.98179 8.47939L2.68353 6.23914L5.85964 5.77763L7.28004 2.89958L8.70044 5.77763L11.8765 6.23914L9.57829 8.47939Z" fill="black"/><path d="M10.1208 11.6427L9.57829 8.47939L11.8765 6.23914L8.70044 5.77763L7.28004 2.89958L5.85964 5.77763L2.68353 6.23914L4.98179 8.47939L4.43924 11.6427L7.28004 10.1492L10.1208 11.6427Z" fill="black"/></svg>';
      }else{
        aa = aa + '<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.88223 8.91558L3.02293 13.9257L7.52227 11.5602L12.0216 13.9257L11.1623 8.91558L14.8024 5.36742L9.77194 4.63645L7.52227 0.078125L5.2726 4.63645L0.242188 5.36742L3.88223 8.91558ZM9.82048 8.47939L10.363 11.6427L7.52223 10.1492L4.68143 11.6427L5.22397 8.47939L2.92572 6.23914L6.10183 5.77763L7.52223 2.89958L8.94263 5.77763L12.1187 6.23914L9.82048 8.47939Z" fill="black"/></svg>';
      }
    }
    return <span dangerouslySetInnerHTML={{__html: aa}} ></span>;
  }

  return(
  <ReviewStyleContainer>
  <div className="instructorBatch-details">
    <div className="tabTitle"> <div className="instructorBatch-details-head">Reviews</div> </div>
    <div className="instructorBatch-reviewsContainer">
      <Collapse accordion expandIconPosition='end'>

        {
          reviewList.length>0 ? reviewList.map((item, index)=>(
            <Panel header={item?.createdByUserName} key={index}>
            <div className="instructorBatch-reviewContent">
              <div className='rating'>
                {/* <FontAwesomeIcon
                  icon={
                    props.rating < 0.5
                      ? ["far", "star"]
                      : props.rating >= 0.5 && props.rating < 1
                      ? "star-half-alt"
                      : "star"
                  }
                />
                <FontAwesomeIcon
                  icon={
                    props.rating < 1.5
                      ? ["far", "star"]
                      : props.rating >= 1.5 && props.rating < 2
                      ? "star-half-alt"
                      : "star"
                  }
                />
                <FontAwesomeIcon
                  icon={
                    props.rating < 2.5
                      ? ["far", "star"]
                      : props.rating >= 2.5 && props.rating < 3
                      ? "star-half-alt"
                      : "star"
                  }
                />
                <FontAwesomeIcon
                  icon={
                    props.rating < 3.5
                      ? ["far", "star"]
                      : props.rating >= 3.5 && props.rating < 4
                      ? "star-half-alt"
                      : "star"
                  }
                />
                <FontAwesomeIcon
                  icon={
                    props.rating < 4.5
                      ? ["far", "star"]
                      : props.rating >= 4.5 && props.rating < 5
                      ? "star-half-alt"
                      : "star"
                  }
                /> */}

                { getRatting(item?.rating)}
                <span>Reviews</span>
              </div>
              <div className='comment_box'>
                <div className='comment'>
                 {item?.review}
                </div>
                <div className='comment-date'> {item?.created? moment(new Date(item?.created)).format("llll"): null}</div>
                <Input.TextArea value={item?.replyByInstructor} onChange={(e) =>onChange(item, e, index)} rows={5}></Input.TextArea>
                <div className='action-row'>
                    <div>
                    {/* onClick={()=>likeanddislike(item)}  */}
                    {item?.likes?item?.likes:0} <FontAwesomeIcon color={item.likes>0?'#667EEA':'#929292'}icon={faThumbsUp}/>
                      {/* onChange={(e) =>checked(item, e, index)} */}
                    </div>
                    <div>
                      <Button type='link' onClick={()=>onFinish(item)}>Reply</Button>
                    </div>
                </div>
              </div>
               
            </div>
          </Panel>

          ))
        

          :   <div className="no-content">
                  <h5>No Review Found!</h5>
              </div>
        }

      
      </Collapse>
        {/* <ReviewItem/>
        <ReviewItem/> */}
    </div>
  </div>
  </ReviewStyleContainer>
)};

export default VenueProviderReviews;
