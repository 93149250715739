import React, { useState, useEffect } from 'react';
//import './instructorCourseDetails.css';
import VenueDashCredsItem from "./VenueDashCredsItem";
import { Link } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, Descriptions, Image, List, Row, Table } from 'antd';
import { VenueForm } from './style';
import moment from 'moment';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';

const VenueDetails = props => {

  const [amenitiesColumns, setAmenitiesColumns] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [corporateDiscountCodeColumns, setCorporateDiscountCodeColumns] = useState([]);
  const [corporateDiscountCodeData, setCorporateDiscountCodeData] = useState([]);
  const details = props.venueDetails;
  const viewType = props.viewType;


  useEffect(() => {
    if (details && details.venuePricing && details.venuePricing.coupons && details.venuePricing.coupons.length > 0) {
      setCorporateDiscountCodeData(details.venuePricing.coupons)
      setCorporateDiscountCodeColumns([
        {
          title: 'Code',
          dataIndex: 'couponCode',
          key: 'couponCode',
        },
        {
          title: 'Discounted Price',
          dataIndex: 'discountedPrice',
          key: 'discountedPrice',
        },
        {
          title: 'Validity',
          dataIndex: 'validity',
          key: 'validity',
          render: (item, record) => record.couponValidFrom && record.couponValidUntil ? moment(new Date(record.couponValidFrom)).format('DD/MM/YYYY') + ' - ' + moment(new Date(record.couponValidUntil)).format('DD/MM/YYYY') : ''
        },
        {
          title: 'No. of Coupons',
          dataIndex: 'couponQty',
          key: 'couponQty',
        }
      ]);
    }


    if (details && details.customAmenities && details.customAmenities.length > 0) {
      setAmenitiesData(details.customAmenities)
      setAmenitiesColumns([
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: 'Category',
          dataIndex: 'categoryName',
          key: 'categoryName',
        },
        {
          title: 'Quantity',
          dataIndex: 'quantityAvailable',
          key: 'quantityAvailable',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Type',
          dataIndex: 'amenityType',
          key: 'amenityType',
          render: (item, record) => record.amenityType ? 'Free' : 'Paid'
        }
      ]);
    }


    console.log("venueData", details);
  }, [props.venueDetails]);

  return (
    <div className="instCourseDetails-content">
      {
        viewType == 'detail'?
          <Row style={{ width: 'calc( 100% + 20px )', margin: '0 -10px' }}>
            <Col md={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Details</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <VenueDashCredsItem name="Venue Name" text={details?.venueName} />
                    <VenueDashCredsItem name="Venue Type" text={details?.venueCategory?.name} />
                    <VenueDashCredsItem name="Venue Sub-Type" text={details?.venueSubCategory?.name} />
                    <VenueDashCredsItem name="Area" text={`${details?.venueAreaInSqFeet} sq ft`} />
                    <span style={{width:'100%', borderBottom:'1px solid #eaeaea'}}></span>
                      {
                        details.venueSeatings && details.venueSeatings.length > 0 ?
                          details.venueSeatings.map((item, index) => (
                            <VenueDashCredsItem key={index} name={item?.seating.toLowerCase()} text={item?.maximumNumberOfParticipants} />
                          )
                          ) : null
                      }
                  </div>
                  
                </div>
              </div>
            </Col>
            <Col md={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Location</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <VenueDashCredsItem name="Floor/Apt/Street" text={details?.venueLocation?.addressLine} />
                    <VenueDashCredsItem name="City" text={details?.venueLocation?.city} />
                    <VenueDashCredsItem name="State" text={details?.venueLocation?.state} />
                    <VenueDashCredsItem name="Country/Region" text={details?.venueLocation?.country} />
                    <VenueDashCredsItem name="Pincode" text={details?.venueLocation?.zipCode} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Bio</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <p className='description' dangerouslySetInnerHTML={{__html: details?.venueSummary}}></p>
                  </div>
                </div>
              </div>
            </Col>
            
            <Col md={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Price</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    {
                      details.venuePriceList && details.venuePriceList.length > 0 ?
                        details.venuePriceList.map((item, index) => (
                          <VenueDashCredsItem key={index} name={item?.priceType} text={<><FontAwesomeIcon style={{ fontSize: 12 }} icon={faRupeeSign} /> {item?.price}</>} />
                        ))
                        : null
                    }
                  </div>
                </div>
              </div>
            </Col>
            {/* <Col md={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Scheduling</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <VenueDashCredsItem name={'Days'} text={details?.venueSchedule?.mondaySchedule ? 'Monday - Friday' : details?.venueSchedule?.saturdaySchedule ? ', Saturday' : details?.venueSchedule?.sundaySchedule ? ', Sunday' : ''} />
                    <VenueDashCredsItem name={'Timings'} text={details?.venueSchedule?.mondaySchedule ? details?.venueSchedule?.mondaySchedule?.startTime + ' - ' + details?.venueSchedule?.mondaySchedule?.endTime : details?.venueSchedule?.saturdaySchedule ? details?.venueSchedule?.saturdaySchedule?.startTime + ' - ' + details?.venueSchedule?.saturdaySchedule?.endTime : details?.venueSchedule?.sundaySchedule ? details?.venueSchedule?.sundaySchedule?.startTime + ' - ' + details?.venueSchedule?.sundaySchedule?.endTime : ''} />
                    <VenueDashCredsItem name={'House/Guest Guidlines & Rules'} text={details?.guestGuidelinesAndRules} />
                    <VenueDashCredsItem name={'Country/Cancellation Policy'} text={details?.cancellationPolicy} />
                  </div>
                </div>
              </div>
            </Col> */}
            <Col md={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Corporate Discount Code</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart table-view">
                    <Table pagination={false} dataSource={corporateDiscountCodeData} rowKey={(record) => record.couponCode} columns={corporateDiscountCodeColumns} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        :viewType == 'amenities'?
          <div className="instCourseDetails-contentWraper">
            <div className='header'>Amenities</div>
            <div className="instCourseDetails-creds">
              <div className='table-view'>
                {/* <div className="summaryTitle">Amenities</div> */}
                <Table pagination={false} dataSource={amenitiesData} rowKey={(record) => record.id} columns={amenitiesColumns} />
              </div>
            </div>
          </div>
        :viewType == 'images'?
          <div className="instCourseDetails-contentWraper">
            <div className='header'>Images</div>
            <div className="instCourseDetails-creds">
              <div className='table-view'>
                {/* <div className="summaryTitle">Images</div> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 10, alignItems: 'baseline', flexWrap: 'wrap' }}>
                  {
                    details && details.photoGallery && details.photoGallery.length > 0 ?
                      details.photoGallery.map((item, index) => (
                        <div key={index}>
                          <Image
                            width={90}
                            src={item?.venuePhotoId?.fileURL}
                          />
                        </div>
                      ))
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        :viewType == 'guidelines'?
          <Row style={{ width: 'calc( 100% + 20px )', margin: '0 -10px' }}>
            <Col md={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>House/Guest Guidlines & Rules</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <p className='description' dangerouslySetInnerHTML={{__html: details?.guestGuidelinesAndRules}}></p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
              <div className="instCourseDetails-contentWraper">
                <div className='header'>Country/Cancellation Policy</div>
                <div className="instCourseDetails-creds">
                  <div className="instCourseDetails-credsPart">
                    <p className='description' dangerouslySetInnerHTML={{__html: details?.cancellationPolicy}}></p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        :null
      }

      <br />
      
      {/* <div className='table-view'>
        <div className="summaryTitle">Amenities</div>
        <Table pagination={false} dataSource={amenitiesData} rowKey={(record) => record.id} columns={amenitiesColumns} />
      </div>
      <div className='table-view'>
        <div className="summaryTitle">Images</div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 10, alignItems: 'baseline' }}>
          {
            details && details.photoGallery && details.photoGallery.length > 0 ?
              details.photoGallery.map((item, index) => (
                <div key={index}>
                  <Image
                    width={90}
                    src={item?.venuePhotoId?.fileURL}
                  />
                </div>
              ))
              : null
          }
        </div>
      </div> */}
    </div>

  )
}

export default VenueDetails;
