import { colors } from '@material-ui/core';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import styled from 'styled-components';
import { themeColors } from './theme';
import { mediaQueries } from './theme';

export const StepFormWizard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 15px 0px;
    .ant-btn.ant-btn-primary{
      background: #667eea;
      border: 1px solid #667eea;
      border-radius: 4px;
    }
    .ant-btn.ant-btn-default{
      border-radius: 4px;
    }
    .ant-row{
      width: 100%;
    }
    .steps{
      padding: 20px;
      height: auto;
      min-height:70vh;
      background: #F5F5F5;
      border-radius: 5px;
      .tilte{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;

        color: #041016;
      }
      
      .ant-steps{
        padding:30px 0px;
        .ant-steps-item-container{
          cursor: pointer;
        }
        .ant-steps-item ant-steps-item-finish{
          
        }
        .ant-steps-item{
          height:70px;
          .ant-steps-item-tail{
             &::after{
              background-color: #667EEA;
              width: 2px;
             }
          }
          .ant-steps-item-icon{
            background: #fff;
            border: 1px solid #667EEA;
            .ant-steps-icon{
                color:#667EEA;
            }
          }
          .ant-steps-item-content{
            .ant-steps-item-title{
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #4F4F4F;
            }
          }
          &.ant-steps-item-finish{
            .ant-steps-item-icon{
              background: #28a745;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #28a745;
              .ant-steps-icon{
                  color:#FFFFFF;
              }
            }
            .ant-steps-item-content{
              .ant-steps-item-title{
                color: #28a745;
                font-weight: 600;
              }
            }
          }
          &.ant-steps-item-active{
            .ant-steps-item-icon{
              background: #667EEA;
              border: 1px solid #667EEA;
              .ant-steps-icon{
                  color:#FFFFFF;
              }
            }
            .ant-steps-item-content{
              .ant-steps-item-title{
                color: #000000;
                font-weight: 600;
              }
            }
          }
          &.ant-steps-item-error{
            .ant-steps-item-icon{
              background: #EB5757;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #EB5757;
              .ant-steps-icon{
                  color:#FFFFFF;
              }
            }
            .ant-steps-item-content{
              .ant-steps-item-title{
                color: #EB5757;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    
`;

export const SetpContainer = styled.div`
  padding: 0px 20px;
  .action-buttons{
    padding: 20px 0px;
    position: sticky !important;
    background:#fff;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    height: 75px;
    z-index: 100;
    .ant-btn.ant-btn-primary{
      background: #667EEA;
      border-radius: 4px;
      padding: 0 20px;
      border:0;
    }
    .next{
      text-align: right;
      position: absolute;
      right: 0px;
    }
  }
`;

export const StepContent = styled.div`

    &.profile{
      background: #fff !important;
      border: 1px solid #e0e0e0 !important;
      overflow: hidden;
      border-radius: 10px !important;
      .stepper-stepTitle{
        border-bottom: 1px solid #e0e0e0 !important;
        padding: 10px 20px !important; 
        background: #f5f5f5;
      }
    }


    .header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E2E2E2;
      margin-bottom:20px;
    }
    .stepper-stepTitle{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      padding: 10px 0px;
      margin:0px;
      color: #041016;
      
    }
`;

export const StepContentForm = styled.div`

    &.profile-content{
      border:0 !important;
    }


    padding: 20px;
    border: 1px solid #dedede;
    border-radius: 2px;
    .only_label{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      margin-bottom:10px;
      color: #041016;

    }
    .only-label{
      .ant-space-item{
        width:100%;
        padding: 0px 5px;
      }
    }
    .ant-input-number{
      width:100%;
    }
    .multi-form{
      .ant-form-item{
        margin-bottom:12px;
      }
      .ant-space-item{
        width:100%;
      }
    }
    .socialUrlGroup{
        gap: 0 !important;
        align-items: flex-start;
        width: 100%;
      .ant-space-item{
          width: 100%;
          :nth-child(1){
            width:0px;
          }
          :nth-child(2){
            width:200px;
          }
          :last-child{
            width:40px;
          }
          .ant-form-item{
            padding:0;
            .ant-select-selector{
              border-radius: 5px 0px 0 5px !important;
            }
            .ant-input{
              border-radius: 0px 0px 0px 0px;
            }
            
          }
          .remove_button{
            width: 40px;
            height: 40px;
            /* color: #fff; */
            background: transparent;
            border: 1px solid #E2E2E2;
            border-radius: 0px 5px 5px 0px;
            box-shadow: none;
          }
      }
    }
    .multi-input-filed{
      .multi-input-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      .add_button{
        background: #667EEA;
        border-radius:100px;
        border-color: #667EEA;
        margin-bottom: 10px;
      }
      .remove_button{
        background: #EB5757;
        border-radius:100px;
        border-color: #EB5757;
      }
    }
    .customize_section{
      position: relative;
      padding:0px 5px 16px 5px;
      .close-icon{
        position: absolute;
        right: 0;
        top: 10px;
        color: #EB5757;
        font-weight: 100;
      }
    }
    .customize_dates{
      width: 100%;
      // max-width: 600px;
      border: 1px solid #dedede;
      border-radius: 4px;
      .customize_dates_head{
        display:flex;
        padding:10px;
        border-bottom: 1px solid #dedede;
        .customize_dates_left{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          p{
            margin: 0;
            padding-right: 10px;
          }
        }
      }      
    }
    .customize_dates_list{
      max-height: 300px;
      height: auto;
      overflow: auto;
      .customize_dates_item{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .customize_dates_item_text{
          ont-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #303030;
        }
        .customize_dates_item_from{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #4F4F4F;
          & > span{
            padding-right:10px;
          }
        }
        .customize_dates_item_to{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #4F4F4F;
          & > span{
            padding-right:10px;
          }
        }
        .customize_dates_item_action{
          text-align:right;
          .close-icon{
            color: #EB5757;
            font-weight: 100;
            position: relative;
            top: 0;
          }
        }
      }
    }
    &.fourthStep-container{
      width: 100%;
      min-height: 586px;
      background: #F7F7F7;
      border: 1px solid #CACACA;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      margin-bottom: 30px;
      .summaryTitle{
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #041016;
        margin-bottom: 10px;
      }
      .table-view{
        padding-bottom:30px;
        width:100%;
        .ant-table-thead > tr{
          background: #DDDDDD;
          border-radius: 2px;
        }
        .ant-table-thead > tr > th{
          padding:10px;
          background: #DDDDDD;
        }
        .ant-table-tbody > tr > td{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #041016;
          padding: 10px;
        }
      }
      .notes-section{
        .summaryNote{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.02em;
          color: #303030;

        }
      }
    }
    
    

`;

export const VenueListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const VenueHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom:20px;
    .stepper-stepTitle{
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #041016;
      border:0;
      margin:0;
      padding:0;

    }
    .right-action{
        .my_menu{
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #667EEA;
        }
    }
`

export const VenueFilter = styled.div`
    width:100%;
  .search-filter{
    margin-bottom: 20px;
    & > div{
      padding:0;
    }
    .location-filter{
      .active-location-filter{
        height: 40px;
        background: #667EEA;
        padding: 5px 10px;
        color:#fff;
        border-radius: 4px;
        .ant-space-item{
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;   
          *{
            color: #FFFFFF;   
            white-space: nowrap;
          }
        }
      }
    }
    .text-filter{
      width: 100%;
      max-width: 320px;
      .ant-input-affix-wrapper{
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        border-radius: 4px;
      }
      .locate-me{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #898989;
        padding:0;
        span{
          padding: 0px 6px;
        }
      }
    }
    .right-action{
      display: flex;
      gap: 10px;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      .my_menu{
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        padding: 0px;
        align-items: center;
        color: #667EEA;
        padding: 3px 10px;
        border-radius: 50px;
        height: 30px;
        border: 1px solid #667EEA;
      }
    }
    .filter-options{
      display: flex;
      flex-direction: row;
      gap: 10px;
      .ant-btn{
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #303030;
        .ant-space{
          .ant-space-item{
            line-height: 10px;
          }
        }
      }
    }
    .active-filter-option{
      display: flex;
      flex-direction: row;
      padding:20px 0px 0px 0px;
      .ant-tag{
        border:1px solid #EDEDED;
        padding:10px;
        font-weight: 400;
        font-size: 13px;
        display: flex;
        border-radius: 30px;
        padding: 5px 10px;
        height: 28px;
        background: #fff;
        line-height: 16px;
        color: #4F4F4F;
        span{
          color:#EB5757;
          font-size: 16px;
          font-weight: 900;
        }
      }
    }
  }
    
`

export const VenueItemCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    padding: 10px;
    margin:10px 0px;
    background: #FFFFFF;
    position:relative;
    border: 1px solid rgba(79, 79, 79, 0.15);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 5%);
    ${mediaQueries.md}{
      flex-direction: row;
    }
    &.selected{
      border: 2px solid #6dbd18;
    }
    .left-part{
      padding:10px;
      flex: 20;
      .photos{
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        
        .hide{
          display:none !important;
        }
        .other-img{
          display: flex;
          width: 30%;
          border-radius: 2px;
          overflow: hidden;
          justify-content: center;
          align-items: center;  
          position:relative;
          .ant-image-img{
            border-radius: 2px;
          }
          
          &.last{
            .number-count{
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              color: #fff;
              z-index: 10;
            }
            .ant-image{
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              ::before{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: #000000a3;
                top: 0;
                left: 0;
                z-index: 9;
              }
              .ant-image-mask-info{
                display:none;
              }
            }
            b{
              position: absolute;
              align-items: center;
              color: #fff;
              margin: auto;
              font-size: 17px;
              font-weight: 900;
              z-index: 10;
              cursor: pointer;
            }
          }
        }
        .main-img{
          width:100%;
          aspect-ratio: 16/9;
          .ant-image:first-child{
            width: 100%;
            border-radius: 4px;
            overflow: hidden;
            height:125px;
            .ant-image-img{
              border-radius: 4px;
              
            }
          }
        }
      }
      
    }
    .center-part{
      padding:10px 10px;
      padding-right: 20px;
      flex: 50;
      .content-top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .rating{
          display: flex;
          align-items: center;
          .rating-star{
            color:#EDC26B;
          }
          .rating-star-halfempty{
            color:#EDC26B;
          }
          .rating-star-empty{
            color:#EDC26B;
          }
        }

        .title{
          ont-weight: 800;
          font-size: 16px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #041016;
          text-transform: capitalize;
          margin:0;
        }
        .destination{
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #828282;
        }
        p{
          span{
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            margin-left:10px;
            display: flex;
            align-items: center;
            color: #828282;
          }
          
        }
      }
      .content-item{
        margin: 10px 0px 0px 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        p{
          display: flex;
          align-items: flex-start;
          width: 30%;
          margin-bottom:0px;
          justify-content: flex-start;
          b{
            margin-right:10px;
            padding-top: 0px;
          }
          span{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #4F4F4F;
          }
          a{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #667EEA;
          }
        }
        &.address{
          margin-bottom:20px;
          p{
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .right-part{
      padding:10px;
      padding-left: 20px;
      position:relative;
      flex: 30;
      border-left: 1px solid #EDEDED;
      .price{
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #041016;
        margin: 0;
      }
      .extra-price{
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        display: flex;
        align-items: center;
        color: #4F4F4F;
        margin: 0;
      }
      .days{
        font-weight: 600;
        font-size: 20px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #041016;

      }
      .detail_button{
        background: #667EEA;
        border: 1px solid #667EEA;
        border-radius: 4px;
        color:#fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
      }
      .action-button{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        left: 0;
        padding: 10px;
      }
      .extra-icons{
        position: absolute;
        
        top: -25px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        right: 0;
        padding: 10px;
        ${mediaQueries.md}{
          top: 0;
        }
        .icon_button{
          background: transparent;
          border: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
`

export const AdvanceFilterForm = styled.div`
    .filter-option{
      .filter-tilte{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;

        color: #303030;

      }
      .ant-checkbox-wrapper{
        margin-bottom:10px;
      }
    }
`
export const LeftMenuContaier = styled.div`
    // margin-right: 20px;
    position: sticky;
    top: 0;
    .mobile-menu-tab{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto;
      margin-bottom: 20px;
      a{
        display: flex;
        padding: 10px;
        white-space: nowrap;
      }
      ${mediaQueries.md}{
        display:none;
      }
    }
    .left-container{
      background: #FAFAFA;
      padding:2px;
      overflow:auto;
      border-radius: 5px;
      margin-bottom:10px;
      display:none;
      ${mediaQueries.md}{
        min-height:70vh;
        overflow:hidden;
        margin-bottom:0px;
        display:flex;
      }
      .menu-group{
        padding:0px 0px;
        background: transparent;
        display: flex;
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border: 0;
        ${mediaQueries.md}{
          justify-content: flex-start;
        }
        .ant-menu-item-group{
          margin-bottom:20px;
          width: 100%;
          position: relative;
          padding-top:20px;
          &:last-child{
            ::before{
              content: "";
              border-top: 1px solid #dedede;
              width: calc(100% - 40px);
              left: 20px;
              position: absolute;
              top: 0;
            }
          }
        }
        .ant-menu-item-group-title{
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.02em;
          padding-left: 20px;
          margin-bottom:2px;
          color: ${themeColors.textDark};
        }
        .ant-menu-item {
          
          display: flex;
         
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;
          margin: 0;
         
          .ant-menu-title-content{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: ${themeColors.textSubtle};
            line-height: 17px;
            a{
              font-family: 'Lato';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: ${themeColors.textSubtle};
              line-height: 17px;
              :hover{
                color: ${themeColors.primary};
              }
            }
          }
          &.active{
            .ant-menu-title-content{
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              /* identical to box height */
    
              letter-spacing: 0.02em;
    
              color: ${themeColors.primary};
            }
           
           }
          &.ant-menu-item-selected{
            color: #ffffff;
            background: #667EEA;
            a{
              color:#ffffff;
              :hover{
                color: ${themeColors.white};
              }
            }
          }
        }
        ${mediaQueries.md}{
          flex-direction: column;
          padding:20px 0px;

        }
      }

      
    }
`

export const PaginationContainer = styled.div`
#react-paginate{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .pagination-container{
    width: 100%;
    text-align: center;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    li{
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center;
      &.disabled{
        opacity: 0.6;
      }
      &.previous{
        svg{
          color:#4F4F4F;
        }
      }
      &.next{
        svg{
          color:#4F4F4F;
        }
      }
      &.active{
        background: #667EEA;
        border-radius: 4px;
        a{
          color:#ffffff;
          font-weight: 700;
        }
      }
      a{
        display:block;
        font-weight: 400;
        font-size: 16px;
        color: #303030;
        line-height: 19px;
        letter-spacing: 0.02em;
      }
    }
  }
}
`

export const DetailPageHeader = styled.div`
  margin-bottom:20px;
  .header-pre{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:15px 0px;
    .back-button{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      padding: 0;
      align-items: center;
      color: #828282;
      a{
        margin-left:5px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #828282;
      }
    }
  }
  .header-top{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #EDEDED;
    padding-bottom:10px;
    ${mediaQueries.md}{
      flex-direction: row;
      padding-bottom:0px;
      justify-content: space-between;
      align-items: center;
    }
    .title{
      display: flex;
      flex-direction: row;
      align-items:baseline;
      margin-bottom: 10px;
      strong{
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        display: flex;
        align-items: center;
        color: #041016;
      }
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        font-style: italic;
        color: #828282;
        margin-left:5px;
      }
    }
    .edit_button{
      background: #667EEA;
      border-radius: 51px;
      color:#ffffff;
      border:0px;
    }
  }
  .description{
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    padding:0px 0px;
    color: #4F4F4F;
    margin-bottom:10px;

  }
`

export const FormContainer = styled.div`
  .course-form-container{

  }
`

export const BasicFormContainer = styled.div`
  width: 100%;

  &.auth-form{
    .ant-form-item{
      margin-bottom:30px;
    }
    .forgot{
      position: absolute;
      right: 10px;
      font-size: 14px;
      color: #000;
      z-index:1000;
      text-decoration: underline;
    }
    .ant-form-item-row{
      .ant-form-item-label{
        font-size: 16px;
        font-weight: 600;
        > label{
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 6px;
        }
      }
      .ant-form-item-control{
        .ant-form-item-control-input{
          .ant-form-item-control-input-content{
              position:relative;
              height:47px;
              .forgot{
                position: absolute;
                right: 10px;
                top: -26px;
                font-size: 14px;
                color: #000;
                text-decoration: underline;
              }
              .ant-input{
                background: #f3f3f4;
                height: 100%;
                border-radius: 10px;
                &:hover, &:focus{
                  background: #ffffff !important; 
                  box-shadow:0 0 4px 1px rgb(103 126 234 / 70%);
                }
              }
          }
          .ant-input-group-wrapper{
            .ant-input-group-addon{
              border-width:0px;
              .ant-select{
                background: #f3f3f4;
                padding: 8px 11px;
                border-radius: 10px;
                height: 47px;
                padding: 0;
                display: flex;
                align-items: center;
                
                &.ant-select-focused, &:hover{
                  background: #ffffff;
                  box-shadow:0 0 4px 1px rgb(103 126 234 / 70%);
                }
              }
            }
          }
          .ant-input-affix-wrapper{
            background: #f3f3f4;
            padding: 8px 11px;
            border-radius: 10px;
            height: 47px;
            &.ant-input-affix-wrapper-focused, &:hover{
              background: #ffffff;
              box-shadow:0 0 4px 1px rgb(103 126 234 / 70%);
            }
          
            
            .ant-input-prefix{
              margin-right:10px;
            }      
            .ant-input{
              background: transparent;
              &:hover, &:focus{
                background: transparent !important; 
                box-shadow:none;
              }
            }
          }
        }
      }
    }


  }



  .ant-form{
  }
  .ant-form-item-label{
    label{
      cursor: auto;
    }
  }
  .youtube-url-div{
    iframe{
      width:100%;
      height:280px;
    }
  }
  .static-text{
    .ant-input{
      height:300px !important;
    }
  }
  
  .add_button{
    border-radius: 100% !important;
    padding: 0;
    line-height: 20px;
  }
  .multi-fields{
    > .ant-space-item{
      width:100%;
    }
    .multi-group-form{
      border-radius: 5px 5px 0px 0px !important;
      border: 0px !important;
      border-bottom: 1px solid #E2E2E2 !important;
      .ant-input{
        background:transparent !important;
      }
    }
    .multi-form{
      width:100%;
      gap:0 !important;
      border-radius: 5px;
      border: 1px solid #E2E2E2;
      overflow: hidden; 
      justify-content: flex-start;
      align-items: flex-start;
      .ant-input-affix-wrapper{
        border-radius: 0;
        border:0;
      }
      .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
        box-shadow: none;
      }
      .ant-space-item{
        width:auto;
        .ant-form-item{
          padding:0;
        }
        &:first-child{
          width:100%;
          margin: 0;
          .ant-form-item{
            margin-bottom:0px;
          }
          .ant-input{
            border-radius: 0;
            border:0;
          }
        }
        
      }
    
      .remove_button{
        width: 40px !important;
        height: 40px;
        border-radius: 0;
        background: transparent;
        border: 1px solid #E2E2E2;
        border-top:0;
        border-bottom:0;
        color:#ffffff;
      }
      .move_button{
        width: 40px !important;
        height: 40px;
        border-radius: 0;
        background: transparent;
        color:#ffffff;
        border: 0;
        .anticon{
          color: #667EEA;
        }
      }
    }
  }
  .ant-form-item{
    margin-bottom:20px;
    .ant-form-item-control-input-content{
      .quill{
        border-radius: 5px;
        overflow: hidden;
        .ql-toolbar{
          border:1px solid #E2E2E2;
          border-radius: 5px 5px 0px 0px;
          *{
            color:${themeColors.primary};
          }
          .ql-stroke{
            stroke: ${themeColors.primary};
          }
        }
        .ql-editor{
          min-height:80px;
        }
        .ql-container{
          border:1px solid #E2E2E2;
          border-top: 0;
          border-radius: 0px 0px 5px 5px;
        }
      }
    }


    .ant-input{
      background: #FFFFFF;
      border: 1px solid #E2E2E2;
      border-radius: 5px;
      height:40px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
     
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 38px;
    }
    .ant-select{
      .ant-select-selector{
        background: #FFFFFF;
        border: 1px solid #E2E2E2;
        border-radius: 5px;
        height:40px;
      }
      .ant-select-selection-item{
        line-height:36px;
      }
      

    }
    .ant-select-multiple{
      // .ant-select-selector{
      //   height:40px;
      // }
    }
    .ant-input-affix-wrapper{
      background: #FFFFFF;
      border: 1px solid #E2E2E2;
      border-radius: 5px;
      .ant-input{
        background: transpatent;
        border: 0px solid #E2E2E2;
        height:30px;
      }
    }
    .ant-input-wrapper{
      &.ant-input-group{
        .ant-input-affix-wrapper{
          margin-left: 10px;
          width: calc(100% - 10px);
        }
        .ant-input{
          margin-left: 10px;
          width: calc(100% - 10px);
        }
        .ant-input-group-addon{
          background: #fff;
          margin-right:10px;
          border: 1px solid #E2E2E2;
          border-radius: 5px;
          .ant-select-selector{
            padding:0;
          }
        }
      }
      
    }
    .ant-form-item-explain-error{
      font-size: 12px;
      margin: 2px;
    }
  }
  .tag-list{
    .ant-tag{
      display: inline-flex;
      width: initial;
      align-items: center;
      .anticon{
        margin-top:3px;
      }
    }
  }
  .only_view{

    .ant-select-selector{
      border: 0 !important;
      background: transparent !important;
    }
    .ant-select-selection-item{
      line-height: 20px !important;
      margin: 5px!important;
    }
  }
  .ant-input-group{
    .ant-input{

    }
  }
  .tag_select{
    .ant-input-affix-wrapper{
      padding:0;
      .ant-input{
        padding: 0px 10px;
        height:40px;
      }
    }
    button{
      padding-left: 20px;
      padding-right: 20px;
      background: #667EEA;
      color:#ffffff;
      height:40px;
      border: 0;
    }
  }
`
export const Vdetail = styled.div`
.payment-detail{
  padding: 20px;
  height: 100%;
  width:100%;
  min-height: 500px;
  background: #F5F5F5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  .Payment{
    display: flex;
    justify-content: space-between;
    margin:5px 0px;
  }
}
.house-rules{
  padding: 40px 30px;
  left: 385px;
  top: 5125px;
  background: #F2F2F2;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .rules{
    display: flex;
    align-items: baseline;
    h5{
      flex:30;
    }
    span{
      flex:60;
      color:#303030;
    }
    .rules-list{
      padding: 0;
      padding-left: 15px;
      list-style: auto;
      margin: 0;
      color:#303030;
    }
  }
}
.apply-btn > button{
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration-line: underline;
  color:#667eea;
}
.apply-input{
  background: #FFFFFF;
  border: 1px solid #2D9CDB;
  border-radius: 2px;
}
.apply{
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.header{
  font-weight: 700;
  font-size: 24px;
  position: relative;
  line-height: 29px;
  padding-left: 10px;
  border: 0;
  color: #041016;
}
.header::before{
  content: "";
  background: #667EEA;
  position: absolute;
  left: 0px;
  width: 2px;
  height: 100%;
}

.venue-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  .start-review{
    display: flex;
    flex-direction: column;
    width: 125px;
    text-align: right;
    p{
      display: flex;
      align-items: center;
      gap: 6px;
      span{
        display: flex;
        color: #FFB30F;
        gap: 2px;
      }
    }
    
  }
  .title-main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: flex-start;
    > div{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .back_buton{
        background: transparent;
        border: 0;
        color: #677eea;
        padding: 0;
        font-size: 22px;
        line-height: 20px;
      }
      h3{
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        button{
          background: #FFFFFF;
          border: 1px solid #EB5757;
          border-radius: 20px;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          color: #EB5757;
        }
      }
      .address{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #676767;
      }
  
    }
  }
}
.venue-images{
  margin-bottom: 40px;
  .main-img{
    border-radius: 4px;
    overflow: hidden;
    .slick-list{
      height: 100%;
      .slick-track{
        height: 100%;
        .slick-slide{
          div{
            width:100% !important;
            height:100% !important;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

          }
        }
      }
    }
    // .ant-image{
    //   width:100%;
    // }
    // img{
    //   width: 100%;
    //   height:auto;
    //   aspect-ratio: 16/9;
    // }
    .slick-arrow{
      z-index: 10;
      background: #677eea;
      color: #fff !important;
      &.slick-prev{
        left: 10px;
      }
      &.slick-next{
        right: 10px;
      }

    }
  }
  .img-list{
    // display: flex;
    // gap: 5px;
    // padding: 0px;
    // flex-direction: row;
    // overflow:auto;
    // justify-content: center;
    // align-items: center;
    margin-top: 6px;
    .inner-slider{
      height: 75px;
      .slick-list{
        padding:0px !important;
        height:100% !important;
        .slick-track{
          height:100% !important;
          // display: flex;
          // transform: none !important;
          // align-items: stretch;
          // gap: 5px;
          .slick-slide{
            height:100% !important;
            // display: flex;
            // height: 75px !important;
            // width: 80px !important;
            // justify-content: center;
            // align-items: center;
            padding: 1px;
            border: 1px solid #dadada;
             div{
              width: 100% !important;
              display: flex;
              align-items: center;
              height:100% !important;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
            &.slick-current{
              border: 2px solid #677eea;
            }
          }
        }
      }
    }
    .ant-image{
      position: relative;
    }
    .more-images{
      .more-count{
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000050;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
        font-weight: 600;
      }
      &:hover .more-count{
        display:none;
      }
    }

  }
}
.amenities-section{
  margin-top: 40px;
}
.About-venue{
  padding: 20px 0px;
}

.Amenities-list > ul{
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.Amenities-logo{
  width: 95px;
  height: 92px;
  background: #EAF5FB;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.Amenities{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Amenities{
  padding: 20px 0px;
}
.host{
  height: 264px;
  left: 0px;
  background: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.host-content{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;
}
.host-img{
  width: 130px;
  text-align:center;
}
.host-layout{
  padding: 25px;
  width: 100%;
  padding-left: 10px;
}
.feedback-value{
  display: flex;
  flex-direction: column;
  color:#e39e09;
  width: 10%;
  align-items: center !important;
}
.feedback-value > div:first-child{
  font-size: 27px;
}
.qa-ask-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .qa-ask-search{
    width: 100%;
    margin-bottom:16px;
    display: flex;
    input{
      background: #FFFFFF;
      border: 1px solid #BDBDBD;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 5px;
      height: 40px;
      width: 100%;
      &:focus{
        outline: none;
      }
    }
  }
  .qa-ask-submitContainer{
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    div {
      width: 100%;
      button{
        background: #667EEA;
        border-radius: 10px;
        margin-top:0px;
        width: 100%;
      }
    }
    p{
      margin-bottom: 4px;
    }
    ${mediaQueries.md}{
      div {
        width: 100%;
        button{
          margin-top:-32px;
          width: 200px;

        }
      }
    }
  }
  .qa-ask-submit{
    background: #667EEA;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 6px 20px;
    border: 0;
    border-radius: 4px;
    border: 1px solid #667EEA;
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
  }
  .qa-validation-err{
    position: absolute;
    bottom: 0;
    color: #f00;
    font-size: 12px;
    margin: 0px 0;
  }
}
.questAnswer-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries.md}{
    flex-direction: row;
  }
  .questAnswer-rating-disabled{
    pointer-events: none;
    opacity: 0.5;
  }
  .questAnswer-rating{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width:100%;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4F4F4F;
    div, svg{
      margin-bottom:8px;
    }
    path{
      pointer-events: none;
    }
    svg:hover{
      cursor:pointer;
    }
  }
  .questAnswer-content{
    width: 100%;
    border-left: 1px solid #DDDDDD;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .questAnswer-question{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
      flex-direction: column;
      ${mediaQueries.md}{
        flex-direction: row;
      }
      .questAnswer-title{
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        min-width: 100px;
        color: #303030;
      }
      .questAnswer-question-title{
        width:100%;
        text-align:left;
        font-weight: 500;
        font-size: 16px;
        word-break: break-all;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #667EEA;
      }
      .questAnswer-answer{
        font-family: Lato;
        font-style: normal;
        word-break: break-all;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        color: #333333;

      }
      .questAnswer-date{
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;

        color: #4F4F4F;
      }
    }
  }
}
.qa-seeMore{
  background: #e0ebf1;
  color:#439cca;
  font-weight: bold;
  min-height: 40px;
  width: 250px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 15px;
}
.qa-seeMore:hover{
  cursor: pointer;
}
`
export const Modalup = styled.div`
.apply-btn{
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration-line: underline;
  color:#667eea;
}
.ant-modal-header{
  padding-bottom: 0px;
}
.qtyinput{
  .ant-input{
    width: 40px !important;
    padding: 5px !important;
    text-align: center !important;
  }
}
.ant-tag-checkable{
  color:#000;
  border-radius: 20px;
  white-space: normal;
  font-size: 14px;
  &.ant-tag-checkable-checked{
    color:#2D9CDB;
    background:transparent;
  }
}
.tag{
  margin: 5px 0px;
}
.add-unit{
  width: 100%;
  max-width: 55px;
}
.ant-input-group-addon{
  border: 0px solid #d9d9d9;
  padding:0px 2px;
  .ant-btn-sm {
    height: 15px;
    padding: 0px 3px;
    font-size: 13px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}
}
.ant-input{
  min-width: 20px;
  padding: 2px 6px;
  height: 100%;
}
`
// padding: 0px 5px;
// border-radius: 85px 0px 0px 85px;
// font-size: 10px;
// }

// padding: 0px 4px;
//     font-size: 10px;
// }

// padding: 0px 5px;
//     border-radius: 0px 85px 85px 0px;
//     font-size: 10px;