import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
// import "./headerDropdown.css";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { auth } from "../../../firebase/firebase.util";
import { SettingOutlined, UserOutlined, HeartOutlined, ReadOutlined, ShoppingOutlined, PoweroffOutlined } from '@ant-design/icons';
import { logout } from "../../../redux/user/user.actions";
import {
  selectIsInstructor,
  selectIsLearner,
  selectIsSignedIn,
  selectIsOnboarded,
  selectOnboardingStatus,
  selectIsVenueProvider,
  selectVenueProviderIsOnboarded,
  selectVenueProviderOnboardingStatus,
} from "../../../redux/user/user.selectors";
import { LinkButton } from "../header.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import { HeaderDropdownMenu } from "./headerDropdown.styles";

const HeaderDropdown = ({
  logout,
  isSignedIn,
  isLearner,
  isInstructor,
  isVenueProvider,
  isVenueProviderOnboarded,
  onVenueProviderboardingStatus,
  isOnboarded,
  onboardingStatus,
  ...props
}) => {

  const location = useLocation();
  const [msgCounter, setMsgCounter] = useState(7);
  const user = useSelector((state) => state.user.currentUser);
  console.log("isLearner", isLearner, isOnboarded, isVenueProviderOnboarded)
  console.log("isVenueProvider", isVenueProvider, onVenueProviderboardingStatus)
  console.log("isInstructor", isInstructor, onboardingStatus)
  let disableField; // Some options will be not shown for instructor.

  if (isInstructor && location.pathname.includes("instructor")) {
    if (!location.pathname.includes("instructor-profile")) {
      disableField = false;
    } else {
      disableField = true;
    }
  } else {
    disableField = true;
  }


  return (
    <HeaderDropdownMenu
      id="profileDropDown"
      className={`header-dropdown-menu ${props.showDropdown ? "show-anim" : "hide-anim"
        }`}
    >
      <FontAwesomeIcon className="icon-handPoint" icon={faCaretUp} size="2x" />

      <div className="profileDropDown-user">
        <img
          className="img-profile"
          alt="profile"
          src={
            user
              ? user.profileImg
                ? user.profileImg
                : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
              : "https://i.pinimg.com/474x/8c/70/8b/8c708b478e0e71f7599b75b9cc108ddf.jpg"
          }
        />
        <div className="profileDropDown-userInfo">
          <h4>
            {user ? user.firstName : ""} {user ? user.lastName : ""}
          </h4>
          <p>{user ? user.email : ""}</p>
        </div>
      </div>
      {/*<div id="profileMsgContainer" className="msg-container">  This is messages link that we are hiding for now. Maybe it wont be used at all
               <LinkButton className="link-grey">Messages</LinkButton>
               <NotificationBadge className="msg-badge"
                                  count={msgCounter} effect={Effect.SCALE}/>
            </div>*/}
      <hr className="hr-line" />
      {isSignedIn && disableField && (
        <LinkButton to="/my-courses" className="link-grey">
          <ReadOutlined />
          My Courses
        </LinkButton>
      )}
      {/* {isSignedIn && disableField && (
        <LinkButton to="/cart" className="link-grey">
          My Cart
        </LinkButton>
      )} */}
      {/*Currently we are hiding profile for learner, because backend problem*/}


      {disableField && (
        <LinkButton to="/wishlist" className="link-grey">
          <HeartOutlined />
          Wishlist
        </LinkButton>
      )}
      {//isInstructor &&
        <LinkButton to="/profile" className="link-grey">
          <UserOutlined />
          Profile
        </LinkButton>}

      <LinkButton to="/settings" className="link-grey">
        <SettingOutlined />
        Settings
      </LinkButton>

      {disableField && (
        <LinkButton to="/purchase-history" className="link-grey">
          <ShoppingOutlined />
          Purchase History
        </LinkButton>
      )}

      <hr className="hr-line" />
      {/* Hiding Help & Support until the pages are ready */}
      {/* <LinkButton className="link-grey">Help & Support</LinkButton> */}
      {isSignedIn && (
        <LinkButton
          className="link-grey"
          onClick={() => {
            auth.signOut();
            localStorage.setItem("anonymousUserID", "");
            logout();
            props.handleShowDropdown();

          }}
          to="/"
        >
          <PoweroffOutlined />
          Logout
        </LinkButton>
      )}
      <hr className="hr-line" style={{ marginTop: 12 }} />
      {isLearner && isInstructor && isOnboarded ? (
        <>
          <LinkButton
            className="link-box"
            to={
              (location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
                ? "/"
                : "/instructor-dashboard/courses"
            }
          >
            {(location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
              ? "Switch to Learner mode"
              : "Switch to Instructor mode"}
          </LinkButton>

          {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
        </>
      ) : isLearner && !isInstructor ? (
        <>
          <LinkButton className="link-box" to="/become-an-instructor">
            Become an instructor
          </LinkButton>
        </>
      )
        : isInstructor &&
          (onboardingStatus === "APPROVED" || onboardingStatus === "PENDING_APPROVAL") ? (
          <>
            <LinkButton className="link-box" to={
              (location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
                ? "/"
                : "/instructor-dashboard/courses"
            }>
              {(location.pathname.includes("/instructor") && !location.pathname.includes("instructor-profile"))
                ? "Switch to Learner mode"
                : "Switch to Instructor mode"}
            </LinkButton>
            {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
          </>
        )
          :
          <>
            {
              location.pathname.includes("/instructor/onboarding")
                ? <LinkButton className="link-box" to="/">
                  Switch to Learner mode
                </LinkButton>
                : <LinkButton className="link-box" to="/instructor/onboarding">
                  Continue Instructor Onboarding{" "}
                </LinkButton>
            }

            {/*<LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>*/}
          </>}

      {/* {isLearner && isVenueProvider && isVenueProviderOnboarded ? (
        <>
          <LinkButton
            className="link-box"
            to={
              (location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                ? "/"
                : "/venue-provider-dashboard/venues"
            }
          >
            {(location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
              ? "Switch to Learner mode"
              : "Switch to Venue Provider mode"}
          </LinkButton>

        </>
    )  : isLearner && !isVenueProvider ? (
        <>
          <LinkButton className="link-box" to="/become-an-venue-provider">
            Become an Venue Provider
          </LinkButton>
        </>
      )
      : isVenueProvider &&
          (onVenueProviderboardingStatus==="APPROVED" || onVenueProviderboardingStatus==="PENDING_APPROVAL") ? (
          <>
            <LinkButton className="link-box" to={
              (location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                ? "/"
                : "/venue-provider-dashboard/venues"
            }
          >
            {(location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
              ? "Switch to Learner mode"
              : "Switch to Venue Provider mode"}
            </LinkButton>
          </>
        )
       : <>
         <LinkButton className="link-box" to="/venue-provider/onboarding">
           Continue Venue Provider Onboarding{" "}
         </LinkButton>
         <LinkButton to="/instructor/landing-venue-wizard">Venues </LinkButton>
         </>} */}
    </HeaderDropdownMenu>
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

const mapStateToProps = createStructuredSelector({
  isSignedIn: selectIsSignedIn,
  isLearner: selectIsLearner,
  isInstructor: selectIsInstructor,
  isOnboarded: selectIsOnboarded,
  onboardingStatus: selectOnboardingStatus,
  isVenueProvider: selectIsVenueProvider,
  isVenueProviderOnboarded: selectVenueProviderIsOnboarded,
  onVenueProviderboardingStatus: selectVenueProviderOnboardingStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDropdown);
