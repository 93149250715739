import { takeLatest, put, call, all } from "redux-saga/effects";
import ContentTypes from "../../common/contenttypes/content-types";
import VenueProviderOnBoardingTypes from "./venueprovideronboarding.types";
import UserActionTypes from "../user/user.types";
import { push } from "connected-react-router";
import Axios from "axios";

export function* submitNationalId(
  nationalIdObj,
  registrationValues
) {
  let uploadImgURL = process.env.REACT_APP_API_URL + "/file/s3";
  let data = new FormData();
  data.append("file", registrationValues["nationalId"]);
  return yield Axios.post(uploadImgURL, data);
}

export function* submitToApiForVenueProviderRegistration(
  registrationValues,
  uuid,
  action,
  currentStep
) {
  let url = process.env.REACT_APP_API_URL + "/venue/provider/";
  url += uuid + "/onboarding?action=" + action + "&currentStep=" + currentStep;

  // if (registrationValues["nationalId"]) {
  //   const response = yield call(submitNationalId, registrationValues["nationalId"]);
  //   registrationValues["nationalId"] = response.data.s3File.id;
  // }

//  let bodyFormData = new FormData();
//  for (let key in registrationValues) {
  //  var isKeyValue = !!registrationValues[key];

  //  if (isKeyValue) {
  //    bodyFormData.append(key, registrationValues[key]);
    //}
//  }
  console.log(registrationValues)

  // Changing format of registration values before sending it to API

  let formatedValues ={
                        "currentStep": currentStep,
                        "personalDetails": {
                            "firstName": registrationValues.firstName,
                            "lastName": registrationValues.lastName,
                            // "designation": registrationValues.designation,
                            "profileImgID": registrationValues.profileImgID,
                            "socialBio": registrationValues.socialBio,
                            "agreementAccepted": registrationValues.agreementAccepted,
                            "panNumber": registrationValues.panNumber,
                            "companyName": registrationValues.companyName,
                            "primaryName": registrationValues.primaryName,
                            "primaryEmail": registrationValues.primaryEmail,
                            "primaryPhoneCode": registrationValues.primaryPhoneCode,
                            "primaryPhone": registrationValues.primaryPhone,
                            "secondaryName": registrationValues.secondaryName,
                            "secondaryEmail": registrationValues.secondaryEmail,
                            "secondaryPhoneCode": registrationValues.secondaryPhoneCode,
                            "secondaryPhone": registrationValues.secondaryPhone,
                            // "nationalID": registrationValues.nationalID,
                            // "introVideoYoutubeURL": registrationValues.introVideoYoutubeURL
                        },
                        // "address": {
                        //     "address1": registrationValues.address1,
                        //     "address2": registrationValues.address2,
                        //     "city": registrationValues.city,
                        //     "state": registrationValues.state?.value,
                        //     "country": registrationValues.country?.value,
                        //     "pincode": registrationValues.pincode
                        // },
                        // "socialMedia": registrationValues.socialMedia,
                        // "socialMediaMoreLinks": registrationValues.socialMediaMoreLinks,
                        "venueProviderBankDetails": {
                            "bankName": registrationValues.bankName,
                            "bankAcctName": registrationValues.bankAcctName,
                            "bankAcctNo":  registrationValues.bankAcctNo,
                            "bankIFSCCode": registrationValues.bankIFSCCode,
                            "gst": registrationValues.gst,
                            "tannumber": registrationValues.tannumber
                        }
                    }
                    console.log(formatedValues)
                    // return false;
  return yield Axios.post(url, formatedValues)
}

export function* submitRegistrationForVenueProvider({
  payload: { registrationValues, uuid, action, currentStep },
}) {
  try {

    yield put({ type: "SHOW_LOADING" });
  let response =  yield call(
      submitToApiForVenueProviderRegistration,
      registrationValues,
      uuid,
      action,
      currentStep
    )
    let responseData = yield response;
    if(responseData.data.actionResult==="SUCCESS"){
      // console.log(responseData, "responseData");
      // return;
      let onboardingStatus = responseData.data.venueProviderOnboardingDetails.venueProviderOnboardingStatus;
      yield put({ type: "UPDATE_VENUE_PROVIDER_ONBOARDING_STATUS", payload: onboardingStatus })
    }

    yield put({ type: "HIDE_LOADING" });


    if(action==="SAVE"){
      yield put(push("/"));
    }
    else{
      yield put(push("/venue-provider-dashboard/venues"));
    }
  } catch (error) {
    if(action==="SAVE"){
      yield put(push("/"));
    }
    else{
      yield put(push("/venue-provider-dashboard/venues"));
    }
  }
}

export function* initiatePendingApproval() {
  yield put(push("/venue-provider/onboarding/info"));
}

export function* onSubmitVenueProviderRegistrationStart() {
  yield takeLatest(
    VenueProviderOnBoardingTypes.REGISTRATION_VENUE_PROVIDER_STARTED,
    submitRegistrationForVenueProvider
  );
}

export function* onSetVenueProviderPendingApproval() {
  yield takeLatest(
    VenueProviderOnBoardingTypes.SET_VENUE_PREOVIDER_PENDING_APPROVAL,
    initiatePendingApproval
  );
}

export function* venueProviderOnboardingSagas() {
  yield all([call(onSubmitVenueProviderRegistrationStart), call(onSetVenueProviderPendingApproval)]);
}
