const SignupActionTypes = {
    LINKEDIN_SIGN_UP_SUCCESS: 'LINKEDIN_SIGN_UP_SUCCESS',
    FACEBOOK_SIGN_UP_SUCCESS: 'FACEBOOK_SIGN_UP_SUCCESS',
    GOOGLE_SIGN_UP_SUCCESS: 'GOOGLE_SIGN_UP_SUCCESS',
    EMAIL_SIGN_UP_SUCCESS: 'EMAIL_SIGN_UP_SUCCESS',
    EMAIL_SIGN_UP_FAILURE: 'EMAIL_SIGN_UP_FAILURE',
    EMAIL_SIGN_UP_START: 'EMAIL_SIGN_UP_START',
    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
    FORGOT_PASSWORD_LOADING: 'FORGOT_PASSWORD_LOADING',
    RESET_PASSWORD_START: 'RESET_PASSWORD_START',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
}

export default SignupActionTypes;
