import "./App.css";

import React, { Component } from "react";

import Header from "./components/header/header.component";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer/footer.component";
import ReactNotification from "react-notifications-component";
import 'react-notifications-component/dist/theme.css';
// import LoginAndSignupPage from "./containers/login-signup/login-signup.component";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, withRouter, useHistory } from "react-router-dom";
import { SocketContext, socket } from "./contexts/socket";


//version 4

class App extends Component {
  unsubscribeFromAuth = null;

  constructor(props) {
    super(props);
    // this.location = window.location.pathname;
    this.state = { showLogin: false, location: window.location.pathname };
    this.handleCloseLogin = this.handleCloseLogin.bind(this);
    this.handleOpenLogin = this.handleOpenLogin.bind(this);
  }



  handleCloseLogin() {
    this.setState((state) => ({
      showLogin: false,
    }));
  }

  handleOpenLogin(path) {
    return (
      <Redirect to={{ pathname: `/auth/${path}` }} />
    )

    // this.setState((state) => ({
    //   showLogin: true,
    // }));
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.location = location.pathname;
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }


  onRouteChanged() {
    this.setState(
      { location: window.location.pathname }
    )
    // this.location = window.location.pathname;
  }



  componentDidUpdate(prevProps) {

    if (this.props.signupUser !== prevProps.signupUser) {
      this.setState(state => ({
        showLogin: false
      }))
    }
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  render() {
    const currentUser = this.props.currentUser;
    return (
      <>
        <ReactNotification />
        <Helmet>
          <title>SimpliTrain</title>
          {/*TODO description for robots*/}
          <meta name="description" content="Simplitrain description" />
        </Helmet>
        {
          this.state.location && (this.state.location.includes('auth') || this.state.location.includes('signup') || this.state.location.includes('resetPassword')) ?
            null
            : <Header handleLogin={this.handleOpenLogin} />
        }


        {/* {
              this.state.showLogin && (currentUser!=undefined? currentUser.id===null: true)  ?
                <Redirect
                    to={{
                    pathname: "/auth/login"
                  }}
                />
                // <LoginAndSignupPage showLogin={this.state.showLogin} handleLogin={this.handleCloseLogin}/>
              :""
            } */}
        <div style={{ minHeight: '60vh' }}>
          <SocketContext.Provider value={socket}>
            <Routes handleOpenLogin={this.handleOpenLogin} />
          </SocketContext.Provider>
        </div>
        {
          this.state.location && (this.state.location.includes('auth') || this.state.location.includes('signup') || this.state.location.includes('resetPassword')) ?
            null
            : <Footer />
        }

      </>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  signupUser: state.signup.signupUser
});

export default withRouter(connect(mapStateToProps, null)(App));