import React,{useEffect} from 'react';
import {useLocation, Link, NavLink} from "react-router-dom";
import {useHistory} from 'react-router-dom';

// import "./headerDropdown.css";
import { connect, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectIsInstructor,
  selectIsLearner,
  selectIsSignedIn,
  selectIsOnboarded,
  selectOnboardingStatus,
  selectIsVenueProvider,
  selectVenueProviderIsOnboarded,
  selectVenueProviderOnboardingStatus,
} from "../../../redux/user/user.selectors";

import "./venueProvidersLink.css";
import LearnersItem from "./venueProvidersItem.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faChalkboardTeacher, faShareAlt, faTrophy, faCogs, faSearch, faBookReader, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import LearnersStory from "./venueProvidersStory.jsx";
import Collapsible from 'react-collapsible';
import details from "./JSON.js";
import { Button, Col, Collapse, Row, Typography } from 'antd';
import { Container } from 'react-bootstrap';
import { HeaderSection, PageContainer, PageSection } from '../footerLink.style.jsx';
import image from '../../../assets/venueproviders_banner.jpg';
import { LinkButton } from '../../header/header.styles';
const { Title } = Typography;
const { Panel } = Collapse;

const VenueProviderLink = ({
  logout,
  isSignedIn,
  isLearner,
  isInstructor,
  isVenueProvider,
  isVenueProviderOnboarded,
  onVenueProviderboardingStatus,
  isOnboarded,
  onboardingStatus,
  ...props
})  => {
  const history= useHistory()
  const { pathname } = useLocation();
  const location = useLocation();
  const user = useSelector((state) => state.user.currentUser);
  console.log("isLearner", isLearner, isVenueProviderOnboarded)
  console.log("isVenueProvider", isVenueProvider, onVenueProviderboardingStatus)
  // logout();
  useEffect(()=>(
    window.scroll({top:0, behavior:'smooth'})
  ),[pathname])

  return(
    <PageContainer>
      {/* <HeaderSection>
        <h2>Become a Venue Provider</h2>
        <h5>List your Venue on Simplitrain and reach a wider range of Customers</h5>
        <Button onClick={()=> history.push('/become-an-venue-provider')}>Become an Venue Provider</Button>
      </HeaderSection> */}
      <PageSection>
          <Container>
            <Row style={{maxWidth:1090, margin:'auto', alignItems: 'center'}}>
              <Col md={12}>
                <div style={{ textAlign: 'left' }}>
                  <h2 className='AvenirLTStd-Black' style={{fontSize: 40, fontWeight: '900'} }>Become a Venue Provider</h2>
                  <h5 className='AvenirLTStd-Book'  style={{ fontSize: 22, fontWeight: '400', margin: 0 }}>List your Venue on Simplitrain and reach a wider range of Customers</h5>  

{console.log("isVenueProvider && isVenueProviderOnboarded ", isVenueProvider && isVenueProviderOnboarded )}
                  {isVenueProvider && isVenueProviderOnboarded ? (
                    <>
                      <LinkButton
                        className="link-box1 ant-btn ant-btn-default"
                        to={
                          (location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                            ? "/"
                            : "/venue-provider-dashboard/venues"
                        }
                      >
                        {(location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                          ? "Switch to Learner mode"
                          : "Switch to Venue Provider mode"}
                      </LinkButton>

                    </>
                )  : !isVenueProvider ? (
                    <>
                      <LinkButton className="link-box1 ant-btn ant-btn-default" to="/become-an-venue-provider">
                        Become an Venue Provider
                      </LinkButton>
                    </>
                  )
                  : isVenueProvider &&
                      (onVenueProviderboardingStatus==="APPROVED" || onVenueProviderboardingStatus==="PENDING_APPROVAL") ? (
                      <>
                        <LinkButton className="link-box1 ant-btn ant-btn-default" to={
                          (location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                            ? "/"
                            : "/venue-provider-dashboard/venues"
                        }
                      >
                        {(location.pathname.includes("/venue-provider") && !location.pathname.includes("venue-provider-profile"))
                          ? "Switch to Learner mode"
                          : "Switch to Venue Provider mode"}
                        </LinkButton>
                      </>
                    )
                  : <>
                  
                      <>
                        <LinkButton className="link-box1 ant-btn ant-btn-default" to="/venue-provider/onboarding">
                          Continue Venue Provider Onboarding{" "}
                        </LinkButton>
                      </>
                    </>} 

                  {/* <Button onClick={()=> history.push('/become-an-venue-provider')}>Become an Venue Provider</Button> */}
                </div>
              </Col>
              <Col md={12}>
                <img src={image} style={{ width: 438, height: 394, objectFit: 'cover' }} />
              </Col>
            </Row>
          </Container>
        </PageSection>
      <PageSection className='theme_bg'>
        <Container>
          <Title className="title AvenirLTStd-Black">Why list your Venue at Simplitrain?</Title>
          <div className='card_link' style={ {maxWidth: 1090, margin:'auto'}}>
            <Row style={{marginTop:20, marginBottom:20}}>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faChalkboardTeacher}/> */}
                    <img src={require('../../../assets/Visibilty - Venue Providers.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Increased visibility'}</h3>
                    <p>{'Listing a venue on our platform can increase the visibility of the venue and make it more easily discoverable by potential customers.'}</p>
                </div>
              </Col>
              {/* <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    <h3>{'Wide audience'}</h3>
                    <p>{'Our training platform has a large and diverse audience of learners, providing venue providers with the opportunity to reach a wider range of customers.'}</p>
                </div>
              </Col> */}
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/venue Management - Venue Providers.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Easy venue management'}</h3>
                    <p>{'We provide venue providers with tools to easily manage their venues and bookings on our platform.'}</p>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/pricing - Venue Providers.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Flexible pricing options'}</h3>
                    <p>{'We offer flexible pricing options for venue providers, allowing them to set their own rates and adjust them as needed.'}</p>
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:20, marginBottom:20}}>
             
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                  <img src={require('../../../assets/Payment protection - Venue Providers.svg')} style={{width:50 }} />
                  <h3 className='AvenirLTStd-Black'> {'Payment protection'}</h3>
                  <p>{'We offer payment protection for venue providers, ensuring that they receive payment for their bookings in a timely manner.'}</p>
                </div>
              </Col>
              {/* <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    <h3>{'Wide range of venues'}</h3>
                    <p>{'We have a wide range of venues available on our platform, including conference centers, training rooms, and classrooms, to meet the needs of different types of courses.'}</p>
                </div>
              </Col> */}
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/Marketing Support- Venue Providers.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Marketing support'}</h3>
                    <p>{'We offer marketing support to help venue providers promote their venues and reach a wider audience.'}</p>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-joinUsItem" >
                    {/* <FontAwesomeIcon icon={faCogs}/> */}
                    <img src={require('../../../assets/Reputation - Venue Providers.svg')} style={{width:50 }} />
                    <h3 className='AvenirLTStd-Black'>{'Opportunity to build a reputation'}</h3>
                    <p>{'Venue providers who list their venues on our platform have the opportunity to build a reputation as a reliable and high-quality provider of training venues.'}</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </PageSection>
      {/* <PageSection>
        <Container>
          <Title className="title">How to register?</Title>
          <div className='howItem_link'>
            <Row style={{marginBottom:10}}>
              <Col md={8}>
                <div className="learnersLink-howItem">
                  <FontAwesomeIcon icon={faSearch}/>
                  <h4>1. Search/ Browse Courses</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-howItem">
                    <FontAwesomeIcon icon={faBookReader}/>
                    <h4>2. Read through the descriptions</h4>
                </div>
              </Col>
              <Col md={8}>
                <div className="learnersLink-howItem">
                    <FontAwesomeIcon icon={faShoppingCart}/>
                    <h4>3. Add to Cart & Register</h4>
                </div>
              </Col>
            
            </Row>
          </div>
        </Container>
      </PageSection> */}
      <PageSection>
        <Container >
          <Title className="title AvenirLTStd-Black">FAQ</Title>
          <div style={{marginTop:30, maxWidth: 1090, margin:'auto'}} >
            <Row>
              <Col md={12} style={{padding:'0px 10px'}}>
                <Collapsible key={'q1'} trigger={<p><strong>Q:</strong> Can I speak with the instructor before I register?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong> {'We have an option for you to message the instructor with in the portal.'}</p>
                  </div>
                </Collapsible>
                <Collapsible key={'q3'} trigger={<p><strong>Q:</strong> What are your refund options?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong> For Online programs; If you cancel up to 48 hours before the course begins, you are eligible to receive the full amount back minus the transaction charges from the payment gateway provider</p>
                    <p style={{ marginLeft: 26 }}>For Classroom  programs; if there are no venue cancellation charges then a similar refund policy applies. If the Venue provider has cancellation charges then the same will apply to you.</p>
                  </div>
                </Collapsible>
                <Collapsible key={'q5'} trigger={<p><strong>Q:</strong> Why should I register?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong>{'When you register, you can keep a track of all your learning history and login and check your course details.'}</p>
                  </div>
                </Collapsible>
              </Col>
              <Col md={12} style={{padding:'0px 10px'}}>
                <Collapsible key={'q2'} trigger={<p><strong>Q:</strong> What are your payment options?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong> {'Our payment service provider is RazorPay and all options from RazorPay are available to you.'}</p>
                  </div>
                </Collapsible>
                <Collapsible key={'q4'} trigger={<p><strong>Q:</strong> How secure is my data?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong> We only collect your email address and phone number to enable us to mail you receipt, reminders and venue directions. All other financial data entered at the time of payment is handled by Razor Pay. For more details, please read our privacy policy <NavLink to='/policies/privacy-and-cookie-policy'>here.</NavLink></p>
                  </div>
                </Collapsible>
                <Collapsible key={'q6'} trigger={<p><strong>Q:</strong> Can I reschedule the course I have registered for?</p>} triggerTagName="div" openedClassName="learnersLink-collapsibleOpen">
                  <div className="learnersLink-collapsible-content">
                    <p><strong>A:</strong> {'You can cancel and re-register the next course offered by the instructor.'}</p>
                  </div>
                </Collapsible>
              </Col>
            </Row>  
          </div>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <h5 className='title AvenirLTStd-Black' style={{marginBottom: 0}}>List your Venue on Simplitrain</h5>
          <Button onClick={()=> history.push('/become-an-venue-provider')}>Become an Venue Provider</Button>
        </Container>
      </PageSection>
    </PageContainer>
)};

// export default ;

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = createStructuredSelector({
  isSignedIn: selectIsSignedIn,
  isLearner: selectIsLearner,
  isInstructor: selectIsInstructor,
  isOnboarded: selectIsOnboarded,
  onboardingStatus: selectOnboardingStatus,
  isVenueProvider: selectIsVenueProvider,
  isVenueProviderOnboarded: selectVenueProviderIsOnboarded,
  onVenueProviderboardingStatus: selectVenueProviderOnboardingStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueProviderLink);
